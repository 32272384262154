import React, { useState, useContext } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import PendingTransactionCard from './PendingTransactionCard'; // Extracted TransactionCard component
import TransactionManager from '../management/TransactionManager';
import { getPendingTransactions, approvePendingTransaction, reviewPendingTransaction, updatePendingTransaction, rejectPendingTransaction, submitPendingTransaction, deletePendingTransaction } from '../../api'
import ConfirmDialog from '../../utils/ConfirmDialog';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import useCustomMutation from '../../utils/hooks'; // Custom hook for mutation logic

const PendingTransactionList = () => {
  const { data, refreshData } = useContext(DataContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState({});
  const [dialogState, setDialogState] = useState({
    edit: { open: false, transaction: null },
    delete: { open: false, transaction: null },
    reject: { open: false, transaction: null },
  });

  const transactions = data.pendingTransactions || [];
  const assets = data.assets || [];
  const owners = data.owners || [];

  const groupedTransactions = user.role !== 'INDIVIDUAL'
  ? transactions.reduce((acc, transaction) => {
      const asset = assets.find((a) => a.id === transaction.asset_id);
      const ownerId = asset?.user_id || 'unknown';
      acc[ownerId] = acc[ownerId] || [];
      acc[ownerId].push(transaction);
      return acc;
    }, {})
  : {};

  const openEditModal = (transaction) => {
    setDialogState({ ...dialogState, edit: { open: true, transaction } });
  };

  const closeEditModal = () => {
    setDialogState({ ...dialogState, edit: { open: false, transaction: null } });
  };

  const openDeleteDialog = (transaction) => {
    setDialogState({ ...dialogState, delete: { open: true, transaction } });
  };

  const closeDeleteDialog = () => {
    setDialogState({ ...dialogState, delete: { open: false, transaction: null } });
  };

  const openRejectDialog = (transaction) => {
    setDialogState({ ...dialogState, reject: { open: true, transaction } });
  };

  const closeRejectDialog = () => {
    setDialogState({ ...dialogState, reject: { open: false, transaction: null } });
  };

  const handleRejectTransaction = (note) => {
    const { transaction } = dialogState.reject;
    rejectTransaction.mutate({ id: transaction.id, note: note });
    closeRejectDialog();
  };

  const handleExpandClick = (ownerId) => {
    setExpanded((prev) => ({ ...prev, [ownerId]: !prev[ownerId] }));
  };

  // Mutations for transaction actions
  const refreshPendingTransactions = () => {
    refreshData('pendingTransactions', getPendingTransactions);
  };

  const approveTransaction = useCustomMutation(
    (id) => approvePendingTransaction(id),
    {successMessage: t('transaction_approved_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const rejectTransaction = useCustomMutation(
    ({ id, note }) => rejectPendingTransaction(id, note),
    {successMessage: t('transaction_rejected_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const submitTransaction = useCustomMutation(
    (id) => submitPendingTransaction(id),
    {successMessage: t('transaction_submitted_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const deleteTransaction = useCustomMutation(
    (id) => deletePendingTransaction(id),
    {successMessage: t('transaction_deleted_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const updateTransaction = useCustomMutation(
    ({ id, data }) => updatePendingTransaction(id, data),
    { successMessage: t('transaction_updated_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const reviewTransaction = useCustomMutation(
    (id) => reviewPendingTransaction(id),
    { successMessage: t('transaction_reviewed_successfully'),
      errorMessage: t('transaction_operation_failed'),
      refreshData: refreshPendingTransactions}
  );

  const handleUpdateTransaction = (updatedData) => {
    updateTransaction.mutate({ id: dialogState.edit.transaction.id, data: updatedData });
    console.log('Test if refresh is called ');
    closeEditModal();
  };

  const handleConfirmDelete = () => {
    deleteTransaction.mutate(dialogState.delete.transaction.id);
    closeDeleteDialog();
  };

  return (
    <Box my={3}>
      {Object.keys(groupedTransactions).length > 0 ? (
        Object.entries(groupedTransactions).map(([ownerId, transactions]) => (
          <List key={ownerId}>
            <ListItem button onClick={() => handleExpandClick(ownerId)}>
              <ListItemText
                primary={`${owners.find((o) => o.id === ownerId)?.name || t('unknown_owner')} ${owners.find((o) => o.id === ownerId)?.surname || ''
                  } (${transactions.length})`}
              />
              {expanded[ownerId] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expanded[ownerId]} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                {transactions.map((transaction) => (
                  <PendingTransactionCard
                    key={transaction.id}
                    transaction={transaction}
                    assets={assets}
                    user={user}
                    onEdit={openEditModal}
                    onDelete={openDeleteDialog}
                    onApprove={approveTransaction.mutate}
                    onReject={openRejectDialog}
                    onSubmit={submitTransaction.mutate}
                    onReview={reviewTransaction.mutate}
                  />
                ))}
              </Grid>
            </Collapse>
          </List>
        ))
      ) : (
        <Grid container spacing={2}>
          {transactions.map((transaction) => (
            <PendingTransactionCard
              key={transaction.id}
              transaction={transaction}
              assets={assets}
              user={user}
              onEdit={openEditModal}
              onDelete={openDeleteDialog}
              onApprove={approveTransaction.mutate}
              onReject={openRejectDialog}
              onSubmit={submitTransaction.mutate}
              onReview={reviewTransaction.mutate}
            />
          ))}
        </Grid>
      )}



      {/* Edit Modal */}
      <Dialog open={dialogState.edit.open} onClose={closeEditModal} fullWidth maxWidth="md">
        <DialogTitle>{t('edit_transaction')}</DialogTitle>
        <DialogContent>
          {dialogState.edit.transaction &&
            <TransactionManager
              user={user}
              assets={assets}
              owners={owners}
              initialData={dialogState.edit.transaction}
              apply={handleUpdateTransaction}
            />
          }
        </DialogContent>
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <ConfirmDialog
        open={dialogState.delete.open}
        onClose={closeDeleteDialog}
        onConfirm={handleConfirmDelete}
        confirmButtonColor="error"
      />
      {/* Reject Confirmation Dialog */}
      <ConfirmDialog
        open={dialogState.reject.open}
        onClose={closeRejectDialog}
        onConfirm={handleRejectTransaction}
        title="reject_transaction"
        message="reject_note"
        confirmLabel="reject"
        confirmButtonColor="error"
        requireReason // Enable reason input
      />
    </Box>
  );
};

export default PendingTransactionList;
