import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  InputAdornment,Autocomplete,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configCurrencies, configConciergeCategories, configCountries } from '../../api';
import { useNotification } from '../../context/NotificationContext';


const ConciergeForm = ({ user, apply, toggleShow }) => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showNotification } = useNotification();

  const currentYear = new Date().getFullYear();
  const endOfYear = `${currentYear}-12-31`; // Construct the end-of-year date in 'yyyy-MM-dd' format


  const generateCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from({ length: 7 }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
  };

  const { control, handleSubmit, formState: { errors, isValid }, reset } = useForm({
    defaultValues: {
      name: '',
      category: '',
      contact: { phone: '', email: '' },
      address: '',
      country: '',
      city: '',
      fees: '',
      plan_expiry_date: '',
      description: '',
      notes: '',
      currency: '',
      code: generateCode(),
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [currencyRes, categoryRes, countriesRes] = await Promise.all([
          configCurrencies(),
          configConciergeCategories(),
          configCountries(),
        ]);
        setCurrencies(currencyRes.data);
        setCategories(categoryRes.data);
        setCountries(countriesRes.data);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        showNotification(t('failed_to_load_data'), 'error');
      }
    };
    fetchData();
  }, [user.currency]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await apply(data);
      showNotification(t('concierge_created_successfully'), 'success');
      toggleShow();
    } catch (error) {
      console.error('Error creating concierge:', error);
      showNotification(t('failed_create_concierge'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Grid container spacing={3}>

          {/* General Information */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{ required: t('name_required') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('name')}
                  error={!!errors.name}
                  helperText={errors.name ? t('name_required') : ''}
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
  <FormControl fullWidth>
    <Controller
      name="category"
      control={control}
      rules={{ required: t('category_required') }}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={categories} // Pass the list of categories
          getOptionLabel={(option) => t(option)} // Translate category labels
          onChange={(event, value) => field.onChange(value)} // Update field value on selection
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('category')}
              required
              error={!!errors.category}
              helperText={errors.category?.message}
            />
          )}
        />
      )}
    />
  </FormControl>
</Grid>

          {/* Contact Information */}

          <Grid item xs={6}>
            <Controller
              name="contact.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('phone')}
                  error={!!errors.contact?.phone}
                  helperText={errors.contact?.phone ? t('phone_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="contact.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('email')}
                  error={!!errors.contact?.email}
                  helperText={errors.contact?.email ? t('email_required') : ''}
                  required
                />
              )}
            />
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('address')}
                  error={!!errors.address}
                  helperText={errors.address ? t('address_required') : ''}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t('country')}</InputLabel>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select {...field} required>
                    {countries.map((country) => (
                      <MenuItem key={country} value={country}>
                        {t(country)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('city')}
                  error={!!errors.city}
                  helperText={errors.city ? t('city_required') : ''}
                />
              )}
            />
          </Grid>

          {/* Financial Information */}
          <Grid item xs={12}>
            <Controller
              name="fees"
              control={control}
              rules={{
                required: t('fees_required'),
                min: { value: 0, message: t('fees_min') },
                max: { value: 100, message: t('fees_max') },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('management_fees')}
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  error={!!errors.fees}
                  helperText={errors.fees?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
  <Controller
    name="plan_expiry_date"
    control={control}
    defaultValue={endOfYear} // Set end of year as default
    render={({ field }) => (
      <TextField
        {...field}
        fullWidth
        label={t('plan_expiry_date')}
        type="date"
        InputLabelProps={{ shrink: true }}
        error={!!errors.plan_expiry_date}
        helperText={errors.plan_expiry_date ? t('date_required') : ''}
        required
      />
    )}
  />
</Grid>

          {/* Description */}
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('description')}
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>

          {/* Currency and Code */}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>{t('currency')}</InputLabel>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Select {...field} required>
                    {currencies.map((curr) => (
                      <MenuItem key={curr} value={curr}>
                        {curr}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('code')}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button onClick={toggleShow} color="secondary">
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isLoading ? t('saving') : t('save')}
            </Button>
          </DialogActions>
        </form>
      );
    };

    export default ConciergeForm;
