import axiosInstance from '../src/utils/axiosConfig';


// Config
export const configCurrencies = () => axiosInstance.get(`/config/currencies`);
export const configAssetTypes = () => axiosInstance.get(`/config/asset-types`);
export const configAssetCategories = () => axiosInstance.get(`/config/asset-categories`);
export const configAssetExploitations = () => axiosInstance.get(`/config/asset-exploitations`);
export const configTransactionTypes = () => axiosInstance.get(`/config/transaction-types`);
export const configConciergeCategories = () => axiosInstance.get(`/config/concierge-categories`);
export const configExpenseTypes = () => axiosInstance.get(`/config/expense-categories`);
export const configIncomeTypes = () => axiosInstance.get(`/config/income-categories`);
export const configDebtTypes = () => axiosInstance.get(`/config/debt-types`);

export const configCountries = () => axiosInstance.get(`/config/countries`);
export const configDistricts = () => axiosInstance.get(`/config/districts`);
export const configCommunes = (district) => axiosInstance.get(`/config/districts/${district}/communes`);
export const configCommunePrices = (district, commune, cur) => axiosInstance.get(`/config/districts/${district}/communes/${commune}/prices/${cur}`);
export const configQuartiers = (district, commune) => axiosInstance.get(`/config/districts/${district}/communes/${commune}/quartiers`);
export const configQuartierPrices = (district, commune, quartier, cur) => axiosInstance.get(`/config/districts/${district}/communes/${commune}/quartiers/${quartier}/prices/${cur}`);
export const configConstants = () => axiosInstance.get(`/config/constants`);

export const configBrands = () => axiosInstance.get(`/config/car-brands`);
export const configModels = (brand) => axiosInstance.get(`/config/car-models/${brand}/models`);
export const uploadPhoto = (formData) =>
  axiosInstance.post('/photos', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const deletePhoto = (photoId) =>
  axiosInstance.delete(`/photos/${photoId}`);

export const listPhotos = (ownerType, ownerId) =>
  axiosInstance.get(`/photos/${ownerType}/${ownerId}`);


export const uploadProfilePhoto = (photo) => {
  const formData = new FormData();
  formData.append('file', photo);
  return axiosInstance.post(`/users/profile-photo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Asset Endpoints
export const getAssets = (active_only) => {
  const query = active_only === true ? `?active=true` : '';
  return axiosInstance.get(`/assets${query}`);
};
export const createAsset = (asset, details, forecast, location = null, flag_deposit = false, smooth_deposit = false) => axiosInstance.post(`/assets`, {
  asset: asset,
  details: details,
  forecast: forecast,
  location: location,
  flag_deposit: flag_deposit,
  smooth_deposit: smooth_deposit
});
export const readAsset = (assetId) => axiosInstance.get(`/assets/${assetId}`);
export const deleteAsset = (assetId) => axiosInstance.delete(`/assets/${assetId}`);
export const sellAsset = (assetId) => axiosInstance.post(`/assets/${assetId}/sell`);
export const historyAsset = (assetId) => axiosInstance.get(`/stats/assets/${assetId}/history`);
export const statsAsset = (assetId) => axiosInstance.get(`/stats/assets/${assetId}`);
export const updateAsset = (assetId, data) => axiosInstance.put(`/assets/${assetId}`, data);

// forecast
export const getForecast = (assetId) => axiosInstance.get(`/forecasts/${assetId}`);
export const updateForecast = (data) => axiosInstance.post(`/forecasts`, data);
export const statsForecast = (forecast) => axiosInstance.post(`/forecasts/stats`, forecast);

// Asset details
//export const createAssetDetails = (details) => axiosInstance.post(`/asset_details/real_estate`, detai
export const getLocation = (locations_id) => axiosInstance.get(`/generic/locations/${locations_id}`);
export const getImmo = (immo_id) => axiosInstance.get(`/generic/real_estates/${immo_id}`);
export const getAuto = (auto_id) => axiosInstance.get(`/generic/automobiles/${auto_id}`);
export const readAssetImmo = (assetId) => axiosInstance.get(`/generic/real_estates/assets/${assetId}`);
export const readAssetAuto = (assetId) => axiosInstance.get(`/generic/automobiles/assets/${assetId}`);
export const readAssetEstimation = (assetId) => axiosInstance.get(`/estimations/assets/${assetId}`);
export const getAssetFull = (assetId) => axiosInstance.get(`/assets/${assetId}/full`)
// Login user with credentials and token
export const loginUser = (credentials, token) => axiosInstance.post(`/users/login`, credentials, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const createUser = (userData) => axiosInstance.post(`/users`, userData);
export const updateUser = (userData) => axiosInstance.put(`/users`, userData);
export const getUser = () => axiosInstance.get(`/users`);
export const deleteUser = () => axiosInstance.delete(`/users`);
export const getUserStatsHistory = () => axiosInstance.get(`/stats/history`);
// Estimations
export const createEstimation = (estimation) => axiosInstance.post(`/estimations`, estimation);
export const getEstimations = () => axiosInstance.get(`/estimations`);
export const deleteEstimation = (estimationId) => axiosInstance.delete(`/estimations/${estimationId}`);
// Transactions endpoints
export const createIncome = (IncomeData) => axiosInstance.post(`/assets/income`, IncomeData);
export const createExpense = (ExpenseData) => axiosInstance.post(`/assets/expenses`, ExpenseData);
export const createTransaction = (asset_id, TransactionData) => axiosInstance.post(`/assets/${asset_id}/transactions`, TransactionData);
export const deleteLastTransaction = (asset_id) => axiosInstance.delete(`/assets/${asset_id}/transaction`);
export const getCashTransactions = () => axiosInstance.get(`/transactions/cash`);
export const createAppreciation = (ApprecData) => axiosInstance.post(`/transactions`, ApprecData);
export const getTransactions = (asset_id) => axiosInstance.get(`/assets/${asset_id}/transactions`);
export const deleteTransaction = (transaction_id) => axiosInstance.delete(`/transactions/${transaction_id}`);
export const updateTransaction = (transaction_id, transaction) => axiosInstance.put(`/transactions/${transaction_id}`, transaction);
export const cash_deposit = (cash) => axiosInstance.post(`/transactions/cash_deposit`, cash);
export const cash_withdrawal = (cash) => axiosInstance.post(`/transactions/cash_withdrawal`, cash);
export const cash_update = (transaction_id, cash) => axiosInstance.put(`/transactions/${transaction_id}`, cash);

export const createTransactionPro = (concierge_id, transactionData) => axiosInstance.post(`/concierges/${concierge_id}/transactions`, transactionData);
export const getTransactionsPro = (concierge_id) => axiosInstance.get(`/concierges/${concierge_id}/transactions`);

// Charts endpoints
export const getUserCashflowChart = () => axiosInstance.get(`/charts/user/cashflow`);
export const getUserWealthChart = () => axiosInstance.get(`/charts/user/wealth`);
export const getAssetCashflowChart = (asset_id) => axiosInstance.get(`/charts/assets/${asset_id}/cashflow`);
export const getAssetValueChart = (asset_id) => axiosInstance.get(`/charts/assets/${asset_id}/value`);
export const getAssetExpenseCategoriesChart = (asset_id) => axiosInstance.get(`/charts/assets/${asset_id}/expenses`);


// Debt endpoints
export const createDebt = (debt) => axiosInstance.post(`/debts`, debt);
export const getDebts = () => axiosInstance.get(`/debts`);
export const deleteDebt = (debtId) => axiosInstance.delete(`/debts/${debtId}`);
export const getDebt = (debtId) => axiosInstance.get(`/debts/${debtId}`);
export const createRepayment = (debt_id, repayment) => axiosInstance.post(`/debts/${debt_id}/repayment`, repayment);
export const getDebtTransactions = (debt_id) => axiosInstance.get(`/debts/${debt_id}/transactions`);

// Concierges getConcierges, updateConcierge, deleteConcierge
export const createConcierge = (concierge) => axiosInstance.post(`/concierges`, concierge);
export const getConcierges = () => axiosInstance.get(`/concierges`);
export const updateConcierge = (conciergeId, concierge) => axiosInstance.put(`/concierges/${conciergeId}`, concierge);
export const deleteConcierge = (conciergeId) => axiosInstance.delete(`/concierges/${conciergeId}`);
export const getConcierge = () => axiosInstance.get(`/concierges/me`);
export const activateConcierge = (conciergeId) => axiosInstance.put(`/concierges/${conciergeId}/activate`);
export const deactivateConcierge = (conciergeId) => axiosInstance.put(`/concierges/${conciergeId}/deactivate`);
export const getStatsHistoryConcierge = () => axiosInstance.get(`/stats/concierges/history`);
export const getUserConcierge = () => axiosInstance.get(`/concierges/user`);
export const getConciergeRevenueChart = () => axiosInstance.get(`/charts/concierge/revenue`);
export const getConciergeOccupancyChart = () => axiosInstance.get(`/charts/concierge/occupancy`);
// Contracts
export const getContracts = () => axiosInstance.get(`/contracts`);
export const createContract = (asset_id, contract) => axiosInstance.post(`/contracts/assets/${asset_id}`, contract);
export const approveContract = (contract_id) => axiosInstance.post(`/contracts/approve`, {request_id: contract_id});
export const reviewContract = (contract_id) => axiosInstance.post(`/contracts/review`, {request_id: contract_id});
export const archiveContract = (contract_id) => axiosInstance.post(`/contracts/archive`, {request_id: contract_id});
export const rejectContract = (contract_id, reason) => axiosInstance.post(`/contracts/reject`, {request_id: contract_id, reason: reason});
export const acceptContract = (contract_id) => axiosInstance.post(`/contracts/accept`, {request_id: contract_id});
export const deleteContract = (contract_id) => axiosInstance.delete(`/contracts/${contract_id}`);
export const refuseContract = (contract_id, reason) => axiosInstance.post(`/contracts/refuse`, {request_id: contract_id, reason: reason});

// Management
export const getManagedAssets = () => axiosInstance.get(`/management/assets`);
export const getOwners = () => axiosInstance.get(`/management/clients`);
export const getPendingTransactions = () => axiosInstance.get(`/management/transactions`);
export const createPendingTransaction = (asset_id, transaction) => axiosInstance.post(`/management/assets/${asset_id}/transactions`, transaction);
export const approvePendingTransaction = (transaction_id) => axiosInstance.post(`/management/transactions/${transaction_id}/approve`);
export const rejectPendingTransaction = (transaction_id, note) => axiosInstance.post(`/management/transactions/${transaction_id}/reject`, { notes: note });
export const reviewPendingTransaction = (transaction_id) => axiosInstance.post(`/management/transactions/${transaction_id}/review`);
export const deletePendingTransaction = (transaction_id) => axiosInstance.delete(`/management/transactions/${transaction_id}`);
export const updatePendingTransaction = (transaction_id, transaction) => axiosInstance.put(`/management/transactions/${transaction_id}`, transaction);
export const submitPendingTransaction = (transaction_id) => axiosInstance.post(`/management/transactions/${transaction_id}/submit`);

// Partners
export const getPartners = () => axiosInstance.get(`/partners`);
export const createPartner = (partner) => axiosInstance.post(`/partners`, partner);
export const updatePartner = (partnerId, partner) => axiosInstance.put(`/partners/${partnerId}`, partner);
export const deletePartner = (partnerId) => axiosInstance.delete(`/partners/${partnerId}`);

// Chats
export const getChats = [];
export const deleteReview = (review_id) => axiosInstance.delete(`/reviews/${review_id}`);
export const getReviews = () => axiosInstance.get(`/reviews`);
export const createReview = (review) => axiosInstance.post(`/reviews`, review);
export const approveReview = (review_id) => axiosInstance.put(`/reviews/${review_id}/approve`);
export const rejectReview = (review_id) => axiosInstance.put(`/reviews/${review_id}/reject`);
export const submitReview = ({ review_id, review }) => axiosInstance.put(`/reviews/${review_id}/submit`, review);
export const getPendingReviews = () => axiosInstance.get(`/reviews/pending`);
export const getRequestedReviews = () => axiosInstance.get(`/reviews/requested`);
export const getAssetsForConcierge = (concierge_id) => axiosInstance.get(`/concierges/${concierge_id}/assets`);
export const getConciergeReviews = (concierge_id) => axiosInstance.get(`/reviews/concierges/${concierge_id}`);
