import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Fab,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../context/DataContext';
import ReviewRequestForm from './ReviewRequestForm';
import { approveReview, rejectReview } from '../../api';

const ReviewsPanelAdmin = () => {
  const { t } = useTranslation();
  const { data, refreshData, refreshAll } = useDataContext();
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleApprove = async (reviewId) => {
    try {
      await approveReview(reviewId);
      refreshAll();
    } catch (error) {
      console.error('Failed to approve review:', error);
    }
  };

  const handleReject = async (reviewId) => {
    try {
      await rejectReview(reviewId);
      refreshAll();
    } catch (error) {
      console.error('Failed to reject review:', error);
    }
  };

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="Review Tabs"
        sx={{ mb: 2 }}
      >
        <Tab label={t('Pending Reviews')} />
        <Tab label={t('Submitted Reviews')} />
      </Tabs>

      <Box sx={{ p: 2 }}>
        {tabValue === 0 ? (
          data.pendingReviews.length ? (
            data.pendingReviews.map((review) => (
              <Card key={review.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('Concierge')}: {review.concierge_name}
                  </Typography>
                  <Typography variant="body2">{t('Rating')}: {review.rating}</Typography>
                  <Typography variant="body2">{t('Comment')}: {review.comment}</Typography>
                  <Typography variant="body2">{t('User')}: {review.user_name}</Typography>
                  <Box mt={2}>
                    <Button color="primary" onClick={() => handleApprove(review.id)}>
                      {t('Approve')}
                    </Button>
                    <Button color="error" onClick={() => handleReject(review.id)}>
                      {t('Reject')}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>{t('No pending reviews')}</Typography>
          )
        ) : (
          data.requestedReviews.length ? (
            data.requestedReviews.map((review) => (
              <Card key={review.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t('Concierge')}: {review.concierge_name}
                  </Typography>
                  <Typography variant="body2">{t('User')}: {review.user_name}</Typography>
                  <Typography variant="body2">{t('Rating')}: {review.rating}</Typography>
                  <Typography variant="body2">{t('Comment')}: {review.comment}</Typography>
                  <Typography variant="body2">{t('Date')}: {review.created_at}</Typography>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography>{t('No requested reviews')}</Typography>
          )
        )}
      </Box>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleOpenDialog}
      >
        <AddIcon />
      </Fab>

      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogContent>
          <ReviewRequestForm onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ReviewsPanelAdmin;
