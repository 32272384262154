import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, CircularProgress, Autocomplete } from '@mui/material';
import { useBrands } from '../hooks/useBrands';
import { useModels } from '../hooks/useModels';

const DetailsAuto = forwardRef(({ data, user, t, onValidSubmit }, ref) => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const currentYear = new Date().getFullYear();

  const years = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => currentYear - i);

  const { brands, isLoading: loadingBrands } = useBrands();
  const { models, fetchModels, resetModels, isLoading: loadingModels } = useModels();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      brand: data?.brand || '',
      model: data?.model || '',
      release_year: data?.release_year || '',
    },
    mode: 'onChange',
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    if (data) {
      for (const key in data) {
        setValue(key, data[key] ?? '');
      }
    }
  }, [data, setValue]);

  const handleFormSubmit = (formData) => {
    const brandName = brands.find((brand) => brand.id === formData.brand)?.name || formData.brand;
    onValidSubmit({
      ...formData,
      brand: brandName,
      estimation: 0,
    });
  };

  const handleBrandChange = async (brand) => {
    setSelectedBrand(brand);
    setValue('brand', brand?.id || brand || ''); // Support free-text input
    setValue('model', ''); // Reset model when brand changes
    resetModels(); // Clear previous models
    if (brand?.id) {
      await fetchModels(brand.id);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      {/* Brand Selector */}
      <Controller
        name="brand"
        control={control}
        rules={{ required: t('brand_required') }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={brands}
            loading={loadingBrands}
            value={selectedBrand}
            freeSolo // Allow free text input
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
            onChange={(e, newValue) => handleBrandChange(newValue || '')}
            onInputChange={(e, newValue) => {
              if (!newValue) setSelectedBrand(null);
              setValue('brand', newValue); // Update form value with custom input
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('brand')}
                margin="normal"
                fullWidth
                error={!!errors.brand}
                helperText={errors.brand ? errors.brand.message : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingBrands ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />

      {/* Model Selector */}
      <Controller
        name="model"
        control={control}
        rules={{ required: t('model_required') }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={models}
            loading={loadingModels}
            freeSolo // Allow free text input
            getOptionLabel={(option) => (typeof option === 'string' ? option : option)} // Expecting models as strings
            onChange={(e, newValue) => setValue('model', newValue || '')}
            onInputChange={(e, newValue) => setValue('model', newValue)} // Update form value with custom input
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('model')}
                margin="normal"
                fullWidth
                error={!!errors.model}
                helperText={errors.model ? errors.model.message : ''}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingModels ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      />

      {/* Release Year Selector */}
      <Controller
        name="release_year"
        control={control}
        rules={{
          required: t('year_required'),
          validate: (value) =>
            years.includes(Number(value)) || t('invalid_year'),
        }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            options={years.map((year) => year.toString())}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => setValue('release_year', newValue || '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('release_year')}
                margin="normal"
                fullWidth
                error={!!errors.release_year}
                helperText={errors.release_year ? errors.release_year.message : ''}
              />
            )}
          />
        )}
      />
    </Box>
  );
});

export default DetailsAuto;
