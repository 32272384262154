import { useState } from 'react';
import { configModels } from '../../../../api';

const modelsCache = {}; // Cache for models keyed by brandId

export const useModels = () => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchModels = async (brandId) => {
    if (modelsCache[brandId]) {
      console.log(`Using cached models for brandId ${brandId}:`, modelsCache[brandId]); // Debugging
      setModels(modelsCache[brandId]);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await configModels(brandId);
      modelsCache[brandId] = response.data; // Cache the response
      console.log(`Models cache updated for brandId ${brandId}:`, modelsCache[brandId]); // Debugging
      setModels(response.data);
    } catch (err) {
      console.error('Failed to fetch models:', err);
      setError(err.message || 'Failed to fetch models');
    } finally {
      setIsLoading(false);
    }
  };

  const resetModels = () => setModels([]); // Clear models when brand changes

  return { models, isLoading, error, fetchModels, resetModels };
};
