import React, { useState, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import {
  Container, Grid, Box, Tabs, Tab, Tooltip, Avatar,
  CardContent, Typography,
} from '@mui/material';
import { StyledCard, CardHeader, CardValue, IconWrapper } from './StyledCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import UserWealthChart from '../charts/UserWealthChart';
import UserCashflowChart from '../charts/UserCashflowChart';
import UserAllocPie from '../charts/UserAllocationPie';
import MainCard from '../../utils/MainCard';
import { formatChangeText } from '../../utils';
import './Dashboard.css';


const DashboardUser = () => {
  const { user } = useContext(UserContext);
  const { data } = useContext(DataContext); // Access preloaded data
  const [tabValue, setTabValue] = useState('tab1');
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const { t } = useTranslation();
  const showCharts = user.net_liq !== 0 || user.assets_value !== 0;

  if (!data) return null;

  const handleClick = (tooltip) => {
    setTooltipContent(tooltip);
    setTooltipOpen(true);
  };

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleTabChange = (value) => {
    if (value !== tabValue) setTabValue(value);
  };

  const statsHistory = data.userStatsHistory || {};
  const cardsData = [
    { icon: <AttachMoneyIcon color="info" />, value: formatAmount(user.net_profit, user.currency), label: t('net_profit'), tooltip: t('tooltip_net_profit') },
    { icon: <SavingsIcon color="primary" />, value: formatAmount(user.contributions, user.currency), label: t('personal_contribution'), tooltip: t('tooltip_savings'), mtd: statsHistory?.contributions_mtd },
    { icon: <AccountBalanceWalletIcon color="success" />, value: formatAmount(user.cash_balance, user.currency), label: t('available_liquidity'), tooltip: t('tooltip_cash_balance') },
    ...(user.preferences?.debts
      ? [{
            icon: <AccountBalanceIcon color="error" />,
            value: formatAmount(user.debts_value, user.currency),
            label: t('running_debts'),
            tooltip: t('tooltip_running_debts') || ''
          }
        ]
      : [])
  ];

  return (
    <Container mb={2}>
      {/* Main Card */}
      <Box display="flex" justifyContent="center" my={2}>
        <MainCard
          title={t('net_worth')}
          value={user.net_liq}
          m_change={statsHistory?.net_worth_mtd}
          y_change={statsHistory?.net_worth_ytd}
          currency={user.currency}
          tooltip={t('tooltip_net_worth')|| ''}
          size="h4"
        />
      </Box>

      {/* Tabs Section */}
      {showCharts && (
        <>
          <Tabs value={tabValue} onChange={(event, newValue) => handleTabChange(newValue)}>
            <Tab label={t('wealth')} value="tab1" />
            <Tab label={t('cashflow')} value="tab2" />
            <Tab label={t('allocation')} value="tab3" />
          </Tabs>

          {/* Charts Section */}
          {tabValue === 'tab1' && <UserWealthChart currency={user.currency} wealthData={data.userWealthChart} />}
          {tabValue === 'tab2' && <UserCashflowChart currency={user.currency} userCashflowChart={data.userCashflowChart} strIncome={t('income')} strExpense={t('expense')} />}
          {tabValue === 'tab3' && <UserAllocPie currency={user.currency} cash_balance={user.cash_balance} assets={data.assets} />}
        </>
      )}

      {/* Cards Section */}
      <Grid container spacing={3} my={3} p={2}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Tooltip
              title={card.tooltip}
              open={tooltipOpen && tooltipContent === card.tooltip}
              onClose={handleClose}
              disableHoverListener
              disableFocusListener
              arrow
            >
              <StyledCard raised onClick={() => handleClick(card.tooltip)}>
                <CardContent>
                  <CardHeader>
                    <IconWrapper>
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        {card.icon}
                      </Avatar>
                    </IconWrapper>
                    <div>
                      <Typography variant="subtitle2" gutterBottom>
                        {card.label}
                      </Typography>
                      <CardValue sx={{ mb: 1 }}>
                        {card.value}
                      </CardValue>
                      {card.mtd ? formatChangeText(card.mtd, (card.mtd / Math.abs(card.value - card.mtd)) * 100, t('MTD'), user.currency) : null}
                    </div>
                  </CardHeader>
                </CardContent>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DashboardUser;
