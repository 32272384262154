import React from 'react';
import { Card, CardMedia, CardActions, Button, Tooltip, Typography } from '@mui/material';

const PhotoCard = ({ photo, onDelete }) => (
  <Card>
    <CardMedia
  component="img"
  height="140"
  image={photo?.file_path
    ? `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${photo.file_path}`
    : '/placeholder-image.png'}
  alt="Photo"
/>
<CardActions>
  <Tooltip title={photo.file_path}>
    <Typography variant="caption">
      {photo.file_path}
    </Typography>
  </Tooltip>
  <Button color="error" onClick={() => onDelete(photo.id)}>
    Delete
  </Button>
</CardActions>
</Card>
);

export default PhotoCard;
