import React, { useContext, useState } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import InboxIllustration from '../../resources/empty-inbox.svg';
import PendingTransactionList from '../pendingTransaction/PendingTransactionList';
import PendingReviewList from '../../components/reviews/PendingReviewList';
import ContractList from '../../components/contracts/ContractList'; // Reusable component
import { DataContext } from '../../context/DataContext';
import { getPendingTransactions, getRequestedReviews } from '../../api';
import { AttachMoney, RateReview, Description } from '@mui/icons-material';

export default function InboxUser() {
  const { user } = useContext(UserContext);
  const { data, refreshData } = useContext(DataContext);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const contracts = (data?.contracts || []).filter(contract => contract.is_pending);

  const tabs = [
    {
      label: t('transactions'),
      icon: <AttachMoney />,
      content: 'transactions',
      description: t('pending_transactions_description', 'Review pending transactions and take action as needed.'),
      show: data.pendingTransactions?.length > 0,
    },
    {
      label: t('reviews'),
      icon: <RateReview />,
      content: 'reviews',
      description: t('pending_reviews_description', 'Provide feedback on pending reviews to keep things moving.'),
      show: data.requestedReviews?.length > 0,
    },
    {
      label: t('requests'),
      icon: <Description />,
      content: 'requests',
      description: t('pending_contracts_description', 'Finalize contracts to complete agreements and proceed.'),
      show: contracts?.length > 0,
    },
  ].filter(tab => tab.show);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {tabs.length > 0 ? (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>

          <Box my={3}>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2, textAlign: 'center' }}>
              {tabs[activeTab]?.description}
            </Typography>
            {tabs[activeTab]?.content === 'transactions' && (
              <PendingTransactionList
                transactions={data.pendingTransactions}
                user={user}
                assets={data.assets}
                refresh={() => refreshData('pendingTransactions', getPendingTransactions)}
              />
            )}
            {tabs[activeTab]?.content === 'reviews' && (
              <PendingReviewList
                reviews={data.requestedReviews}
                refresh={() => refreshData('requestedReviews', getRequestedReviews)}
                role={user.role}
              />
            )}
            {tabs[activeTab]?.content === 'requests' && (
              <ContractList contracts={contracts} expandable={false} showLifecycleFlow={true}/>
            )}
          </Box>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          my={3}
        >
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img
              src={InboxIllustration}
              alt={t('no_actions_required')}
              style={{ animation: 'bounce 2s infinite' }}
            />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
