import React, { useContext, useState } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import InboxIllustration from '../../resources/empty-inbox.svg';
import PendingTransactionList from '../pendingTransaction/PendingTransactionList';
import ContractList from '../../components/contracts/ContractList';
import { DataContext } from '../../context/DataContext';
import { AttachMoney, Description, Chat } from '@mui/icons-material';
import { getPendingTransactions, getContracts, acceptContract, refuseContract, reviewContract } from '../../api';
import { useNotification } from '../../context/NotificationContext';
import { useMutation, useQueryClient } from 'react-query';
import ConfirmDialog from '../../utils/ConfirmDialog';

export default function InboxPro() {
  const { user } = useContext(UserContext);
  const { data, refreshData, refreshAll } = useContext(DataContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [contractToRefuse, setContractToRefuse] = useState(null);

  // Filtered data
  const contracts = (data?.contracts || []).filter((contract) => contract.status === 'REQUESTED' || contract.status === 'UNDER_REVIEW');
  const pendingTransactions = data?.pendingTransactions || [];
  const chats = []; // Placeholder for chat data

  // Mutations
  const acceptContractMutation = useMutation(
    (contractId) => acceptContract({ request_id: contractId }),
    {
      onSuccess: () => {
        showNotification(t('contract_accepted_successfully'), 'success');
        queryClient.invalidateQueries(['contracts', user.id]);
        refreshData('contracts', getContracts);
        refreshAll();
      },
      onError: (error) => {
        showNotification(`${t('contract_acceptance_failed')}: ${error.response?.data?.detail}`, 'error');
      },
    }
  );

  const reviewContractMutation = useMutation(
    (contractId) => reviewContract({ request_id: contractId }),
    {
      onSuccess: () => {
        showNotification(t('start_review'), 'success');
        queryClient.invalidateQueries(['contracts', user.id]);
        refreshData('contracts', getContracts);
      },
      onError: (error) => {
        showNotification(`${t('contract_acceptance_failed')}: ${error.response?.data?.detail}`, 'error');
      },
    }
  );
  const refuseContractMutation = useMutation(
    ({ contractId, reason }) => refuseContract({ request_id: contractId, reason }),
    {
      onSuccess: () => {
        showNotification(t('contract_refused_successfully'), 'success');
        queryClient.invalidateQueries(['contracts', user.id]);
        refreshData('contracts', getContracts);
      },
      onError: (error) => {
        showNotification(`${t('contract_refusal_failed')}: ${error.response?.data?.detail}`, 'error');
      },
    }
  );

  const handleConfirmRefuse = (reason) => {
    if (contractToRefuse) {
      refuseContractMutation.mutate({ contractId: contractToRefuse, reason }); // Pass an object
      setIsDialogOpen(false);
    }
  };

  // Tab Configurations
  const tabs = [
    {
      label: t('contracts'),
      icon: <Description />,
      content: 'contracts',
      description: t('manage_requests_description'),
      show: contracts.length > 0,
    },
    {
      label: t('transactions'),
      icon: <AttachMoney />,
      content: 'transactions',
      description: t('transactions_requiring_input_description'),
      show: pendingTransactions.length > 0,
    },
    {
      label: t('messages'),
      icon: <Chat />,
      content: 'messages',
      description: t('messages_description'),
      show: chats.length > 0,
    },
  ].filter((tab) => tab.show);

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleApproveContract = (contractId) => {
    acceptContractMutation.mutate(contractId);
  };

  const handleReviewContract = (contractId) => {
    reviewContractMutation.mutate(contractId);
  };

  const handleRefuseContract = (contractId) => {
    setContractToRefuse(contractId);
    setIsDialogOpen(true);
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {tabs.length > 0 ? (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start" />
            ))}
          </Tabs>

          <Box my={3}>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
              {tabs[activeTab]?.description}
            </Typography>

            {tabs[activeTab]?.content === 'contracts' && (
              <ContractList
                contracts={contracts}
                expandable={false}
                onApprove={handleApproveContract}
                onReject={handleRefuseContract}
                onReview={handleReviewContract}
                showLifecycleFlow={true}
              />
            )}

            {tabs[activeTab]?.content === 'transactions' && (
              <PendingTransactionList transactions={pendingTransactions} />
            )}

            {tabs[activeTab]?.content === 'messages' && (
              <Box>
                <Typography variant="h6">{t('text_messages')}</Typography>
                {chats.map((message, index) => (
                  <Typography key={index}>{message.content}</Typography>
                ))}
              </Box>
            )}
          </Box>

          {/* Confirmation Dialog */}
          <ConfirmDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onConfirm={handleConfirmRefuse}
            title={t('confirm')}
            message={t('confirm_refusal')}
            confirmLabel={t('refuse')}
            confirmButtonColor="error"
            cancelLabel={t('cancel')}
            requireReason={true}
          />
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" my={3}>
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img
              src={InboxIllustration}
              alt={t('no_actions_required')}
              style={{ animation: 'bounce 2s infinite' }}
            />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
