import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActions,
  Button,
  Slider,
} from '@mui/material';
import { formatAmount } from '../utils';

const EstimationCard = ({ estimation, location, details, currency, t, handleDeleteEstimation }) => {
  const PriceComparisonCard = ({ minPrice, maxPrice, myPrice, currency, t, city }) => {
    const safeMinPrice = Math.min(minPrice, maxPrice);
    const safeMaxPrice = Math.max(minPrice, maxPrice);

    const pricePosition =
      myPrice <= safeMinPrice
        ? 0
        : myPrice >= safeMaxPrice
        ? 100
        : ((myPrice - safeMinPrice) / (safeMaxPrice - safeMinPrice)) * 100;

    const marks = [
      { value: 0, label: '' },
      { value: 20, label: `${formatAmount(minPrice, currency, true)}/m²` },
      { value: 80, label: `${formatAmount(maxPrice, currency, true)}/m²` },
      { value: 100, label: '' },
    ];

    return (
      <Card sx={{ boxShadow: 3, margin: 2, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h7" textAlign="center" gutterBottom>
            {t('prices_in_city')} {city}
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <Slider
                valueLabelDisplay="auto"
                marks={marks}
                disabled
                defaultValue={pricePosition}
                sx={{
                  '& .MuiSlider-track': { backgroundImage: 'linear-gradient(to right, green , red)' },
                  '& .MuiSlider-thumb': { backgroundColor: '#fff' },
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const characteristicCircle = (label) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 60,
        height: 50,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: '1px solid #e0e0e0',
        color: 'black',
        margin: '5px',
      }}
    >
      <Typography variant="body2" align="center">
        {label}
      </Typography>
    </Box>
  );

  return (
    <Card sx={{ boxShadow: 3, margin: 2, borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          {t('price_estimation')}
        </Typography>
        <Typography color="text.secondary" sx={{ textAlign: 'center' }}>
          {location?.address} {location?.quartier}
        </Typography>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          {details?.surface && characteristicCircle(`${details.surface} m²`)}

          {details?.rooms && characteristicCircle(`${details.rooms} ${t('rooms')}`)}
          {location?.city && characteristicCircle(location?.city)}
        </Grid>
        <Box textAlign="center">
          <Typography variant="h5" color="primary" sx={{ fontWeight: 'bold' }}>
            {formatAmount(estimation?.average, currency)}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {t('soit')} {formatAmount(estimation?.average / details?.surface, currency)}/m²
          </Typography>
          <PriceComparisonCard
            minPrice={estimation?.min_sector / details?.surface}
            maxPrice={estimation?.max_sector / details?.surface}
            myPrice={estimation?.average / details?.surface}
            currency={currency}
            t={t}
            city={location?.city}
          />
        </Box>
        <Typography variant="subtitle2" sx={{ mt: 2, textAlign: 'center' }}>
          {t('accuracy_disclaimer')}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        {handleDeleteEstimation && estimation.visible && (
          <Button
            variant="outlined"
            color="error"
            sx={{ textTransform: 'none' }}
            onClick={() => handleDeleteEstimation(estimation.id)}
          >
            {t('delete')}
          </Button>
        )}
        <Button
        variant="outlined"
          sx={{ textTransform: 'none' }}
          onClick={() => window.open('https://wa.me/message/ZTZUNNZHD4JRM1', '_blank')}
        >
          {t('sell/buy')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default EstimationCard;
