import React, { createContext, useEffect, useState, useContext } from 'react';
import buildings from '../resources/buildings.jpg';
import collectibles from '../resources/collectibles.jpeg';
import house from '../resources/house.jpeg';
import apartment from '../resources/apartment.jpeg';
import equipment from '../resources/equipment.jpeg';
import financial_product from '../resources/financial_product.jpeg';
import land from '../resources/land.jpeg';
import other from '../resources/other.jpeg';
import vehicule from '../resources/vehicule.jpeg';

// Define the image sources
const imageSources = {
  'REAL_ESTATE': buildings,
  'FINANCIAL_PRODUCT': financial_product,
  'EQUIPMENT': equipment,
  'AUTOMOBILE': vehicule,
  'COLLECTIBLES': collectibles,
  'HOUSE': house,
  'APARTMENT': apartment,
  'LAND': land,
  'OTHER_ASSET': other,
};

// Utility function to convert an image URL to base64
const convertToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

// Create the context
const ImageContext = createContext({});

// Provider Component
export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = async () => {
      const loadedImages = {};
      const promises = Object.entries(imageSources).map(([type, url]) =>
        convertToBase64(url)
          .then((base64) => {
            loadedImages[type] = base64;
            localStorage.setItem(`image_${type}`, base64);
          })
          .catch((error) => {
            console.error(`Failed to load ${type} image:`, error);
            loadedImages[type] = other; // Fallback image
          })
      );
      await Promise.all(promises);
      setImages(loadedImages);
      setLoading(false);
    };

    preloadImages();
  }, []);

  return (
    <ImageContext.Provider value={{ images, loading }}>
      {children}
    </ImageContext.Provider>
  );
};

export const useImages = () => useContext(ImageContext);
