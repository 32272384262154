import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import ContractList from './ContractList';
import { useTranslation } from 'react-i18next';
import { Container, Box, Typography } from '@mui/material';

export default function Contract() {
  const { data } = useContext(DataContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const isAdmin = user.role === 'ADMIN';
  const contracts = (data.contracts || []).filter(contract =>
    contract.is_pending == false
  );

  if (isAdmin) {
    // Admin-specific logic: group contracts by concierge
    const groupedContracts = contracts.reduce((acc, contract) => {
      const conciergeId = contract.concierge_id || 'Unknown';
      if (!acc[conciergeId]) {
        acc[conciergeId] = {
          conciergeName: contract.concierge_name || t('unknown_concierge'),
          contracts: [],
        };
      }
      acc[conciergeId].contracts.push(contract);
      return acc;
    }, {});

    const groupedContractsArray = Object.values(groupedContracts);

    return (
      <Container maxWidth="md" justifyContent="center">
        <Box display="flex" justifyContent="center" my={5}>
          <Typography variant="h4">{t('contracts')}</Typography>
        </Box>

        {groupedContractsArray.length > 0 ? (
          groupedContractsArray.map((group, index) => (
            <Box key={index} my={3}>
              <ContractList
                contracts={group.contracts}
                title={group.conciergeName}
                userRole={user.role}
                expandable={group.contracts.length > 0}
                showLifecycleFlow={false}
              />
            </Box>
          ))
        ) : (
          <Box display="flex" justifyContent="center" my={3}>
            <Typography variant="body1">{t('no_contract')}</Typography>
          </Box>
        )}
      </Container>
    );
  }


  const active_contracts = (data.contracts || []).filter(contract =>
    ['ACTIVE'].includes(contract.status)
  );
  const close_contracts = (data.contracts || []).filter(contract =>
    ['REJECTED', 'REFUSED', 'ARCHIEVED'].includes(contract.status)
  );

  const expandable = active_contracts.length > 0 && close_contracts.length > 0;

  return (
    <Container>
      <Box display="flex" justifyContent="center" my={5}>
          <Typography variant="h4">{t('contracts')}</Typography>
      </Box>
      {Object.keys(active_contracts).length === 0 && close_contracts.length === 0 ? (
        <Box textAlign="center" mt={5}>
          <Typography variant="h6">{t('no_contract')}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_contract_prompt')}
          </Typography>
        </Box>
      ) : (
        <>
          {active_contracts.length > 0 && (
            <ContractList
              contracts={active_contracts}
              title={t('validated_contracts')}
              userRole={user.role}
              expandable={expandable}
              showLifecycleFlow={false}
            />
          )}

          {close_contracts.length > 0 && (
            <ContractList
              contracts={close_contracts}
              title={t('non_validated_contracts')}
              userRole={user.role}
              expandable={expandable}
              showLifecycleFlow={false}
            />
          )}
        </>
      )}
    </Container>
  );
}
