import React from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CircularProgress } from '@mui/material';


const ConciergeOccupancyChart = ({ occupancyChartData }) => {

  const { t } = useTranslation();

  if (!occupancyChartData || Object.keys(occupancyChartData).length === 0) {
    return <CircularProgress />;
  }

  // Prepare the chart data
  const categories = Object.keys(occupancyChartData); // Months
  const occupancyData = categories.map(
    (month) => occupancyChartData[month] || 0 // Ensure we don't break on missing data
  );

  const options = {
    chart: {
      type: 'column',
      zoomType: 'x',
      backgroundColor: 'transparent',
      height: 450,
      panning: true,
      panKey: 'shift',
    },
    title: null,
    xAxis: {
      categories,
      tickmarkPlacement: 'on',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Arial, sans-serif',
        },
      },
    },
    yAxis: {
      title: null,
      labels: {
        formatter: function () {
          return `${(this.value * 100).toFixed(2)}%`; // Format as percentage
        },
      },
    },
    legend: {
      enabled: false, // No need for a legend since this is a single series
    },
    series: [
      {
        name: t('occupancy_rate'),
        data: occupancyData,
        color: Highcharts.getOptions().colors[0],
      },
    ],
    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function () {
        return `<b>${this.x}</b><br/>${t('occupancy_rate')}: ${(this.y * 100).toFixed(2)}%`;
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            chart: {
              scrollablePlotArea: {
                minWidth: 300,
                scrollPositionX: 1,
              },
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ConciergeOccupancyChart;
