import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';
import ClickableTooltip from './utils/ClickableTooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import i18next from 'i18next';

// Mapping of currency codes to locales
const currencyToLocaleMap = {
  USD: 'en-US',
  EUR: 'fr-FR',
  GBP: 'en-GB',
  JPY: 'ja-JP',
  XOF: 'fr-SN'
  // ... add more currencies and their typical locales as needed
};

export function getCurrencyFormatter(currency) {
  // Find the locale based on the currency, defaulting to 'fr-FR' if not found in the map
  const locale = currencyToLocaleMap[currency] || 'fr-FR';

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function currencySymbol( currency ) {
  if(currency == null)
    return null;
  // Get the appropriate currency formatter
  const formatter = getCurrencyFormatter(currency);

  // Extract the currency symbol from the formatter
  return formatter.formatToParts(0).find(part => part.type === 'currency').value;
}

export function formatAmount(amount, currency, simplify = false, title = false) {
  if(currency == null)
    return amount;
  // Get the appropriate currency formatter
  const formatter = getCurrencyFormatter(currency);

  // Extract the currency symbol from the formatter
  const currencySymbol = formatter.formatToParts(0).find(part => part.type === 'currency').value;


  if (simplify) {
    function format(value, divisor, suffix) {
      const result = value / divisor;
      // Check if the result is an integer
      if (result % 1 === 0) {
        // Integer, no decimal
        return  result.toString() + suffix +' '+ currencySymbol;
      } else {
        // Not an integer, show one decimal
        return  result.toFixed(1) + suffix +' '+ currencySymbol;
      }
    }

    if (Math.abs(amount) >= 1e9) {
      // Billions
      return format(amount, 1e9, 'B');
    } else if (Math.abs(amount) >= 1e6) {
      // Millions
      return format(amount, 1e6, 'M');
    } else if (Math.abs(amount) >= 1e3) {
      // Thousands
      return title ? formatter.format(amount) : format(amount, 1e3, 'k');
    }
  }

  // If not simplifying, or the number is smaller than 1k, use the regular formatter
  return formatter.format(amount);
}


  // General function to create a handler
  export const createAmountChangeHandler = (setFormattedAmount, currency) => (e) => {
    const input = e.target;
    let amount = input.value;
    const caretPosition = input.selectionStart;

    amount = amount.replace(/[^0-9.]/g, "");

    if (amount.includes('.')) {
      const parts = amount.split('.');
      amount = parts[0] + '.' + parts[1].slice(0, 2);
    }

    const formattedAmount = formatAmount(amount, currency);
    setFormattedAmount(formattedAmount);

    setTimeout(() => {
      let newCaretPosition = caretPosition + (formattedAmount.length - input.value.length);
      input.setSelectionRange(newCaretPosition, newCaretPosition);
    }, 0);

    return amount;
  };

  export const interestRateChangeHandler = (setFormattedInterestRate) => (e)  => {
    const input = e.target;
    let rate = input.value;
    const caretPosition = input.selectionStart;

    rate = rate.replace(/[^0-9.]/g, "");

    if (rate.includes('.')) {
      const parts = rate.split('.');
      rate = parts[0] + '.' + parts[1].slice(0, 2);
    }

    setFormattedInterestRate(rate);

    setTimeout(() => {
      input.setSelectionRange(caretPosition, caretPosition);
    }, 0);

    return rate;
  };

  export const calculateROI = (asset) => {
    const costOfInvestment = asset.purchase_price + asset.acquisition_cost; // The total investment cost now includes purchase price and acquisition cost.
    const netProfit = asset.value + asset.cashflow - costOfInvestment; // Net profit includes cashflow and subtracts the total investment cost from current value.

    // Check if the required values are available and valid numbers.
    if (typeof costOfInvestment !== 'number' || typeof asset.value !== 'number' || typeof asset.cashflow !== 'number') {
      return 'N/A'; // Return 'N/A' if any of the values are missing or not numbers.
    }

    const roi = (netProfit / costOfInvestment) * 100;
    return roi.toFixed(2) + '%'; // ROI is usually expressed as a percentage.
  };

  const getReadableTimeFrame = (timeFrame, t) => {
    const currentDate = new Date();

    if (timeFrame === 'YTD') {
      return t('in_year', { year: currentDate.getFullYear() }); // Example: "en 2023"
    }

    if (timeFrame === 'MTD') {
      const monthName = currentDate.toLocaleString(i18next.language, { month: 'long' }); // Get the month name based on the locale
      return t('in_month', { month: monthName }); // Example: "en Janvier"
    }

    return timeFrame; // Fallback for unexpected timeFrame
  };

  // Helper function to format the change text with arrows
export const formatChangeText = (change, percent, timeFrame, currency, t) => {
  if (change == null) {
    return null;
  }

  const readableTimeFrame = getReadableTimeFrame(timeFrame, t); // Generate the readable time frame
  const Icon = change > 0 ? ArrowUpwardIcon : change < 0 ? ArrowDownwardIcon : RemoveIcon;

  return (
    <Typography variant="caption" color="textSecondary" style={{ display: 'inline-flex', textAlign: 'right' }}>
      <ClickableTooltip title={`${readableTimeFrame} ${t('change')}`}>
        <span style={{ color: change >= 0 ? 'green' : 'red', display: 'inline-flex', alignItems: 'right' }}>
          <Icon style={{ fontSize: '1.5em', marginRight: '1px' }} />
          {formatAmount(Math.abs(change), currency, true, true)}
          {isFinite(percent) ? `(${percent.toFixed(1)}%)` : null}
        </span>
      </ClickableTooltip>
      <Typography component="span" variant="caption">
        &nbsp; {readableTimeFrame}
      </Typography>
    </Typography>
  );
};
