import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ConfigContext } from '../../../../context/ConfigContext';
import { Box, TextField, FormControl, InputLabel, Select, Button, MenuItem, FormHelperText, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExploitationSelector from '../components/ExploitationSelector';

const GeneralForm = forwardRef(({ assetIn, t, onValidSubmit }, ref) => {
  const { configs } = useContext(ConfigContext);
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(false);
  const type = assetIn.type;
  const assetCategories = configs.assetCategories || [];
  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      name: assetIn?.name || '',
      description: assetIn?.description || '',
      category: assetIn?.category || '',
      exploitation: assetIn?.exploitation || '',
      purchase_date: assetIn?.purchase_date || new Date().toISOString().split('T')[0],
    },
    mode: "onChange"
  });

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));



  // Dynamically set the category's default value when type changes
  useEffect(() => {
    if (!assetIn.category && assetCategories && assetCategories[type] && assetCategories[type].length > 0) {
      setValue("category", assetCategories[type][0]);
    } else if (assetIn.category) setValue("category", assetIn.category);
  }, [assetIn.category, type, assetCategories, setValue]);

  useEffect(() => {
    if (assetIn) {
      for (const key in assetIn) {
        setValue(key, assetIn[key]);
      }
    }
  }, [assetIn, setValue]);

  const handleFormSubmit = (data) => {
    onValidSubmit(data); // Pass the form data to the parent
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}
      sx={{
        '& .MuiTextField-root': { mb: 2, width: '100%' },
        '& .MuiFormControl-root': { mb: 2, width: '100%' }
      }}
    >
      {/* Main Form Fields */}
      <Controller
        name="name"
        control={control}
        rules={{ required: t('required') }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            label={t('name')}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : ''}
          />
        )}
      />

      <FormControl fullWidth error={!!errors.category} >
        <InputLabel id="category-label">{t('category')}</InputLabel>
        <Controller
          name="category"
          control={control}
          rules={{ required: t('category_required') }}
          render={({ field }) => (
            <Select {...field} labelId="category-label" label={t('category')}>
              {assetCategories && assetCategories[type] && assetCategories[type].map(category => (
                <MenuItem key={category} value={category}>{t(category)}</MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{errors.category?.message}</FormHelperText>
      </FormControl>

      <Controller
        name="purchase_date"
        control={control}
        rules={{ required: t('purchase_date_required') }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            type="date"
            label={type === "FINANCIAL_PRODUCT" ? t('opening_date') : t('purchase_date')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                max: new Date().toISOString().split("T")[0], // Set the maximum date to today
              },
            }}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
          />
        )}
      />

      {/* Toggle Button for Additional Info */}
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }}>
        <Button
          size="small"
          startIcon={isAdditionalInfoOpen ? <RemoveIcon /> : <AddIcon />}
          onClick={() => setIsAdditionalInfoOpen(prev => !prev)}
        >
          {isAdditionalInfoOpen ? t('less_details') : t('more_details')}
        </Button>
      </Box>

      {/* Collapsible Section for Additional Information */}
      <Collapse in={isAdditionalInfoOpen} timeout="auto" unmountOnExit>
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField {...field} label={t('description')} fullWidth />
          )}
        />

        <ExploitationSelector
          control={control}
          errors={errors}
          selectedAssetType={type}
          t={t}
          setValue={setValue}
          exploi_default={assetIn?.exploitation}
        />
      </Collapse>
    </Box>
  );
});

export default GeneralForm;
