import React, { useState, useContext, useCallback } from 'react';
import {
  Box, Container, Fab, Grid, Button, Fade, Tab, Tabs, Typography, Modal,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { deleteEstimation, createEstimation, getEstimations } from '../../api';
import EstimationCreationWizard from './EstimationCreationWizard';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import EstimationCard from '../../utils/EstimationCard';
import { useNotification } from '../../context/NotificationContext';
import ConfirmDialog from '../../utils/ConfirmDialog';

const Estimations = () => {
  const { user } = useContext(UserContext);
  const { data, refreshData } = useContext(DataContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [activeTab, setActiveTab] = useState(0);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [estimationToDelete, setEstimationToDelete] = useState(null);

  // Group estimations by category
  const groupByCategory = (estimations) =>
    estimations.reduce((acc, estimation) => {
      const category = estimation.details?.category || 'Other';
      acc[category] = acc[category] || [];
      acc[category].push(estimation);
      return acc;
    }, {});

  const estimations = groupByCategory(data.estimations || []);
  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const confirmDelete = (estimationId) => {
    setEstimationToDelete(estimationId);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      if (estimationToDelete) {
        await deleteEstimation(estimationToDelete);
        await refreshData('estimations', getEstimations);
        showNotification(t('estimation_deleted_successfully'), 'success');
      }
    } catch (error) {
      console.error('Error deleting estimation:', error);
      showNotification(t('operation_failed'), 'error');
    } finally {
      setDeleteDialogOpen(false);
      setEstimationToDelete(null);
    }
  };

  const handleWizardSubmitEstimation = async (details, location = null) => {
    try {
      const payload = { details, location };
      await createEstimation(payload);
      await refreshData('estimations', getEstimations);
      setIsWizardOpen(false);
      showNotification(t('estimation_creation_successful'), 'success');
    } catch (error) {
      console.error('Error creating estimation:', error);
      showNotification(t('estimation_creation_failed'), 'error');
    }
  };

  const handleOpenWizard = () => setIsWizardOpen(true);
  const handleCloseWizard = () => setIsWizardOpen(false);

  return (
    <Container maxWidth="md">
      {Object.keys(estimations).length === 0 ? (
        <Box textAlign="center" mt={5}>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_valuation_prompt')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenWizard}
            sx={{ mt: 3 }}
          >
            {t('add_your_first_valuation')}
          </Button>
        </Box>
      ) : (
        <>
          <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ mb: 2 }}>
            {Object.keys(estimations).map((category, index) => (
              <Tab label={t(category)} key={category} />
            ))}
          </Tabs>

          {Object.keys(estimations).map((category, index) => (
            <TabPanel value={activeTab} index={index} key={category}>
              <Grid container spacing={2}>
                {estimations[category].map((estimation) => (
                  <Grid item xs={12} sm={6} md={4} key={estimation.id}>
                    <EstimationCard
                      estimation={estimation}
                      location={estimation.location}
                      details={estimation.details}
                      currency={user.currency}
                      t={t}
                      handleDeleteEstimation={confirmDelete}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          ))}

          <Fab
            color="primary"
            aria-label={t('add_estimation')}
            onClick={handleOpenWizard}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <AddIcon />
          </Fab>
        </>
      )}

      {/* Estimation Creation Wizard */}
      <Modal
        open={isWizardOpen}
        onClose={handleCloseWizard}
        closeAfterTransition
        BackdropProps={{ timeout: 1500 }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={isWizardOpen}>
          <Box
            sx={{
              background: 'linear-gradient(rgb(255, 255, 255), rgba(162, 225, 238, 0.988))',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              maxWidth: '500px',
              maxHeight: '90vh',
              overflow: 'auto',
            }}
          >
            <EstimationCreationWizard
              applyEstimation={handleWizardSubmitEstimation}
              onCancel={handleCloseWizard}
              t={t}
              user={user}
            />
          </Box>
        </Fade>
      </Modal>

      {/* Confirm Dialog for Deletion */}
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title={t('delete_transaction_title')}
        message={t('delete_message')}
        cancelLabel={t('cancel')}
        confirmLabel={t('delete')}
        confirmButtonColor="error"
      />
    </Container>
  );
};

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default Estimations;
