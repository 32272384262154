import React, { useContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import {
  getPendingTransactions,
  createPendingTransaction,
  rejectPendingTransaction,
  updatePendingTransaction,
} from '../../api';
import TransactionManager from './TransactionManager';
import PendingTransactionList from '../pendingTransaction/PendingTransactionList';
import { useNotification } from '../../context/NotificationContext';

export default function Transactions() {
  const { user, refreshUser } = useContext(UserContext);
  const { data, refreshAll, refreshData } = useContext(DataContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  const [basicModal, setBasicModal] = useState(false);
  const [rejectModal, setRejectModal] = useState({ open: false, transactionId: null });
  const [rejectNote, setRejectNote] = useState('');
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const toggleShow = () => {
    setInitialData(null);
    setBasicModal(!basicModal);
  };

  const assets = data.assets || [];
  const owners = data.owners || [];
  const partners = [];

  const createTransactionMutation = useMutation(
    ({ asset_id, transaction }) => createPendingTransaction(asset_id, transaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pendingTransactions');
        refreshUser();
        refreshData('pendingTransactions', getPendingTransactions);
      },
    }
  );

  const updateTransactionMutation = useMutation(
    ({ transaction_id, transaction }) => updatePendingTransaction(transaction_id, transaction),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pendingTransactions');
        refreshUser();
        refreshData('pendingTransactions', getPendingTransactions);
      },
    }
  );

  const rejectTransactionMutation = useMutation(
    ({ transaction_id, note }) => rejectPendingTransaction(transaction_id, note),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('pendingTransactions');
        refreshUser();
      },
    }
  );

  const handleNewTransaction = async (formData) => {
    try {
      setIsLoading(true);
      await createTransactionMutation.mutateAsync({ asset_id: formData.asset_id, transaction: formData });
      refreshData('pendingTransactions', getPendingTransactions);
      showNotification(t('transaction_created_successfully'), 'success');
      toggleShow();
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateTransaction = async (formData) => {
    try {
      setIsLoading(true);
      await updateTransactionMutation.mutateAsync({ transaction_id: formData.id, transaction: formData });
      showNotification(t('transaction_updated_successfully'), 'success');
      refreshData('pendingTransactions', getPendingTransactions);
      toggleShow();
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectTransaction = async () => {
    try {
      setIsLoading(true);
      await rejectTransactionMutation.mutateAsync({ transaction_id: rejectModal.transactionId, note: rejectNote });
      refreshData('pendingTransactions', getPendingTransactions);
      showNotification(t('transaction_rejected_successfully'), 'success');
      setRejectModal({ open: false, transactionId: null });
      setRejectNote('');
    } catch (error) {
      showNotification(t('transaction_operation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenRejectModal = (transactionId) => {
    setRejectModal({ open: true, transactionId });
  };

  const handleCloseRejectModal = () => {
    setRejectModal({ open: false, transactionId: null });
    setRejectNote('');
  };

  const openUpdateTransaction = (transaction) => {
    setEditingTransaction(transaction);
    const asset = assets.find((a) => a.id === transaction.asset_id);
    const owner = owners.find((c) => c.id === asset?.user_id);

    setInitialData({
      id: transaction.id,
      owner_id: owner?.id || '',
      asset_id: transaction.asset_id,
      date: transaction.date || '',
      transactions: transaction.details || [],
      partner_id: transaction.partner_id || null,
      notes: transaction.notes || '',
      repeat_until: transaction.repeat_until || null,
    });

    setBasicModal(true);
  };

  const handleSubmitForm = (formData) => {
    editingTransaction ? handleUpdateTransaction(formData) : handleNewTransaction(formData);
    refreshData('pendingTransactions', getPendingTransactions);
  };

  return (
    <Container maxWidth="md">

      <Box display="flex" justifyContent="center" my={5}>
        <Typography variant="h4">{t('transactions')}</Typography>
      </Box>
      <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
        {t('transactions_description')}
      </Typography>
      {data.pendingTransactions?.length === 0 ? (
        <Box textAlign="center" m={3}>
          {user.role === 'PRO' && (
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={toggleShow} sx={{ mt: 3 }} disabled={!assets.length}>
              {t('add_transaction')}
            </Button>
          )}
        </Box>
      ) : (
        <>
          <PendingTransactionList
            transactions={data.pendingTransactions}
            user={user}
            assets={assets}
            partners={partners}
            owners={owners}
            refresh={() => refreshData('pendingTransactions', getPendingTransactions)}
            handleEditTransaction={openUpdateTransaction}
          />
          <Fab
            color="primary"
            aria-label={t('add_transaction')}
            onClick={toggleShow}
            disabled={!assets?.length || user.role === 'INDIVIDUAL'}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16
            }}
          >
            <AddIcon />
          </Fab>
        </>
      )}



      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="md">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>{t('transaction_request')}</DialogTitle>
        <DialogContent>
          <TransactionManager user={user} apply={handleSubmitForm} toggleShow={toggleShow} assets={assets} owners={owners} initialData={initialData} partners={partners} />
        </DialogContent>
      </Dialog>

      <Dialog open={rejectModal.open} onClose={handleCloseRejectModal} fullWidth maxWidth="sm">
        <DialogTitle>{t('reject_transaction')}</DialogTitle>
        <DialogContent>
          <TextField fullWidth label={t('reject_note')} multiline rows={4} value={rejectNote} onChange={(e) => setRejectNote(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRejectModal}>{t('cancel')}</Button>
          <Button onClick={handleRejectTransaction} color="secondary">{t('reject')}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
