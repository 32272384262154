import React, { useEffect, useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../context/NotificationContext';
import { getContracts } from '../../api';
import { DataContext } from '../../context/DataContext';

const getEndOfYearDate = () => {
  const currentYear = new Date().getFullYear();
  return `${currentYear}-12-31`;
};

const ContractForm = ({ user, apply, toggleShow, assets, concierges, preselectedConcierge, contracts }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { refreshData } = useContext(DataContext);
  const [isCar, setIsCar] = useState(false); // State to track if the selected asset is a car

  const { control, handleSubmit, setValue, watch, formState: { errors, isValid } } = useForm({
    defaultValues: {
      user_id: user.id,
      asset_id: '',
      concierge_id: preselectedConcierge ? preselectedConcierge.id : '',
      message: '',
      start_date: new Date().toISOString().split('T')[0],
      expiry_date: getEndOfYearDate(),
      status: 'REQUESTED',
      details: {},
    },
    mode: "onChange", // This ensures validation runs on every change
  });
  const selectedAssetId = watch('asset_id');
    // Update the "isCar" state when asset_id changes
    useEffect(() => {
      const selectedAsset = assets.find((asset) => asset.id === selectedAssetId);
      if (selectedAsset && selectedAsset.category === 'CAR') { // Adjust "category" based on your actual data
        setIsCar(true);
      } else {
        setIsCar(false);
      }
    }, [selectedAssetId, assets]);

  // Filter assets based on active contracts
  const filteredAssets = assets.filter((asset) => {
    const isAssetInContract = contracts.some(
      (contract) =>
        contract.asset_id === asset.id &&
        ['REQUESTED', 'UNDER_REVIEW', 'ACCEPTED', 'ACTIVE'].includes(contract.status)
    );
    return !isAssetInContract; // Exclude assets already in an active/submitted/accepted contract
  });

  useEffect(() => {
    if (preselectedConcierge) {
      setValue('concierge_id', preselectedConcierge.id);
    }
  }, [preselectedConcierge, setValue]);

  const onSubmit = async (data) => {
    try {
      // Add mileage to details if applicable
      if (isCar) {
        data.details = {
          ...data.details,
          mileage: data.mileage, // Add mileage to details
        };
      }

      await apply(data);
      toggleShow();
      refreshData('contracts', getContracts);
    } catch (error) {
      console.error('Failed to create contract:', error);
      showNotification(t('contract_creation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense" error={!!errors.asset_id}>
              <InputLabel>{t('asset')}</InputLabel>
              <Controller
                name="asset_id"
                control={control}
                rules={{ required: t('required') }}
                render={({ field }) => (
                  <Select {...field} label={t('asset')}>
                    {filteredAssets.map(asset => (
                      <MenuItem key={asset.id} value={asset.id}>
                        {asset.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.asset_id && <p>{t('asset_id_required')}</p>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="dense" error={!!errors.concierge_id}>
              <InputLabel>{t('concierge')}</InputLabel>
              <Controller
                name="concierge_id"
                control={control}
                rules={{ required: t('required') }}
                render={({ field }) => (
                  <Select {...field} label={t('concierge')} disabled={!!preselectedConcierge}>
                    {concierges.map(concierge => (
                      <MenuItem key={concierge.id} value={concierge.id}>
                        {concierge.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.concierge_id && <p>{t('concierge_id_required')}</p>}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('message')}
                  multiline
                  rows={3}
                />
              )}
            />
          </Grid>
          {/* Mileage Field (Shown Only for Cars) */}
          {isCar && (
            <Grid item xs={12}>
              <Controller
                name="mileage"
                control={control}
                rules={{
                  required: t('mileage_required'),
                  min: { value: 0, message: t('mileage_positive') },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('current_mileage')}
                    type="number"
                    error={!!errors.mileage}
                    helperText={errors.mileage?.message}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Controller
              name="start_date"
              control={control}
              rules={{ required: t('required') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('start_date')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.start_date}
                  helperText={errors.start_date && t('start_date_required')}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="expiry_date"
              control={control}
              rules={{ required: t('required') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('expiry_date')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.expiry_date}
                  helperText={errors.expiry_date && t('expiry_date_required')}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShow} color="secondary">
          {t('cancel')}
        </Button>
        <Button color="primary" type="submit" disabled={!isValid}>
          {t('save')}
        </Button>
      </DialogActions>
    </form>
  );
};

export default ContractForm;
