import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  InputAdornment,
  Modal
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import CloseButton from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { createAmountChangeHandler, currencySymbol } from '../../utils';
import { useTheme } from '@mui/material/styles';

const FormRepayment = ({ user, debt, apply, toggleShow, initialData }) => {
  const { t } = useTranslation();
  const [formattedAmount, setFormattedAmount] = useState("");
  const [formattedInterestAmount, setFormattedInterestAmount] = useState("");
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      date: initialData?.date || new Date().toISOString().split('T')[0],
      debt_id: debt.id,
      amount: initialData?.amount ? Math.abs(initialData.amount).toString() : '',
      interest_amount: initialData?.interest_amount ? Math.abs(initialData.interest_amount).toString() : '',
      notes: initialData?.notes || '',
    },
    mode: "onChange",
  });

  const onSubmitHandler = (data) => {
    if (!isNaN(data.amount) && debt.user_id) {
      const dataToSend = {
        ...data,
        user_id: debt.user_id,
        debt_id: debt.id,
        amount: Math.abs(parseFloat(data.amount)),
        interest_amount: Math.abs(parseFloat(data.interest_amount)),
      };
      apply(dataToSend);
      toggleShow();
    }
  };

  const handleAmountChange = createAmountChangeHandler(setFormattedAmount, user.currency);
  const handleInterestAmountChange = createAmountChangeHandler(setFormattedInterestAmount, user.currency);

  return (
    <Modal open={true} onClose={toggleShow}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
        <Paper sx={{ padding: 2, minWidth: 300 }}>
          <Typography variant="h6" sx={{ m: 2 }}>{t(initialData ? 'edit_repayment' : 'add_repayment')}</Typography>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('date')}
                      type="date"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="amount"
                  control={control}
                  rules={{ required: `${t('amount_required')}`, validate: value => value > 0 || `${t('amount_must_be_positive')}` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('amount')}
                      type="number"
                      fullWidth
                      value={field.value}
                      onChange={(e) => {
                        const value = Math.abs(e.target.value);
                        setFormattedAmount(value);
                        field.onChange(value);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                      }}
                    />
                  )}
                />
                {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="interest_amount"
                  control={control}
                  rules={{ validate: value => value >= 0 || `${t('interest_must_be_positive')}` }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('interest_amount')}
                      type="number"
                      fullWidth
                      value={field.value}
                      onChange={(e) => {
                        const value = Math.abs(e.target.value);
                        setFormattedInterestAmount(value);
                        field.onChange(value);
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                      }}
                    />
                  )}
                />
                {errors.interest_amount && <Typography color="error">{errors.interest_amount.message}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="notes"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t('notes')}
                      fullWidth
                      multiline
                      rows={2}
                    />
                  )}
                />
                {errors.notes && <Typography color="error">{errors.notes.message}</Typography>}
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                color="warning"
                startIcon={<CloseButton />}
                onClick={toggleShow}
                sx={{ mr: 1 }}>{t('cancel')}</Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                startIcon={<CheckIcon />}
                disabled={!isValid}
              >
                {t('submit')}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </Modal>
  );
};

export default FormRepayment;
