
import {
    getAssets,
    getConcierges,
    getConcierge,
    getContracts,
    getDebts,
    getUserStatsHistory,
    getManagedAssets,
    getPendingTransactions,
    getPendingReviews,
    getRequestedReviews,
    getUserCashflowChart,
    getUserWealthChart,
    getEstimations,
    getStatsHistoryConcierge,
    getUserConcierge,
    getConciergeRevenueChart,
    getCashTransactions,
    configConstants,
    getConciergeOccupancyChart,
    getOwners
  } from '../api';


  // Role-based loading configuration
  const roleApiMapping = {
    ADMIN: {
      assets: getAssets,
      concierges: getConcierges,
      contracts: getContracts,
      pendingReviews: getPendingReviews,
      requestedReviews: getRequestedReviews,
      pendingTransactions: getPendingTransactions,
      constants: configConstants,
      owners: getOwners,
    },
    PRO: {
      conciergeRevenueChart: getConciergeRevenueChart,
      conciergeOccupancyChart: getConciergeOccupancyChart,
      assets: getManagedAssets,
      conciergeStatsHistory: getStatsHistoryConcierge,
      user_concierge: getUserConcierge,
      pendingTransactions: getPendingTransactions,
      concierge: getConcierge,
      contracts: getContracts,
      constants: configConstants,
      owners: getOwners,
    },
    INDIVIDUAL: {
      assets: getAssets,
      userStatsHistory: getUserStatsHistory,
      userCashflowChart: getUserCashflowChart,
      userWealthChart: getUserWealthChart,
      estimations: getEstimations,
      concierges: getConcierges,
      contracts: getContracts,
      pendingTransactions: getPendingTransactions,
      requestedReviews: getRequestedReviews,
      debts: getDebts,
      cashTransactions: getCashTransactions
    },
  };
  export default roleApiMapping;
