import React, { useEffect, useState, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import { LanguageContext } from '../../../context/LanguageContext';
import { useNotification } from '../../../context/NotificationContext';
import { updateUser, configCurrencies, createUser, configCountries } from '../../../api';
import { useTranslation } from 'react-i18next';
import enFlag from '../../../resources/en_flag.svg';
import frFlag from '../../../resources/fr_flag.svg';
import { AccountCircle, Phone, Public } from '@mui/icons-material';

const ProfileEdit = ({ user, saveUser, open, onClose, onSuccess = () => {}, readOnly = false }) => {
  const { t, i18n } = useTranslation();
  const [currencies, setCurrencies] = useState([]);
  const defaultCountry = 'France';//'Côte d\'Ivoire';
  const [countriesList, setCountries] = useState([defaultCountry]);
  const { language, changeLanguage } = useContext(LanguageContext);
  const { showNotification } = useNotification();
  const { control, handleSubmit, watch, formState: { errors, isValid } } = useForm({
    defaultValues: {
      ...user,
      language: i18n.language,
      currency: user.currency || "",
      role: user.role || "INDIVIDUAL",
      code: "",
      country: user.country || defaultCountry, // Default country is France
    },
    mode: "onChange",
  });

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const res = await configCurrencies();
        if (res.data && Array.isArray(res.data)) {
          setCurrencies(res.data);
        }
      } catch (error) {
        console.error('Failed to fetch currencies', error);
      }
    };
    fetchCurrencies();
  }, [user.currency]);

  useEffect(() => {
    configCountries().then(response => {
      if (!response.data.includes(defaultCountry)) {
        response.data.unshift(defaultCountry);
      }
      setCountries(response.data);
    });
  }, []);

  const selectedRole = watch('role');
  const onSubmit = async (data) => {
    try {
      data['user_id'] = user.id;
      data['language'] = i18n.language;
      const response = readOnly ? await updateUser(data) : await createUser(data);
      saveUser(response.data);
      showNotification(t('profile_creation_successful'));
      onClose();
      onSuccess();
    } catch (error) {
      console.error('Failed to update user', error);
      showNotification(t('profile_edit_failed'), 'error');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
        {user.name ? t('edit_user') : t('add_info')}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box display="flex" flexDirection="column" gap={2}>
            {/* Name */}
            <Controller
              name="name"
              control={control}
              rules = {{ required: t('name_required') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={
                    <Box display="flex" alignItems="center">
                      <AccountCircle sx={{ fontSize: 18, color: 'info.main', mr: 1 }} />
                      {t('name')}
                    </Box>
                  }
                  error={!!errors.name}
                  helperText={errors.name ? t('name_required') : ''}

                />
              )}
            />

            {/* Surname */}
            <Controller
              name="surname"
              control={control}
              rules = {{ required: t('surname_required') }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={
                    <Box display="flex" alignItems="center">
                      <AccountCircle sx={{ fontSize: 18, color: 'info.main', mr: 1 }} />
                      {t('surname')}
                    </Box>
                  }
                  error={!!errors.surname}
                  helperText={errors.surname ? t('surname_required') : ''}

                />
              )}
            />

            {/* Country */}
            <Controller
              name="country"
              control={control}
              rules={{ required: t('required') }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  options={countriesList}
                  getOptionLabel={(option) => option || ""}
                  value={field.value || defaultCountry}
                  onChange={(event, value) => field.onChange(value || "")}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        <Box display="flex" alignItems="center">
                          <Public sx={{ fontSize: 18, color: 'info.main', mr: 1 }} />
                          {t('country')}
                        </Box>
                      }
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              )}
            />

            {/* Phone */}
            <Controller
  name="phone"
  control={control}
  rules={{
    validate: (value) =>
      !value || // Allow empty values
      /^\+?[0-9\s\-]*$/.test(value) || // Validate numbers, spaces, dashes, optional leading "+"
      t('invalid_phone_number'), // Error message
  }}
  render={({ field }) => (
    <TextField
      {...field}
      fullWidth
      label={
        <Box display="flex" alignItems="center">
          <Phone sx={{ fontSize: 18, color: 'secondary.main', mr: 1 }} />
          {t('phone')}
        </Box>
      }
      type="tel"
      error={!!errors.phone}
      helperText={errors.phone ? t(errors.phone.message) : ''}
    />
  )}
/>

            {/* Account Type */}
            <FormControl fullWidth>
              <InputLabel>{t('account_type')}</InputLabel>
              <Controller
                name="role"
                rules = {{ required: t('required') }}
                control={control}
                render={({ field }) => (
                  <Select {...field} label={t('account_type')} disabled={readOnly}>
                    <MenuItem value="INDIVIDUAL">{t('INDIVIDUAL')}</MenuItem>
                    <MenuItem value="ADMIN">{t('ADMIN')}</MenuItem>
                    <MenuItem value="PRO">{t('PRO')}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            {/* Company code */}
            {(selectedRole === "PRO") && !readOnly && (
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={t('company_code')}
                    error={!!errors.code}
                    helperText={errors.code ? t('code_required') : ''}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                )}
              />
            )}

            {/* Currency and Language */}
            <Box display="flex" gap={2}>
              {selectedRole !== "PRO" &&
              <FormControl fullWidth>
                <InputLabel shrink={!!watch('currency')}>{t('currency')}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={field.value && currencies.includes(field.value) ? field.value : ""}
                      onChange={(e) => field.onChange(e.target.value)}
                      displayEmpty
                      disabled={readOnly}
                    >
                      {currencies.length > 0 ? (
                        currencies.map((curr) => (
                          <MenuItem key={curr} value={curr}>{curr}</MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          {t('no_currency_available')}
                        </MenuItem>
                      )}
                    </Select>
                  )}
                />
              </FormControl>}
              <Box display="flex" alignItems="center">
                <Typography variant="body2" color="textSecondary">{t('language')}</Typography>
                <Button
                  variant="outlined"
                  onClick={() => changeLanguage(language === 'en' ? 'fr' : 'en')}
                  sx={{ ml: 2 }}
                >
                  <img
                    className="language-flag"
                    src={language === 'en' ? enFlag : frFlag}
                    alt="Language Flag"
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">{t('cancel')}</Button>
          <Button color="primary" type="submit" disabled={!isValid}>{t('save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProfileEdit;
