import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import ReviewsPanelAdmin from './ReviewsPanelAdmin';
import { Container } from '@mui/material';

export default function Inbox() {
  const { user } = useContext(UserContext);

  // Determine which inbox to show based on user role
  const renderInbox = () => {
    switch (user.role) {
      case 'ADMIN':
        return <ReviewsPanelAdmin />;
      default:
        return null;
    }
  };

  return (
    <Container>
      {renderInbox()}
    </Container>
  );
}
