import React, { useState, useEffect, useContext } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  Box, Typography, IconButton, Container, CircularProgress,
  Tabs, Grid, Tab, Fab, Modal
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import {
  getAssetFull, getTransactions, createTransaction, statsAsset, updateAsset, updateTransaction,
  getAssetExpenseCategoriesChart, historyAsset, deleteTransaction, getAssetValueChart, getForecast, statsForecast
} from '../../api';
import AssetExpenseBreakdownChart from '../charts/AssetExpenseBreakdownChart';
import AssetPerfChart from '../charts/AssetPerfChart';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Stats from './Stats';
import MainCard from '../../utils/MainCard';
import Details from './Details';
import FormAssetTransaction from './FormAssetTransaction';
import FormEditAsset from './FormEditAsset';
import TransactionList from '../../utils/TransactionList'; // Adjust the path as needed
import EstimationCard from '../../utils/EstimationCard';
import PhotoUploader from '../photos';
import { useNotification } from '../../context/NotificationContext'; // Import the notification hook

// TopBar component
const TopBar = ({ readOnly, asset, onEdit, t }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', mb: 2 }}>
    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="back">
      <ArrowBackIcon />
    </IconButton>
    <Box sx={{ display: 'column', justifyContent: 'center', alignItems: 'center', mb: 0 }}>
      <Typography variant="h4" align="center">{asset?.name}</Typography>
      <Typography variant="h6" align="center">{t(asset?.category)}</Typography>
      <Typography variant="subtitle2" align="center" color={asset?.active ? 'success.main' : 'error.main'}>
        {asset?.active ? t('active') : t('sold')}
      </Typography>
    </Box>
    <Box>
      {!readOnly && (
        <IconButton edge="end" color="inherit" onClick={onEdit} aria-label="edit">
          <EditIcon />
        </IconButton>
      )}
      <IconButton
  edge="end"
  color="inherit"
  onClick={() => navigate(`/assets/${asset?.id}/photos`)}
  aria-label="photos"
>
  <PhotoLibraryIcon />
</IconButton>
    </Box>
  </Box>
  );
};

// Asset component
function Asset() {
  const { user, refreshUser } = useContext(UserContext);
  const { asset_id } = useParams();
  const { refreshAll } = useContext(DataContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification(); // Use notification hook
  const [horizontalActive, setHorizontalActive] = useState('tab1');
  const [createModal, setCreateModal] = useState(false);
  const [editTransactionModal, setEditTransactionModal] = useState(false);
  const [editTransactionData, setEditTransactionData] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [isCreatingTransaction, setIsCreatingTransaction] = useState(false);
  const editShow = () => setEditModal(!editModal);
  const navigate = useNavigate();

  // State variables for asset details
  const [asset, setAsset] = useState(null);
  const [details, setDetails] = useState(null);
  const [location, setLocation] = useState(null);
  const [estimation, setEstimation] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [forecastData, setForecastData] = useState(null);
  const [forecastStats, setForecastStats] = useState(null);

  const { isLoading: assetLoading, refetch: refetchAsset } = useQuery(
    ['assetFull', asset_id],
    () => getAssetFull(asset_id),
    {
      onSuccess: (response) => {
        const { asset, details, location, estimation } = response.data;
        setAsset(asset);
        setDetails(details);
        setLocation(location);
        setEstimation(estimation);
        setReadOnly(asset?.user_id !== user.id);
      }
    }
  );

  const { data: transactions, isLoading: transactionsLoading, refetch: refetchTransactions } = useQuery(
    ['transactions', asset_id],
    async () => {
      const response = await getTransactions(asset_id);
      return response.data;
    }
  );

  const { data: expenses, isLoading: expenseLoading } = useQuery(
    ['expenses', asset_id],
    async () => {
      const response = await getAssetExpenseCategoriesChart(asset_id);
      return response.data;
    },
    {
      enabled: asset?.type !== 'FINANCIAL_PRODUCT',
    }
  );

  const { data: performance } = useQuery(
    ['performance', asset_id],
    async () => {
      const response = await getAssetValueChart(asset_id);
      return response.data;
    },
    {
      enabled: asset?.type === 'FINANCIAL_PRODUCT',
    }
  );

  const { data: stats, isLoading: statsLoading, refetch: refetchStats } = useQuery(
    ['stats', asset_id],
    async () => {
      const response = await statsAsset(asset_id);
      return response.data;
    }
  );

  const { data: history, isLoading: historyLoading, refetch: refetchHistory } = useQuery(
    ['history', asset_id],
    async () => {
      const response = await historyAsset(asset_id);
      return response.data;
    }
  );

  const onUpdateForecastStats = (newStats, newForecastStats) => {
    if (newStats) setForecastData(newStats); // Update real stats
    if (newForecastStats) setForecastStats(newForecastStats); // Update forecast stats
  };

  const handleDelete = async (transactionId) => {
    try {
      await deleteTransaction(transactionId);
      await refetchAsset();
      await refetchTransactions();
      showNotification(t('transaction_deleted_successfully'), 'success');
      await refreshAll(user.role);
      await refreshUser();
    } catch (error) {
      showNotification(t('transaction_deletion_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    }
  };
  const handleEditTransactionSubmit = async (formData) => {
    try {
      setIsCreatingTransaction(true);
      await updateTransaction(editTransactionData.id, formData);
      await refetchTransactions();
      await refetchStats();
      await refetchAsset();
      showNotification(t('transaction_updated_successfully'), 'success');
      setEditTransactionModal(false);
      await refetchHistory();
      await refreshAll(user.role);
      await refreshUser();
    } catch (error) {
      showNotification(t('transaction_update_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsCreatingTransaction(false);
    }
  };

  const handleNewTransaction = async (formData) => {
    try {
      setIsCreatingTransaction(true);
      formData.user_id = user.id;
      formData.asset_id = asset_id;
      if (formData.category === '')
        formData.category = null;
      await createTransaction(asset_id, formData);
      await refetchTransactions();
      await refetchStats();
      showNotification(t('transaction_created_successfully'), 'success');
      setCreateModal(false);
      await refetchAsset();
      await refetchHistory();
      await refreshUser();
      await refreshAll(user.role);
      if (!asset?.active) {
        navigate(-1);
      }
    } catch (error) {
      showNotification(t('transaction_creation_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setIsCreatingTransaction(false);
    }
  };

  const handleEdit = async (transactionToEdit) => {
    console.log('Transaction to edit:', transactionToEdit);
    setEditTransactionData(transactionToEdit);
    setEditTransactionModal(true);
  };

  useEffect(() => {
    const fetchForecastData = async () => {
      try {
        const forecastResp = await getForecast(asset_id);
        setForecastData(forecastResp.data);
        const statsResp = await statsForecast(forecastResp.data);
        setForecastStats(statsResp.data);
      } catch (error) {
        console.error('Error fetching forecast data:', error);
      }
    };

    if (asset) fetchForecastData();
  }, [asset]);

  const handleEditFormSubmit = async (formData) => {
    try {
      await updateAsset(asset_id, formData);
      await refetchAsset();
      setEditModal(false);
      showNotification(t('asset_updated_successfully'), 'success');
      refreshUser();
      refreshAll(user.role);

    } catch (error) {
      showNotification('Failed to update asset: ' + (error.response?.data?.detail || error.message), 'error');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 4 }}>
      {assetLoading || transactionsLoading || isCreatingTransaction || statsLoading || historyLoading || expenseLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TopBar readOnly={readOnly} asset={asset} onEdit={() => setEditModal(true)} t={t} />

          <Box sx={{ width: '100%' }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>
                <MainCard
                  title={t('value')}
                  value={asset?.value}
                  m_change={history && asset?.active ? history.value_mtd : null}
                  y_change={history && asset?.active ? history.value_ytd : null}
                  currency={user.currency}
                  tooltip={t('tooltip_asset_value')}
                  size='h5'
                  simplify={true}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MainCard
                  title={t('net_profit')}
                  value={asset?.net_profit}
                  m_change={history && asset?.active ? history.profit_mtd : null}
                  y_change={history && asset?.active ? history.profit_ytd : null}
                  currency={user.currency}
                  tooltip={t('tooltip_asset_profit')}
                  size='h5'
                  flagColor={true}
                  simplify={true}
                />
              </Grid>
            </Grid>
          </Box>
          {estimation && EstimationCard({ estimation, location: location, details: details, currency: user.currency, t: t })}

          <Box sx={{ mb: 4, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: 1, border: '1px solid #e0e0e0', p: 1 }}>
            <Tabs value={horizontalActive} onChange={(event, newValue) => setHorizontalActive(newValue)}>
              <Tab label={t('metrics')} value="tab1" />
              <Tab label={t('details')} value="tab2" />
              {asset?.type !== 'FINANCIAL_PRODUCT' && <Tab label={t('expenses')} value="tab3" />}
              {asset?.type === 'FINANCIAL_PRODUCT' && <Tab label={t('performance')} value="tab3" />}
            </Tabs>
            {horizontalActive === 'tab1' && asset && <Stats user={user} asset={asset} stats={stats} readOnly={readOnly} />}
            {horizontalActive === 'tab2' && asset && <Details asset={asset} details={details} location={location} currency={user.currency} t={t} />}
            {horizontalActive === 'tab3' && asset?.type !== 'FINANCIAL_PRODUCT' && <AssetExpenseBreakdownChart expenses={expenses} currency={user.currency} t={t} />}
            {horizontalActive === 'tab3' && asset?.type === 'FINANCIAL_PRODUCT' && <AssetPerfChart performance={performance} assetName={asset?.name} currency={user.currency} />}
          </Box>

          <TransactionList
            title={t('transactions')}
            transactions={transactions}
            currency={user.currency}
            onEdit={handleEdit}
            onDelete={handleDelete}
            readOnly={readOnly}
          />

          {!readOnly && (
            <Fab
              color="primary"
              aria-label={t('add_transaction')}
              disabled={!asset?.active}
              onClick={() => setCreateModal(true)}
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 64,
                height: 64,
                fontSize: 32,
              }}
            >
              <AddIcon />
            </Fab>
          )}

          <Modal open={createModal} onClose={() => setCreateModal(false)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <FormAssetTransaction user={user} asset={asset} apply={handleNewTransaction} toggleShow={() => setCreateModal(false)} />
            </Box>
          </Modal>

          <Modal open={editTransactionModal} onClose={() => setEditTransactionModal(false)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <FormAssetTransaction user={user} asset={asset} apply={handleEditTransactionSubmit} toggleShow={() => setEditTransactionModal(false)} initialData={editTransactionData} />
            </Box>
          </Modal>
          <Modal open={editModal} onClose={() => setEditModal(false)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <FormEditAsset apply={handleEditFormSubmit} toggleShow={() => setEditModal(false)} asset={asset} />
            </Box>
          </Modal>

        </>
      )}
    </Container>
  );
}

export default Asset;
