import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Fab,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { DataContext } from '../../context/DataContext';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import ConciergeForm from './ConciergeForm';
import ConciergeCard from './ConciergeCard';
import { useNotification } from '../../context/NotificationContext';
import ContractForm from '../contracts/ContractForm'; // Import ContractForm
import { createConcierge, getConcierges, createContract, updateConcierge, deleteConcierge, getConcierge, getAssets, activateConcierge, deactivateConcierge } from '../../api'; // Add getAssets

export default function Concierges() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [basicModal, setBasicModal] = useState(false);
  const [contractModal, setContractModal] = useState(false); // State to control contract modal
  const [selectedConcierge, setSelectedConcierge] = useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const { data, refreshData } = useContext(DataContext);
  const [preselectedConcierge, setPreselectedConcierge] = useState(null); // State for preselected concierge
  const queryClient = useQueryClient();
  const toggleShow = () => setBasicModal(!basicModal);
  const toggleContractShow = () => setContractModal(!contractModal); // Toggle contract modal

  const assets = (data.assets || []).filter(asset => asset.active); // Filter active assets
  const concierges = data.concierges || [];

  const createContractMutation = useMutation(
    ({ asset_id, contract }) => createContract(asset_id, contract),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('contracts');
        refreshUser();
      },
    }
  );

  const handleNewContract = async (formData) => {
    try {
      setIsLoading(true);
      const { asset_id, concierge_id, ...rest } = formData;
      await createContractMutation.mutateAsync({ asset_id, contract: formData });
      showNotification(t('contract_created_successfully'), 'success');
      toggleShow();
      // Redirect to Inbox
      navigate(`/inbox`);
    } catch (error) {
      console.error('An error occurred while creating the contract:', error);
      showNotification(t('contract_creation_failed') + ': ' + error.response?.data?.detail || error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewConcierge = async (formData) => {
    try {
      setIsLoading(true);
      if (selectedConcierge) {
        await updateConcierge(selectedConcierge.id, formData);
        showNotification(t('concierge_updated_successfully'), 'success');
      } else {
        await createConcierge(formData);
        showNotification(t('concierge_created_successfully'), 'success');
      }
      refreshData('concierges', getConcierges);
      refreshUser();
      toggleShow();
    } catch (error) {
      console.error('An error occurred while creating/updating the concierge:', error);
      showNotification(t('concierge_creation_failed') + ': ' + error.response?.data?.detail || error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditConcierge = (concierge) => {
    setSelectedConcierge(concierge);
    toggleShow();
  };

  const handleActivateConcierge = async (concierge) => {
    try {
      setIsLoading(true);
      if(concierge.active) {
        await deactivateConcierge(concierge.id);
        showNotification(t('concierge_deactivated_successfully'), 'success');
      } else {
        await activateConcierge(concierge.id);
        showNotification(t('concierge_activated_successfully'), 'success');
      }
      refreshData('concierges', getConcierges);
    } catch (error) {
      console.error('An error occurred while activating the concierge:', error);
      showNotification(t('concierge_activation_failed') + ': ' + error.response?.data?.detail || error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteConcierge = async (conciergeId) => {
    try {
      setIsLoading(true);
      await deleteConcierge(conciergeId);
      showNotification(t('concierge_deleted_successfully'), 'success');
      refreshData('concierges', getConcierges);
    } catch (error) {
      console.error('An error occurred while deleting the concierge:', error);
      showNotification(t('concierge_deletion_failed') + ': ' + error.response?.data?.detail || error.message, 'error');

    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestService = (concierge) => {
    setPreselectedConcierge(concierge); // Set the concierge to be preselected
    toggleContractShow();
  };

  return (
    <Container maxWidth="md">
  <Box textAlign="center" my={3}>
    <Typography variant="h4" fontWeight="bold">
      {t('concierge_marketplace')}
    </Typography>
    <Typography variant="body1" color="textSecondary" mt={1}>
      {t('manage_assets_short_desc')}
    </Typography>
  </Box>

  {(isLoading) ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {concierges.map((concierge) => (
        <ConciergeCard
          key={concierge.id}
          concierge={concierge}
          onRequest={handleRequestService}
          onEdit={handleEditConcierge}
          onDelete={handleDeleteConcierge}
          onActivate={handleActivateConcierge}
          onChat={null}
          role={user?.role}
        />
      ))}


{user?.role === 'ADMIN' && (
        <Fab
          color="primary"
          aria-label={t('add_concierge')}
          onClick={() => {
            setSelectedConcierge(null);
            toggleShow();
          }}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            width: 64,
            height: 64,
            fontSize: 32,
          }}
        >
          <AddIcon />
        </Fab>
      )}

      {/* Dialogs for adding or editing concierges */}
      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
          {selectedConcierge ? t('edit_concierge') : t('create_concierge')}
        </DialogTitle>
        <DialogContent>
          <ConciergeForm
            user={user}
            apply={handleNewConcierge}
            toggleShow={toggleShow}
            concierge={selectedConcierge}
          />
        </DialogContent>
      </Dialog>

      {/* Contract Request Dialog */}
      <Dialog open={contractModal} onClose={toggleContractShow} fullWidth maxWidth="sm">
        <DialogTitle sx={{ backgroundColor: '#f5f5f5' }}>
          {t('contract_request')}
        </DialogTitle>
        <DialogContent>
          <ContractForm
            user={user}
            apply={handleNewContract}
            toggleShow={toggleContractShow}
            assets={assets}
            concierges={concierges}
            contracts={data.contracts}
            preselectedConcierge={preselectedConcierge}
          />
        </DialogContent>
      </Dialog>
    </>
  )}
</Container>

  );
}
