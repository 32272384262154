import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';
import {
  configCurrencies,
  configAssetTypes,
  configAssetCategories,
  configAssetExploitations,
  configTransactionTypes,
  configConciergeCategories,
  configExpenseTypes,
  configIncomeTypes,
  configDebtTypes,
  configCountries,
} from '../api';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState(() => {
    const cachedConfigs = localStorage.getItem('configs');
    return cachedConfigs ? JSON.parse(cachedConfigs) : null; // Load from localStorage if available
  });

  const [loading, setLoading] = useState(!configs); // Only load if configs are missing
  const [error, setError] = useState(null);

  /**
   * Fetch all configuration data.
   */
  const loadConfigs = useCallback(async () => {
    if (configs) {
      return;
    }

    setLoading(true);

    const configFetchers = {
      currencies: configCurrencies,
      assetTypes: configAssetTypes,
      assetCategories: configAssetCategories,
      assetExploitations: configAssetExploitations,
      transactionTypes: configTransactionTypes,
      conciergeCategories: configConciergeCategories,
      expenseTypes: configExpenseTypes,
      incomeTypes: configIncomeTypes,
      debtTypes: configDebtTypes,
      countries: configCountries,
    };

    try {
      const results = await Promise.allSettled(
        Object.entries(configFetchers).map(async ([key, fetcher]) => {
          try {
            const response = await fetcher();
            return { key, data: response.data };
          } catch (err) {
            console.error(`Failed to fetch configuration for ${key}:`, err);
            throw new Error(`Failed to fetch ${key}`);
          }
        })
      );

      const successfulResults = results.filter((r) => r.status === 'fulfilled');
      const newConfigs = successfulResults.reduce((acc, r) => {
        acc[r.value.key] = r.value.data;
        return acc;
      }, {});

      setConfigs(newConfigs);
      localStorage.setItem('configs', JSON.stringify(newConfigs)); // Cache configurations locally
      console.debug('Configurations loaded and cached:', newConfigs);
    } catch (err) {
      console.error('Error fetching configurations:', err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [configs]);

  /**
   * Clears configurations (e.g., on logout).
   */
  const resetConfigs = useCallback(() => {
    setConfigs(null);
    localStorage.removeItem('configs');
    console.debug('Configurations have been reset.');
  }, []);

  useEffect(() => {
    if (!configs) {
      loadConfigs(); // Load configurations if not already loaded
    }
  }, [configs, loadConfigs]);

  return (
    <ConfigContext.Provider value={{ configs, loadConfigs, resetConfigs, loading, error }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);
