import React from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Tooltip,
    Chip,
    Divider,
} from '@mui/material';
import {
    Home as HomeIcon,
    DirectionsCar as DirectionsCarIcon,
    AccountBalance as AccountBalanceIcon,
    Build as BuildIcon,
    CalendarToday as CalendarTodayIcon,
    Contacts as ContactsIcon,
    Message as MessageIcon,
    Link as LinkIcon,
    CropSquare as CropSquareIcon,
    MeetingRoom as MeetingRoomIcon,
    EmojiTransportation as EmojiTransportationIcon,
    PrecisionManufacturing as PrecisionManufacturingIcon,
    Public as PublicIcon,
    LocationCity as LocationCityIcon,
    LocationOn as LocationOnIcon,
    Speed as SpeedIcon,
    Info as InfoIcon,
    Person as PersonIcon,
    Business as BusinessIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const getContractActions = (role, contract) => {
    const actions = {
        canApprove: false,
        canReject: false,
        canReview: false,
        canArchive: false,
        canAccept: false,
        canRefuse: false,
        canDelete: false
    };

    switch (contract.status) {
        case 'REQUESTED':
            if (role === 'PRO') {
                actions.canRefuse = true;
                actions.canReview = true;
            }
            if (role === 'ADMIN') {
                actions.canReject = true;
            }
            if (role === 'INDIVIDUAL') {
                actions.canDelete = true;
            }
            break;

        case 'UNDER_REVIEW':
            if (role === 'PRO') {
                actions.canAccept = true;
                actions.canRefuse = true;
            } else if (role === 'ADMIN') {
                actions.canReject = true; // Allow CONCIERGE to accept contracts under review
            }
            break;

        case 'ACTIVE':
            if (role === 'ADMIN') {
                actions.canArchive = true;
            }
            break;

        case 'ARCHIVED':
        default:
            // Archived contracts don't allow further actions
            break;
    }
    return actions;
};



const ContractCard = ({ contract, userRole, onApprove, onRefuse, onReject, onReview, onAccept, onArchive, onDelete }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

    const handleNavigateToAsset = (assetId) => {
        navigate(`/assets/${assetId}/photos`);
    };

    const getAssetIcon = (assetType) => {
        switch (assetType) {
            case 'REAL_ESTATE':
                return <HomeIcon />;
            case 'AUTOMOBILE':
                return <DirectionsCarIcon />;
            case 'FINANCIAL_PRODUCT':
                return <AccountBalanceIcon />;
            case 'EQUIPMENT':
                return <BuildIcon />;
            default:
                return <BuildIcon />;
        }
    };

    const actions = getContractActions(userRole, contract);

    return (
        <Card
            sx={{
                borderRadius: 3,
                boxShadow: 3,
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
            }}
        >
            <CardContent>
                {/* Header */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6" fontWeight="bold">
                        {contract.asset_name}
                    </Typography>
                    <Tooltip title={t(contract.status)} arrow>
                        <Chip
                            label={t(contract.status)}
                            color={
                                contract.status === 'ACTIVE'
                                    ? 'success'
                                    : contract.status === 'EXPIRED'
                                        ? 'warning'
                                        : 'default'
                            }
                            size="small"
                            sx={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                backgroundColor:
                                    contract.status === 'ACTIVE' ? '#4caf50' : contract.status === 'EXPIRED' ? '#ff9800' : '#e0e0e0',
                                color: 'white',
                            }}
                        />
                    </Tooltip>
                </Box>

                <Box display="flex" flexDirection="column" gap={1} sx={{ mb: 2 }}>

                    {/* Asset Details */}
                    <Box display="flex" alignItems="center" mb={1}>
                        <Tooltip title={t('category')} arrow>
                            <IconButton color="black" sx={{ p: 0, mr: 1 }}>
                                {getAssetIcon(contract.asset_type)}
                            </IconButton>
                        </Tooltip>
                        <Typography variant="body2" color="textSecondary">
                            <strong>{t('category')}:</strong> {t(contract.asset_category)}
                        </Typography>
                    </Box>

                    {contract.details && (
                            <Box display="flex" flexDirection="column" gap={1} sx={{ mt: 2 }}>

                                {Object.entries(contract.details).map(([key, value]) => (
                                    value && (
                                        <Box display="flex" alignItems="center" key={key}>
                                            <Tooltip title={t(key)} arrow>
                                                <IconButton color="primary" sx={{ p: 0, mr: 1 }}>
                                                    {(() => {
                                                        switch (key) {
                                                            case 'surface':
                                                                return <CropSquareIcon />;
                                                            case 'rooms':
                                                                return <MeetingRoomIcon />;
                                                            case 'brand':
                                                                return <EmojiTransportationIcon />;
                                                            case 'model':
                                                                return <PrecisionManufacturingIcon />;
                                                            case 'country':
                                                                return <PublicIcon />;
                                                            case 'city':
                                                                return <LocationCityIcon />;
                                                            case 'quartier':
                                                            case 'address':
                                                                return <LocationOnIcon />;
                                                            case 'release_year':
                                                                return <CalendarTodayIcon />;
                                                            case 'mileage':
                                                                return <SpeedIcon />;
                                                            default:
                                                                return <InfoIcon />;
                                                        }
                                                    })()}
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>{t(key)}:</strong> {key === 'purchase_date' ? formatDate(value) : value}
                                            </Typography>
                                        </Box>
                                    )
                                ))}
                            </Box>
                        )}
                        <Typography variant="body2" color="textSecondary">
                            <IconButton color="primary" sx={{ p: 0, mr: 1 }}><CalendarTodayIcon /></IconButton><strong>{t('purchase_date')}:</strong> {formatDate(contract.purchase_date)}
                        </Typography>
                </Box>

            <Divider sx={{ mb: 2 }} />

            {/* Owner and Concierge Details */}
            <Box display="flex" alignItems="center" mb={1}>
                <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('owner')}:</strong> {contract.user_name}
                </Typography>
            </Box>
            {
                      contract.status === 'UNDER_REVIEW' && (

                        <Box display="flex" alignItems="center" mb={1}>
                          <ContactsIcon sx={{ mr: 1, color: 'black' }} />
                          <Typography variant="subtitle2" color="textSecondary">
                            <strong>{t('contact')}:</strong> {contract.user_contact}
                          </Typography>

                        </Box>
                      )
                    }
            <Box display="flex" alignItems="center" mb={1}>
                <BusinessIcon sx={{ mr: 1, color: 'secondary.main' }} />
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('concierge')}:</strong> {contract.concierge_name}
                </Typography>
            </Box>

            <Divider sx={{ mb: 2 }} />

            {/* Contract Duration */}
            <Box display="flex" alignItems="center" mb={1}>
                <CalendarTodayIcon sx={{ mr: 1, color: 'success.main' }} />
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('contract_duration')}:</strong>{' '}
                    {`${formatDate(contract.created_at)} - ${formatDate(contract.expiry_date)}`}
                </Typography>
            </Box>

            {/* Request Message */}
            {contract.message && (
                <Box display="flex" alignItems="flex-start" mb={2}>
                    <MessageIcon sx={{ mr: 1, color: 'warning.main' }} />
                    <Typography variant="subtitle2" color="textSecondary">
                        <strong>{t('message')}:</strong> {contract.message}
                    </Typography>
                </Box>
            )}

            {/* Asset Navigation */}
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" color="primary" sx={{ fontWeight: 'bold' }}>
                    {t('asset_details')}
                </Typography>
                <Tooltip title={t('view_asset_photos')} arrow>
                    <IconButton
                        onClick={() => handleNavigateToAsset(contract.asset_id)}
                        color="primary"
                    >
                        <LinkIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </CardContent>

        {/* Actions */ }
    <CardActions sx={{ justifyContent: 'flex-end', mt: 1 }}>
        {actions.canReject && (
            <Button variant="contained" color="error" onClick={() => onReject(contract)} sx={{ mr: 1 }}>
                {t('reject')}
            </Button>
        )}
        {actions.canRefuse && (
            <Button variant="contained" color="error" onClick={() => onRefuse(contract)} sx={{ mr: 1 }}>
                {t('refuse')}
            </Button>
        )}
        {actions.canDelete && (
            <Button variant="contained" color="error" onClick={() => onDelete(contract.id)} sx={{ mr: 1 }}>
                {t('delete')}
            </Button>
        )}
        {actions.canReview && (
            <Button variant="contained" color="primary" onClick={() => onReview(contract.id)} sx={{ mr: 1 }}>
                {t('review_contract')}
            </Button>
        )}
        {actions.canApprove && (
            <Button variant="contained" color="primary" onClick={() => onApprove(contract.id)} sx={{ mr: 1 }}>
                {t('approve')}
            </Button>
        )}
        {actions.canAccept && (
            <Button variant="contained" color="success" onClick={() => onAccept(contract.id)} sx={{ mr: 1 }}>
                {t('accept')}
            </Button>
        )}
        {actions.canArchive && (
            <Button variant="contained" color="secondary" onClick={() => onArchive(contract.id)} sx={{ mr: 1 }}>
                {t('archive')}
            </Button>
        )}
    </CardActions>
      </Card >
    );
  };

export default ContractCard;
