import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSignIn } from 'react-supabase';
import { useTranslation } from 'react-i18next';
import { Avatar, IconButton, InputAdornment, CircularProgress, Container, Box, Card, CardContent, TextField, Button, Typography, Link, Dialog, DialogContent, DialogActions, Divider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginUser } from '../../api';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { useNotification } from '../../context/NotificationContext';
import ProfileEdit from '../profile/components/FormProfile';
import Forgot from './Forgot';
import logo from '../../logo192.png'; // Adjust path if necessary

const Login = () => {
  const { saveUser } = useContext(UserContext);
  const { refreshAll } = useContext(DataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ session }, signIn] = useSignIn();
  const { showNotification } = useNotification();
  const [basicModal, setBasicModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const toggleShow = () => setBasicModal(!basicModal);
  const toggleForgot = () => setForgotModal(!forgotModal);

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('invalidEmail')).required(t('emailRequired')),
    password: yup.string().min(5, t('passwordTooShort')).required(t('passwordRequired'))
  });

  const { control, handleSubmit, setError, formState } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
      const { error, session } = await signIn({ email: data.email, password: data.password });
      if (error) throw error;
      const user_id = session.user.id;
      const token = session.access_token;
      try {
        const loginResponse = await loginUser({ user_id }, token);
        if (loginResponse.error) throw loginResponse.error;

        localStorage.clear();
        localStorage.setItem('token', token);
        saveUser(loginResponse.data);


        // Start loading configurations and data
        setIsWaiting(true);
        refreshAll(loginResponse.data?.role);

        // Add a small wait (e.g., 500ms) before navigating
        setTimeout(() => {
          showNotification(t('login_successful'), 'success');
          setIsWaiting(false);
          navigate('/dashboard');
        }, 1000); //Delay to let grab the data

      } catch (err) {
        setIsWaiting(false);
        toggleShow();
      }
    } catch (err) {
      setIsWaiting(false);
      setError("apiError", { message: err.message || t('apiError', { error: err.message }) });
      showNotification(t('login_failed'), 'error');
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleContactClick = () => window.open('https://wa.me/message/ZTZUNNZHD4JRM1', '_blank');

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar src={logo} alt="logo" sx={{ width: 64, height: 64, mb: 2 }} />

        <Card variant="outlined" sx={{ width: '100%', padding: '20px', boxShadow: 2 }}>
          <CardContent>
            {errors.apiError && (
              <Typography color="error" align="center" sx={{ mb: 1 }}>
                {errors.apiError.message}
              </Typography>
            )}
            {isWaiting  &&
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            }
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('email')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    autoComplete="username"
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('password')}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 2, mb: 1 }}
                disabled={isSubmitting}
                type="submit"
              >
                {t('login')}
              </Button>
              <Typography align="center" >
                <Link onClick={toggleForgot} sx={{ cursor: 'pointer', textTransform: 'none', fontSize: '0.875rem', color: 'primary.main' }}>
                  {t('forgotPassword')}
                </Link>
              </Typography>
            </form>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              {t('no_account')} <Link onClick={() => navigate('/signup')} sx={{ cursor: 'pointer', color: 'primary.main' }}>{t('signup')}</Link>
            </Typography>
          </CardContent>
        </Card>

        <Button variant="text" color="primary" sx={{ textTransform: 'none', mt: 2 }} onClick={handleContactClick}>
          {t('contact_us')}
        </Button>
      </Box>

      <Dialog open={basicModal} onClose={toggleShow} fullWidth maxWidth="sm">
        <ProfileEdit
          user={{ id: session?.user?.id, name: '', surname: '', currency: 'EUR', email: session?.user?.email, language: 'fr' }}
          saveUser={saveUser}
          open={basicModal}
          onClose={toggleShow}
          onSuccess={() => navigate('/dashboard')}
        />
      </Dialog>

      <Dialog open={forgotModal} onClose={toggleForgot} fullWidth maxWidth="sm">
        <DialogContent>
          <Forgot toggleShow={toggleForgot} />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleForgot} color="secondary">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
