import React, { useState, useContext } from 'react';
import { Box, Grid } from '@mui/material';
import ConfirmDialog from '../../utils/ConfirmDialog';
import ContractCard from './ContractCard'; // Component for rendering individual contract cards
import { useTranslation } from 'react-i18next';
import useCustomMutation from '../../utils/hooks';
import {
  approveContract,
  rejectContract,
  reviewContract,
  archiveContract,
  acceptContract,
  deleteContract,
  refuseContract,
  getContracts
} from '../../api';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';

const ContractList = ({ contracts = [] }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { refreshData } = useContext(DataContext);
  const [dialogState, setDialogState] = useState({
    reject: { open: false, contract: null },
    refuse: { open: false, contract: null },
  });

  // Callbacks for dialogs
  const openRejectDialog = (contract) => {
    setDialogState({ ...dialogState, reject: { open: true, contract } });
  };

  const closeRejectDialog = () => {
    setDialogState({ ...dialogState, reject: { open: false, contract: null } });
  };
  const openRefuseDialog = (contract) => {
    console.log("Opening refuse dialog for contract:", contract);
    setDialogState({ ...dialogState, refuse: { open: true, contract } });
  };
  const closeRefuseDialog = () => {
    setDialogState({ ...dialogState, refuse: { open: false, contract: null } });
  };


  const handleRejectContract = (note) => {
    const { contract } = dialogState.reject;
    rejectContractMutation.mutate( { id: contract.id, note: note } );
    closeRejectDialog();
  };
  const handleRefuseContract = (note) => {
    const { contract } = dialogState.refuse;
    console.log("Refusing contract with ID:", contract.id, "Reason:", note);
    refuseContractMutation.mutate( { id: contract.id, note: note } );
    closeRefuseDialog();
  };

  // Mutations for contract actions
  const refreshContracts = () => {
    refreshData('contracts', getContracts);
    // Replace with actual refresh logic if needed
  };

  const approveContractMutation = useCustomMutation(
    (id) => approveContract(id),
    {
      successMessage: t('contract_approved_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  const acceptContractMutation = useCustomMutation(
    (id) => acceptContract(id),
    {
      successMessage: t('contract_accepted_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  const rejectContractMutation = useCustomMutation(
    ({ id, note }) => rejectContract(id, note),
    {
      successMessage: t('contract_rejected_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );
  const refuseContractMutation = useCustomMutation(
    ({ id, note }) => refuseContract(id, note),
    {
      successMessage: t('contract_refused_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  const deleteContractMutation = useCustomMutation(
    (id) => deleteContract(id),
    {
      successMessage: t('contract_deleted_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  const reviewContractMutation = useCustomMutation(
    (id) => reviewContract(id),
    {
      successMessage: t('contract_reviewed_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  const archiveContractMutation = useCustomMutation(
    (id) => archiveContract(id),
    {
      successMessage: t('contract_archived_successfully'),
      errorMessage: t('contract_operation_failed'),
      refreshData: refreshContracts,
    }
  );

  return (
    <Box my={3}>
      {contracts.length > 0 ? (
        <Grid container spacing={2}>
          {contracts.map((contract) => (
            <Grid item xs={12} md={6} key={contract.id}>
            <ContractCard
              key={contract.id}
              contract={contract}
              userRole={user.role}
              onApprove={approveContractMutation.mutate}
              onReject={openRejectDialog}
              onRefuse={openRefuseDialog}
              onReview={reviewContractMutation.mutate}
              onArchive={archiveContractMutation.mutate}
              onAccept={acceptContractMutation.mutate}
              onDelete={deleteContractMutation.mutate}
            />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box>{t('no_contracts')}</Box>
      )}

      {/* Reject Confirmation Dialog */}
      <ConfirmDialog
        open={dialogState.reject.open}
        onClose={closeRejectDialog}
        onConfirm={handleRejectContract}
        title={t('reject_contract')}
        message={t('provide_rejection_reason')}
        confirmLabel={t('reject')}
        confirmButtonColor="error"
        requireReason // Enable reason input
      />
      {/* Reject Confirmation Dialog */}
      <ConfirmDialog
        open={dialogState.refuse.open}
        onClose={closeRefuseDialog}
        onConfirm={handleRefuseContract}
        title={t('refuse_contract')}
        message={t('provide_rejection_reason')}
        confirmLabel={t('refuse')}
        confirmButtonColor="error"
        requireReason // Enable reason input
      />
    </Box>
  );
};

export default ContractList;
