import React, { useContext, useState } from 'react';
import { Container, Box, Typography, Tabs, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQueryClient, useMutation } from 'react-query';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import { useNotification } from '../../context/NotificationContext';
import InboxIllustration from '../../resources/empty-inbox.svg';
import PendingTransactionList from '../pendingTransaction/PendingTransactionList';
import ContractList from '../../components/contracts/ContractList';
import PendingReviewList from '../../components/reviews/PendingReviewList';
import { getContracts, getRequestedReviews, approveContract, rejectContract } from '../../api';
import { AttachMoney, RateReview, Description } from '@mui/icons-material';

export default function InboxAdmin() {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { data, refreshData } = useContext(DataContext);
  const { showNotification } = useNotification();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState(0);

  const contracts = data.contracts.filter((contract) => contract.is_pending === true);
  const reviews = data.requestedReviews || [];
  const transactions = data.pendingTransactions || [];

  const tabs = [
    {
      label: t('pending_transactions'),
      icon: <AttachMoney />,
      content: 'transactions',
      description: t('pending_transactions_description', 'Review pending transactions and take action as needed.'),
      show: transactions.length > 0,
    },
    {
      label: t('pending_reviews'),
      icon: <RateReview />,
      content: 'reviews',
      description: t('pending_reviews_description', 'Provide feedback on pending reviews to keep things moving.'),
      show: reviews.length > 0,
    },
    {
      label: t('accepted_contracts'),
      icon: <Description />,
      content: 'contracts',
      description: t('pending_contracts_description', 'Review and approve accepted contracts.'),
      show: contracts.length > 0,
    },
  ].filter((tab) => tab.show);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const approveContractMutation = useMutation((contractId) => approveContract({ request_id: contractId }), {
    onSuccess: () => {
      showNotification(t('contract_approved_successfully'), 'success');
      queryClient.invalidateQueries('contracts');
      refreshData('contracts', getContracts);
    },
    onError: (error) => {
      showNotification(t('contract_approval_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const rejectContractMutation = useMutation((contractId) => rejectContract(contractId), {
    onSuccess: () => {
      showNotification(t('contract_rejected_successfully'), 'success');
      queryClient.invalidateQueries('contracts');
      refreshData('contracts', getContracts);
    },
    onError: (error) => {
      showNotification(t('contract_rejection_failed') + ': ' + (error.response?.data?.detail || error.message), 'error');
    },
  });

  const handleApproveContract = (contractId) => {
    approveContractMutation.mutate(contractId);
  };

  const handleRejectContract = (contractId) => {
    rejectContractMutation.mutate(contractId);
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" my={3}>
        <Typography variant="h4">{t('inbox')}</Typography>
      </Box>

      {tabs.length > 0 ? (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start" />
            ))}
          </Tabs>

          <Box my={3}>
            <Typography variant="body1" color="textSecondary" sx={{ mb: 2, textAlign: 'center' }}>
              {tabs[activeTab]?.description}
            </Typography>

            {tabs[activeTab]?.content === 'transactions' && (
              <PendingTransactionList/>
            )}

            {tabs[activeTab]?.content === 'reviews' && (
              <PendingReviewList
                reviews={reviews}
                refresh={() => refreshData('requestedReviews', getRequestedReviews)}
                role={user.role}
              />
            )}

            {tabs[activeTab]?.content === 'contracts' && (
              <ContractList
                contracts={contracts}
                expandable={false}
                showLifecycleFlow={true}
                onApprove={handleApproveContract}
                onReject={handleRejectContract}
              />
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" my={3}>
          <Box sx={{ width: '60%', maxWidth: '150px', mb: 2 }}>
            <img src={InboxIllustration} alt="No Actions Required" style={{ animation: 'bounce 2s infinite' }} />
          </Box>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {t('no_actions_required')}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            {t('you_are_all_caught_up')}
          </Typography>
        </Box>
      )}
    </Container>
  );
}
