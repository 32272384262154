import React, { useState, useEffect } from 'react';
import { Box, Typography, InputAdornment, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SwipeableViews from 'react-swipeable-views';
import { currencySymbol } from '../../utils';
import CheckIcon from '@mui/icons-material/Check';
import Metrics from './Metrics';
import { getForecast, updateForecast, statsForecast } from '../../api';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ForecastForm = ({ forecast, user, t, handleUpdateForecast }) => {
  const [cashflow, setCashflow] = useState('');
  const [appreciation, setAppreciation] = useState('');
  const [period, setPeriod] = useState('');
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    if (forecast) {
      setCashflow(forecast.cashflow || '');
      setAppreciation(forecast.appreciation || '');
      setPeriod(forecast.period || '');
    }
  }, [forecast]);

  const handleSubmit = async () => {
    await handleUpdateForecast(cashflow, appreciation, period);
    setFormVisible(false);
  };

  const toggleFormVisibility = () => {
    setFormVisible((prev) => !prev);
  };

  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={toggleFormVisibility} sx={{ mt: 2 }}>
        {formVisible ? t('hide_projections') : t('edit_projections')}
      </Button>

      {formVisible && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            mb: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
            p: 2,
            borderRadius: 1,
          }}
        >
          <TextField
            label={t('cashflow')}
            variant="outlined"
            type="number"
            value={cashflow || '0'}
            onChange={(e) => setCashflow(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('appreciation')}
            variant="outlined"
            type="number"
            value={appreciation}
            onChange={(e) => setAppreciation(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label={t('exploitationPeriod')}
            variant="outlined"
            type="number"
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('year')}(s)</InputAdornment>,
            }}
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="success" onClick={handleSubmit} startIcon={<CheckIcon />}>
            {t('ok')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

const Stats = ({ user, asset, stats, readOnly }) => {
  const { t } = useTranslation();
  const [forecast, setForecast] = useState(null);
  const [forecastStats, setForecastStats] = useState(null);
  const [statsLoaded, setStatsLoaded] = useState(false); // Prevent redundant fetches
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (!statsLoaded) {
      const fetchStats = async () => {
        try {
          const forecastResp = await getForecast(asset.id);
          setForecast(forecastResp.data);

          const statsResp = await statsForecast(forecastResp.data);
          setForecastStats(statsResp.data);

          setStatsLoaded(true);
        } catch (error) {
          console.error('Error fetching stats:', error);
        }
      };
      fetchStats();
    }
  }, [asset.id, statsLoaded]);

  const handleUpdateForecast = async (cashflow, appreciation, period) => {
    try {
      const updatedForecastData = {
        id: forecast?.id,
        asset_id: asset?.id,
        cashflow: parseFloat(cashflow),
        appreciation: parseFloat(appreciation),
        period: parseInt(period),
      };

      const updatedForecast = await updateForecast(updatedForecastData);
      setForecast(updatedForecast.data); // Update local forecast

      const updatedStats = await statsForecast(updatedForecast.data);
      setForecastStats(updatedStats.data); // Update local stats
    } catch (error) {
      console.error('Error updating forecast:', error);
    }
  };

  const sections = [
    {
      title: t('metrics_real_cumulative'),
      tooltip: t('tooltip_cumulative_metrics'),
      content:
        <Metrics stats={stats} currency={user.currency} cumulative />

    },
    {
      title: t('metrics_real_annual'),
      tooltip: t('tooltip_annualised_metrics'),
      content: <Metrics stats={stats} currency={user.currency} />,
    },
    {
      title: t('metrics_simulation_title'),
      tooltip: t('tooltip_simu'),
      content: (
        <>
          {forecastStats && <Metrics stats={forecastStats} currency={user.currency} />}
          {!readOnly && (
            <ForecastForm
              forecast={forecast}
              user={user}
              t={t}
              handleUpdateForecast={handleUpdateForecast}
            />
          )}
        </>
      ),
    },
  ];

  const handleSwipeChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        {activeIndex > 0 && (
          <ArrowBackIosNewIcon
            sx={{ position: 'absolute', top: '50%', left: 0, cursor: 'pointer', zIndex: 1 }}
            onClick={() => setActiveIndex((prev) => Math.max(prev - 1, 0))}
          />
        )}

        <SwipeableViews index={activeIndex} onChangeIndex={handleSwipeChange}>
          {sections.map((section, index) => (
            <Box
              key={index}
              sx={{
                p: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.03)',
                border: '1px solid #e0e0e0',
                borderRadius: 1,
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                  border: '1px solid #e0e0e0',
                  p: 1,
                }}
              >
                {section.title}
              </Typography>
              {section.content}
            </Box>
          ))}
        </SwipeableViews>

        {activeIndex < sections.length - 1 && (
          <ArrowForwardIosIcon
            sx={{ position: 'absolute', top: '50%', right: 0, cursor: 'pointer', zIndex: 1 }}
            onClick={() => setActiveIndex((prev) => Math.min(prev + 1, sections.length - 1))}
          />
        )}

        {/* Three Dots Navigation */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
          {sections.map((_, index) => (
            <Box
              key={index}
              onClick={() => setActiveIndex(index)}
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: activeIndex === index ? 'primary.main' : 'grey.400',
                mx: 1,
                cursor: 'pointer',
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Stats;
