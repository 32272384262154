import { useEffect, useState } from 'react';
import { configBrands } from '../../../../api';

let brandsCache = null; // Cache for car brands

export const useBrands = () => {
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(!brandsCache);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBrands = async () => {
      if (brandsCache) {
        console.log('Using cached brands:', brandsCache); // Debugging
        setBrands(brandsCache);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError(null);
      try {
        const response = await configBrands();
        brandsCache = response.data; // Cache the response
        console.log('Brands cache updated:', brandsCache); // Debugging
        setBrands(response.data);
      } catch (err) {
        setError(err.message || 'Failed to fetch brands');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBrands();
  }, []);

  return { brands, isLoading, error };
};
