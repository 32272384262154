import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Chip,
  Divider,
  Button,
  Avatar,
  CardMedia,
  CardActions,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person'; // Import an icon for default avatar
import VerifiedIcon from '@mui/icons-material/Verified';
import StarIcon from '@mui/icons-material/Star';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import vehicleImage from '../../resources/vehicule.jpeg';
import { getConciergeReviews } from '../../api';

const ConciergeCard = ({ concierge, onRequest, onEdit, onDelete, role }) => {
  const { t } = useTranslation();
  const [reviewsOpen, setReviewsOpen] = useState(false);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const shortFormat = concierge.currency === 'XOF';

  const handleOpenReviews = async () => {
    setReviewsOpen(true);
    if (reviews.length === 0) {
      setLoadingReviews(true);
      try {
        const response = await getConciergeReviews(concierge.id);
        setReviews(response.data);
      } catch (error) {
        console.error('Failed to fetch reviews:', error);
      } finally {
        setLoadingReviews(false);
      }
    }
  };

  const handleCloseReviews = () => setReviewsOpen(false);

  return (
    <Card
      sx={{
        borderRadius: 3,
        boxShadow: 3,
        maxWidth: 420,
        mx: 'auto',
        my: 2,
        overflow: 'hidden',
      }}
    >
      {/* Header Section */}
      <Box sx={{ bgcolor: 'lightblue', color: 'white', py: 2, textAlign: 'center' }}>
        <CardMedia
          component="img"
          src={concierge.logoUrl || vehicleImage}
          alt={concierge.name}
          sx={{
            width: 100,
            height: 100,
            borderRadius: '50%',
            border: '3px solid white',
            mx: 'auto',
          }}
        />
        <Typography variant="h5" fontWeight="bold" sx={{ mt: 1 }}>
          {concierge.name}
        </Typography>
        <Typography variant="body2" color="black">
          {concierge.city}, {concierge.country}
        </Typography>
        <Chip
          label={t(concierge.category)}
          variant="filled"
          sx={{
            mt: 1,
            bgcolor: 'white',
            color: 'black',
            fontWeight: 'bold',
            textTransform: 'capitalize',
          }}
        /><br />
        <Chip
          label={`${t('management_fees')} ${(concierge.fees).toFixed(2)}%`}
          size="small"
          sx={{
            mt: 1,
            color: 'black',
            fontWeight: 'bold',
            bgcolor: '#FFCCCC', // Light red color for the chip background
          }}
        />
        {concierge.code && role === 'ADMIN' &&
        <Chip
        label={`${t('code')} ${(concierge.code)}`}
        size="small"
        sx={{
          mt: 1,
          color: 'black',
          fontWeight: 'bold',
          bgcolor: 'lightgrey', // Light red color for the chip background
        }}
      />
        }



      </Box>

      {/* Highlights Section */}
      <Box sx={{ py: 2, px: 2, bgcolor: '#f9f9f9' }}>
        <Grid container spacing={2} alignItems="center">
          {/* Reviews */}
          <Grid item xs={6}>
            <Tooltip
              title={`${t('Average Rating')}: ${concierge.reviews_count
                  ? (concierge.rating_count / concierge.reviews_count).toFixed(1)
                  : 'N/A'
                }`}
              arrow
            >
              <Box textAlign="center" onClick={handleOpenReviews} sx={{ cursor: 'pointer' }}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography variant="h6" fontWeight="bold" sx={{ mr: 0.5 }}>
                    {concierge.reviews_count
                      ? (concierge.rating_count / concierge.reviews_count).toFixed(1)
                      : 'N/A'}
                  </Typography>
                  <StarIcon sx={{ color: '#FFC107' }} />
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    cursor: concierge.reviews_count >= 1 ? 'pointer' : 'default',
                    textDecoration: concierge.reviews_count >= 1 ? 'underline' : 'none',
                    textUnderlineOffset: '2px',
                  }}
                  onClick={() => {
                    if (concierge.reviews_count > 1) handleOpenReviews();
                  }}
                >
                  {concierge.reviews_count === 0
                    ? t('no_reviews') // e.g., "No Reviews"
                    : concierge.reviews_count === 1
                      ? `${concierge.reviews_count} ${t('review')}` // Singular: "1 Review"
                      : `${concierge.reviews_count} ${t('reviews')}`}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>

          {/* ROI */}
          <Grid item xs={6}>
            <Tooltip title={t('average_roi')} arrow>
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: concierge.roi >= 0 ? 'green' : 'red' }}
                >
                  {concierge.roi >= 0
                    ? `${(concierge.roi * 100).toFixed(2)}%`
                    : `${(concierge.roi * 100).toFixed(2)}%`}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('ROI')}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>

          {/* Average Asset Price */}
          <Grid item xs={6}>
            <Tooltip title={t('average_asset_price')} arrow>
              <Box textAlign="center">
                <Typography variant="h6" fontWeight="bold">
                  {formatAmount(concierge.asset_price, concierge.currency, shortFormat)}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('average_asset_price')}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>

          {/* Occupancy */}
          <Grid item xs={6}>
            <Tooltip title={t('average_occupancy_rate')} arrow>
              <Box textAlign="center">
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{
                    color: concierge.occupancy_rate == null
                      ? 'black'
                      : concierge.occupancy_rate >= 0.50
                        ? 'green'
                        : 'red',
                  }}
                >
                  {concierge.occupancy_rate ? `${(concierge.occupancy_rate * 100).toFixed(2)}%` : 'N/A'}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {t('average_occupancy_rate')}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {/* Service Details Section */}
      <CardContent>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          {concierge.description}
        </Typography>
      </CardContent>

      {/* Action Buttons */}
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          bgcolor: '#f9f9f9',
        }}
      >
        {role === 'INDIVIDUAL' && (
          <>
            <Button
              variant="outlined"
              size="large"
              startIcon={<ChatIcon />}
              color="secondary"
              disabled
            >
              {t('chat')}
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<VerifiedIcon />}
              onClick={() => onRequest(concierge)}
              sx={{
                bgcolor: 'primary.main',
                ':hover': { bgcolor: 'primary.dark' },
              }}
            >
              {t('send_request')}
            </Button>
          </>
        )}
        {role === 'ADMIN' && (
          <>
            <Button
              variant="contained"
              size="large"
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => onEdit(concierge)}
            >
              {t('Edit')}
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => onDelete(concierge.id)}
            >
              {t('Delete')}
            </Button>
          </>
        )}
      </CardActions>

      {/* Reviews Modal */}
      <Dialog open={reviewsOpen} onClose={handleCloseReviews} fullWidth maxWidth="sm">
        <DialogTitle>{t('all_reviews')}</DialogTitle>
        <DialogContent>
          {loadingReviews ? (
            <CircularProgress />
          ) : reviews.length > 0 ? (
            reviews.map((review) => (
              <Box
                key={review.id}
                sx={{
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  p: 2,
                  mb: 2,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                {/* User Name and Date */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        mr: 1,
                        bgcolor: 'lightblue', // Background color
                        color: 'white',
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                    <Typography vaiant="body1" fontWeight="bold">
                      {review.user_name || t('anonymous')}
                    </Typography>
                  </Box>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(review.created_at).toLocaleDateString()}
                  </Typography>
                </Box>

                {/* Headline */}
                <Typography variant="h6" sx={{ mt: 1 }}>
                  {review.headline}
                </Typography>

                {/* Rating */}
                <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
                  <Box display="flex" alignItems="center">
                    {[...Array(review.rating)].map((_, index) => (
                      <StarIcon key={index} sx={{ color: '#FFC107', fontSize: 16 }} />
                    ))}
                    {[...Array(5 - review.rating)].map((_, index) => (
                      <StarIcon key={index} sx={{ color: '#E0E0E0', fontSize: 16 }} />
                    ))}
                  </Box>
                </Box>

                {/* Comment */}
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  {review.comment || t('no_comment')}
                </Typography>

                {/* Asset Category */}
                <Typography variant="body2" color="textPrimary" sx={{ mt: 2 }}>
                  {t('asset_category')}: {t(review.asset_category || 'unknown')}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              {t('no_reviews_available')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReviews} color="primary">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

    </Card>
  );
};

export default ConciergeCard;
