import React, { createContext, useState, useContext, useCallback } from 'react';
import roleApiMapping from '../utils/roleApiMapping';
import { UserContext } from './UserContext';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const [error, setError] = useState({});

  const refreshData = useCallback(async (key, apiFunc) => {
    setLoading((prev) => ({ ...prev, [key]: true }));
    try {
      const response = await apiFunc();
      setData((prev) => {
        const updatedData = { ...prev, [key]: response.data };
        localStorage.setItem('data', JSON.stringify(updatedData));
        return updatedData;
      });
    } catch (err) {
      setError((prev) => ({ ...prev, [key]: err.message || 'Failed to refresh data' }));
      console.error(`Failed to refresh ${key}:`, err);
    } finally {
      setLoading((prev) => ({ ...prev, [key]: false }));
    }
  }, []);

  const refreshAll = useCallback(
    async (role) => {
      if (!role) {
        console.warn('No role provided to refreshAll.');
        return;
      }

      const userConfig = roleApiMapping[role] || {};
      const entries = Object.entries(userConfig);

      const results = await Promise.allSettled(
        entries.map(([key, apiFunc]) => refreshData(key, apiFunc))
      );

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          console.error(`Error refreshing ${entries[index][0]}:`, result.reason);
        }
      });
    },
    [refreshData]
  );

  const resetData = useCallback(() => {
    setData({});
    setLoading({});
    setError({});
    localStorage.removeItem('data');
    console.debug('Data has been reset.');
  }, []);

  React.useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('data')) || {};
    setData(storedData);
    console.debug('Data initialized from localStorage.');

    // Automatically refresh all data if the user role exists
    if (user?.role) {
      refreshAll(user.role);
    }
  }, [user?.role, refreshAll]);

  return (
    <DataContext.Provider
      value={{
        data,
        refreshData,
        refreshAll,
        resetData,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
