import React, { useContext, useState } from 'react';
import {
  Box,
  Container,
  Fab,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MainCard from '../../utils/MainCard';
import {createDebt } from '../../api';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import DebtCard from './DebtCard';
import FormDebt from './FormDebt';
import { useNotification } from '../../context/NotificationContext';

function Debts() {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const [isAddingDebt, setIsAddingDebt] = useState(false);
  const [showActiveDebts, setShowActiveDebts] = useState(true); // Toggle state for filtering debts
  const { showNotification } = useNotification();
  const { data, refreshAll } = useContext(DataContext);

  const debts = data.debts || [];
  const filteredDebts = Array.isArray(debts)
  ? debts.filter(debt => (showActiveDebts ? debt.active : true))
  : [];

  const handleAddDebt = () => {
    setIsAddingDebt(true);
  };

  const handleNewDebt = async (formData) => {
    try {
      formData.user_id = user.id;
      await createDebt(formData);

      refreshUser();
      await refreshAll(user.role);

      showNotification(t('loan_operation_successful'));
      setIsAddingDebt(false);
    } catch (error) {
      console.error('An error occurred while adding loan:', error);
      showNotification('Loan operation failed: ' + (error.response?.data?.detail || ''));
    }
  };

  const handleToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setShowActiveDebts(newValue === 'active');
    }
  };



  return (
    <Container maxWidth="lg">
          {debts.length !== 0 && (
            <Box display="flex" justifyContent="center" my={0} sx={{ mb: 3 }}>
              <MainCard
                title={t('running_debts')}
                value={user.debts_value}
                currency={user.currency}
                size="h4"
              />
            </Box>
          )}

          {/* Toggle Button Group */}
          {filteredDebts.length !== debts.length && (
            <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
              <ToggleButtonGroup
                value={showActiveDebts ? 'active' : 'all'}
                exclusive
                onChange={handleToggleChange}
                aria-label="debt filter"
              >
                <ToggleButton value="active" aria-label="active debts">
                  {t('show_active_debts')}
                </ToggleButton>
                <ToggleButton value="all" aria-label="all debts">
                  {t('show_all_debts')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}

          {filteredDebts.length === 0 ? (
            <Box textAlign="center" mt={5}>
              <Typography variant="h6" gutterBottom>
                {t('no_loans')}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                {t('no_loan_quote')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddDebt}
                sx={{ mt: 3 }}
              >
                {t('add_your_first_loan')}
              </Button>
            </Box>
          ) : (
            filteredDebts.map((debt) => (
              <DebtCard key={debt.id} debt={debt} />
            ))
          )}

          {filteredDebts.length !== 0 && (
            <Fab
              color="primary"
              aria-label={t('add_debt')}
              onClick={handleAddDebt}
              sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
                width: 64,
                height: 64,
                fontSize: 32,
              }}
            >
              <AddIcon />
            </Fab>
          )}

          {/* Add Debt Form */}
          <FormDebt
            user={user}
            apply={handleNewDebt}
            open={isAddingDebt}
            onClose={() => setIsAddingDebt(false)}
          />


    </Container>
  );
}

export default Debts;
