import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MetricCard from '../../utils/MetricCard';

const Metrics = ({ stats, currency, cumulative = false }) => {
  const { t } = useTranslation();

  // Determine the data to display based on cumulative flag
  const metricsData = cumulative
    ? [

        stats?.is_financial
        ? {
            title: t('gains'),
            value: stats?.gains,
            tooltip: t('tooltip_gains'),
            size: 'h7',
            currency,
            simplify: true,
          }
        : {
            title: t('occupancy_rate'),
            value: stats?.occupancy_rate,
            tooltip: t('tooltip_occupancy_rate'),
            size: 'h7',
            suffix: '%', // Add a suffix if occupancy is a percentage
          },
          {
            title: t('roi'),
            value: stats?.cumulative_roi,
            tooltip: t('tooltip_roi'),
            size: 'h7',
          },
          {
            title: t('cashflow'),
            value: stats?.cumulative_cashflow,
            tooltip: t('tooltip_cashflow'),
            size: 'h7',
            currency,
            simplify: true,
          },
      ]
    : [
      {
        title: t('irr'),
        value: stats?.irr,
        tooltip: t('tooltip_irr'),
        size: 'h7',
      },
        {
          title: t('roi'),
          value: stats?.roi,
          tooltip: t('tooltip_roi'),
          size: 'h7',
        },
        {
          title: t('cashflow'),
          value: stats?.cashflow,
          tooltip: t('tooltip_cashflow'),
          size: 'h7',
          currency,
          simplify: true,
        },
      ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        p: 0.3,
      }}
    >
      {metricsData.map((metric, index) => (
        <MetricCard
          key={index}
          title={metric.title}
          value={metric.value}
          tooltip={metric.tooltip}
          size={metric.size}
          currency={metric.currency}
          t={t}
          simplify={metric.simplify}
        />
      ))}
    </Box>
  );
};

export default Metrics;
