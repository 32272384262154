import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import StoreIcon from '@mui/icons-material/Store';
import DescriptionIcon from '@mui/icons-material/Description';
import ReviewsIcon from '@mui/icons-material/Reviews';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SavingsIcon from '@mui/icons-material/Savings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import InboxIcon from '@mui/icons-material/Inbox';
import './Sidebar.css'; // Assuming shared styles

const SidebarUser = ({ isMobile, isVisible, toggleSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isVisible && !event.target.closest(".sidebar")) {
        if (isMobile) toggleSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside, { passive: true });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, toggleSidebar]);

  const noShow = ['/login', '/signup'].includes(location.pathname);
  if (noShow) return null;

  const handleMenuItemClick = () => {
    if (isMobile) toggleSidebar();
  };

  return (
    <aside className={`sidebar ${isVisible ? 'visible' : ''}`}>
      <Navbar className="flex-column">
        <Nav className="flex-column nav-section">
          <hr className="nav-divider" />
          <Link className="nav-link" to="/dashboard" onClick={handleMenuItemClick}>
            <DashboardIcon /> <span>{t('dashboard')}</span>
          </Link>


          <Link className="nav-link" to="/assets" onClick={handleMenuItemClick}>
            <OtherHousesIcon /> <span>{t('assets')}</span>
          </Link>


          {/* Inbox */}
          <Link className="nav-link" to="/inbox" onClick={handleMenuItemClick}>
            <InboxIcon /> <span>{t('inbox')}</span>
          </Link>


          <Link className="nav-link" to="/marketplace" onClick={handleMenuItemClick}>
            <PeopleIcon /> <span>{t('marketplace')}</span>
          </Link>
          <Link className="nav-link" to="/documents" onClick={handleMenuItemClick}>
            <DescriptionIcon /> <span>{t('contracts')}</span>
          </Link>
          <Link className="nav-link" to="/reviews" onClick={handleMenuItemClick}>
            <ReviewsIcon/> <span>{t('reviews')}</span>
          </Link>
          <Link className="nav-link" to="/ads" onClick={handleMenuItemClick}>
            <StoreIcon /> <span>{t('ads')}</span>
          </Link>

          <hr className="nav-divider" />

          <Link className="nav-link" to="profile" onClick={handleMenuItemClick}>
            <PersonIcon /> <span>{t('profile')}</span>
          </Link>

        </Nav>
      </Navbar>
      <div className="sidebar-footer">
        Yefina © 2025
      </div>
    </aside>
  );
};

export default SidebarUser;
