import React, { memo } from 'react';
import { Grid, Typography, Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useImages } from '../../../context/ImageContext';

const TypeSelector = memo(({ onSelect }) => {
  const { t } = useTranslation();
  const { images, loading } = useImages();

  const assetTypes = ['LAND', 'HOUSE', 'APARTMENT', 'AUTOMOBILE'];

  const handleTypeSelect = (type) => {
    if (type === 'LAND') {
      onSelect(type);
    } else {
      console.log(`${type} is currently not selectable.`);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      {assetTypes.map((type) => (
        <Grid item xs={6} sm={6} md={4} lg={4} key={type}>
          <Card
            sx={{
              height: 160,
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              '&:hover': { boxShadow: '0 6px 12px rgba(0,0,0,0.2)', transform: 'scale(1.02)' },
              opacity: type !== 'LAND' ? 0.5 : 1,
            }}
            onClick={() => handleTypeSelect(type)}
          >
            <CardActionArea sx={{ position: 'relative', height: '100%' }}>
              <CardMedia
                component="img"
                sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                image={images[type] || images['OTHER_ASSET']}
                alt={t(type)}
              />
              <CardContent
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
                  width: '100%',
                  padding: 1,
                }}
              >
                <Typography gutterBottom variant="h5" color="white">
                  {t(type)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
});

export default TypeSelector;
