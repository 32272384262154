import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { deleteUser, getConcierge, updateUser, configCountries } from '../../api';
import { useClient } from 'react-supabase';
import enFlag from '../../resources/en_flag.svg';
import frFlag from '../../resources/fr_flag.svg';
import { useNotification } from '../../context/NotificationContext';
import i18next, { changeLanguage } from 'i18next';
import {
  Box,
  CircularProgress,
  Container,
  Tabs,
  Tab, Autocomplete,
  Button,
  Typography,
  Divider,
  Avatar,
  FormControlLabel,
  Switch,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import SavingsIcon from '@mui/icons-material/Savings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DocumentIcon from '@mui/icons-material/Description';
import WarningIcon from '@mui/icons-material/Warning';
import EuroIcon from '@mui/icons-material/Euro';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import LanguageIcon from '@mui/icons-material/Language';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

export default function Profile() {
  const navigate = useNavigate();
  const { user, loading, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [activeTab, setActiveTab] = useState(0);
  const client = useClient();
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const defaultCountry = user.country;
  const [countriesList, setCountries] = useState([defaultCountry]);
  const [concierge, setConcierge] = useState(null);
  const [localPreferences, setLocalPreferences] = useState(user.preferences || {});
  const [localLanguage, setLocalLanguage] = useState(user.language);
  const [localName, setLocalName] = useState(user?.name);
  const [localSurname, setLocalSurname] = useState(user?.surname);
  const [localPhone, setLocalPhone] = useState(user?.phone);
  const [localCountry, setLocalCountry] = useState(user?.country);
  const [basicModal, setBasicModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [confirmKeyword, setConfirmKeyword] = useState('');

  const handleContactClick = () => window.open('https://wa.me/message/ZTZUNNZHD4JRM1', '_blank');

  const toggleShow = () => setBasicModal(!basicModal);
  const isPro = user.role === 'PRO';
  const isIndividual = user.role === 'INDIVIDUAL';

  useEffect(() => {
    configCountries().then(response => {
      setCountries(response.data);
    });
  }, []);

  const handleInputChange = (key, value) => {
    switch (key) {
      case 'name':
        setLocalName(value);
        break;
      case 'surname':
        setLocalSurname(value);
        break;
      case 'phone':
        setLocalPhone(value);
        break;
      case 'country':
        setLocalCountry(value);
        break;
      default:
        const updatedPreferences = { ...localPreferences, [key]: value };
        setLocalPreferences(updatedPreferences);
    }
    setIsDirty(true);
  };


  const handleDeleteAccount = async () => {
    try {
      setIsLoading(true);
      // Step 2: Delete from backend
      await deleteUser();

      showNotification(t('alert_account_deleted'), 'success');
      navigate('/logout');
    } catch (error) {
      console.error('Failed to delete account:', error);
      showNotification(t('delete_account_failed'), 'error');
    } finally {
      setIsLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  useEffect(() => {
    if (user) {
      // Initialize preferences and language when user data is available
      setLocalPreferences({
        estimations: user.preferences?.estimations || false,
        notifications: user.preferences?.notifications || false,
        debts: user.preferences?.debts || false,
        savings: user.preferences?.savings || true,
        documents: user.preferences?.documents || false,
        automatic_validation_days: user.preferences?.automatic_validation_days || 0,
        yefina_review: user.preferences?.yefina_review || false,
      });
      setLocalLanguage(user.language || 'en');
    }
  }, [user]);

  const handleLanguageChange = () => {
    const newLanguage = localLanguage === 'en' ? 'fr' : 'en';
    setLocalLanguage(newLanguage);
    setIsDirty(true);
  };
  useEffect(() => {
    const fetchConciergeDetails = async () => {
      if (user.role === 'PRO') {
        try {
          const response = await getConcierge(user.concierge_id);
          setConcierge(response.data);
        } catch (error) {
          console.error('Failed to fetch concierge details:', error);
        }
      }
    };

    // Fetching user session email if available
    const session = client.auth.session();
    if (session && session.user) {
      setUserEmail(session.user.email);
    }

    if (user) {
      fetchConciergeDetails(); // Ensure function is called if user is present
    } else {
      refreshUser();
    }
  }, [user, refreshUser]);

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleApplyChanges = async () => {
    try {
      // Construct the data object to update the user information and preferences

      const data = {
        id: user.id,
        name: localName,
        surname: localSurname,
        email: user.email,
        phone: localPhone,
        currency: user.currency,
        language: localLanguage,
        country: localCountry,
        preferences: {
          ...user.preferences,
          estimations: Boolean(localPreferences.estimations),
          notifications: Boolean(localPreferences.notifications),
          debts: Boolean(localPreferences.debts),
          savings: Boolean(localPreferences.savings),
          documents: Boolean(localPreferences.documents),
          yefina_review: Boolean(localPreferences.yefina_review),
          automatic_validation_days: parseInt(localPreferences.automatic_validation_days, 10),
        },
      };
      console.log('Updating user data:', data);
      // Call the updateUser API to update the backend

      await updateUser(data);
      showNotification(t('profile_edit_successful'), 'success');
      refreshUser(); // Refresh user to update with new preferences
      changeLanguage(localLanguage);
      setIsDirty(false);
    } catch (error) {
      console.error('Failed to update user', error);
      showNotification(t('profile_edit_failed'), 'error');
    }
  };
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(i18next.language, options);
  };

  return (
    <Container maxWidth="md" sx={{ position: 'relative', minHeight: '100vh', pb: 10 }}>
      {/* Header Section with Editable Avatar */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          p: 3,
          background: 'linear-gradient(to right, #f9f9f9, #f0f0f0)',
          borderRadius: 2,
          boxShadow: 1,
          mb: 4,
        }}
      >
        {/* Avatar and User Information */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{
              width: 90,
              height: 90,
              border: '2px solid #eee',
              boxShadow: 2,
              cursor: 'pointer',
              ':hover': { borderColor: 'primary.main' },
              transition: 'border-color 0.3s ease',
            }}
            src={user.avatarUrl || '/default-avatar.png'}
            onClick={() => toggleShow()}
          />
          <Box ml={3}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              {`${user.surname} ${user.name}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('account_type')}: {t(user.role)}
            </Typography>
            {!isPro && <Typography variant="body2" color="textSecondary">
              {t('subscription')}:
              {user.plan_expiry_date ? (
                formatDate(user.plan_expiry_date)
              ) : (
                <>
                  {' '}{t('free')} {' '}
                  <Typography
                    component="span"
                    color="primary"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      ':hover': { color: 'secondary.main' },
                    }}
                    onClick={() => setActiveTab(2)} // Navigate to Advanced Tab
                  >
                    {t('upgrade')}
                  </Typography>
                </>
              )}
            </Typography>}
          </Box>
        </Box>
      </Box>


      {/* Tabs */}
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label={t('profile')} />
        <Tab label={t('preferences')} />
        <Tab label={t('advanced')} />
        {isPro && concierge && <Tab label={t('company')} />}
      </Tabs>

      {/* Tab Content */}
      <Box>
        <Box
          sx={{
            transition: 'opacity 0.5s',
            opacity: 1,
            mt: 1,
          }}
        >
          {activeTab === 0 && ( // Details Tab
            <Box p={2}>
              <Box display="flex" alignItems="center">
                <PersonIcon color="primary" sx={{ fontSize: 24, mr: 2 }} />
                <Box display="flex" flexDirection="column" sx={{ mr: 3 }}>
                  <Typography variant="caption" color="textSecondary">{t('name')}</Typography>
                  <Typography variant="body1" suppressContentEditableWarning contentEditable onBlur={(e) => handleInputChange('name', e.target.textContent)}>
                    {user.name || t('no_name')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">{t('surname')}</Typography>
                  <Typography variant="body1" suppressContentEditableWarning contentEditable onBlur={(e) => handleInputChange('surname', e.target.textContent)}>
                    {user.surname || t('no_surname')}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={1}>
                <MailIcon color="info" sx={{ fontSize: 24, mr: 2 }} />
                <Box>
                  <Typography variant="caption" color="textSecondary">{t('email')}</Typography>
                  <Typography variant="body1">
                    {user.email || t('no_email')}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              <Box display="flex" alignItems="center" mb={1}>
                <PhoneIcon color="secondary" sx={{ fontSize: 24, mr: 2 }} />
                <Box flex={1}>
                  <Typography variant="caption" color="textSecondary">{t('phone')}</Typography>
                  <TextField
                    variant="standard"
                    type="number"
                    value={localPhone || ''}
                    onChange={(e) => handleInputChange('phone', e.target.value)}
                    placeholder={t('enter_phone_number')}
                    InputProps={{
                      style: {
                        fontSize: '1rem', // equivalent to body1 in Typography
                        lineHeight: '1.5', // similar line height
                      },
                      disableUnderline: true,
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </Box>
              <Divider sx={{ my: 1 }} />

              {/* Country Field using Improved Autocomplete */}
              <Box display="flex" alignItems="center" mb={2}>
                <Box flex={1}>
                  <Autocomplete
                    disabled={user.role === "PRO"}
                    options={countriesList}
                    getOptionLabel={(option) => option || ""}
                    value={localPreferences.country || user.country || ""}
                    onChange={(event, value) => handleInputChange('country', value || "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          <Box display="flex" alignItems="center">
                            <LocationOnIcon sx={{ fontSize: 18, color: 'error.main', mr: 1 }} />
                            {t('country')}
                          </Box>
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box display="flex" alignItems="center" mb={2}>
                <LanguageIcon color="info" sx={{ fontSize: 24, mr: 1 }} />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="caption" color="textSecondary">{t('language')}</Typography>
                  <Button onClick={handleLanguageChange}>
                    <img className="language-flag" src={localLanguage === 'en' ? enFlag : frFlag} alt="Language Flag" />
                  </Button>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 40 }} />
                <EuroIcon color="primary" sx={{ fontSize: 24, mr: 1 }} />
                <Box>
                  <Typography variant="caption" color="textSecondary">{t('currency')}</Typography>
                  <Typography variant="body1">{user.currency}</Typography>
                </Box>
              </Box>

            </Box>
          )}

          {activeTab === 1 && ( // Preferences Tab
            <Box p={1}>
              {/* General Preferences */}
              <Typography variant="h6" mb={1}>{t('general_preferences')}</Typography>
              {isIndividual && <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="body1" sx={{ mr: 2 }}>
                  {t('automatic_validation_days')}
                </Typography>
                <TextField
                  type="number"
                  value={localPreferences.automatic_validation_days || 0}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 0 && value <= 99) {
                      handleInputChange('automatic_validation_days', value);
                    } else {
                      handleInputChange('automatic_validation_days', '');
                      showNotification(t('positive_number_required'), 'error');
                    }
                  }}
                  inputProps={{ min: 1 }}
                  sx={{ width: '80px', textAlign: 'center' }}
                />
              </Box>}

              {isPro &&
                <Box display="flex" alignItems="center" mb={2}>
                <WorkspacePremiumIcon color="success" sx={{ fontSize: 24, mr: 2 }} />
                <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                  {t('yefina_review')}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPreferences.yefina_review || false}
                      onChange={(e) => handleInputChange('yefina_review', e.target.checked)}
                    />
                  }
                />
              </Box>
              }


              <Box display="flex" alignItems="center" mb={2}>
                <NotificationsActiveIcon color="warning" sx={{ fontSize: 24, mr: 2 }} />
                <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                  {t('notifications')}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPreferences.notifications || false}
                      onChange={(e) => handleInputChange('notifications', e.target.checked)}
                    />
                  }
                />
              </Box>

              <Divider sx={{ my: 1 }} />

              {/* Sidebar Preferences */}
              <Typography variant="h6" mb={2}>{t('sidebar_preferences')}</Typography>

              {isIndividual && (
                <>
                  {/* Estimation */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <BarChartIcon color="action" sx={{ fontSize: 24, mr: 2 }} />
                    <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                      {t('estimations')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={localPreferences.estimations || false}
                          onChange={(e) => handleInputChange('estimations', e.target.checked)}
                        />
                      }
                    />
                  </Box>

                  {/* Savings */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <SavingsIcon color="success" sx={{ fontSize: 24, mr: 2 }} />
                    <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                      {t('savings')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={localPreferences.savings || false}
                          onChange={(e) => handleInputChange('savings', e.target.checked)}
                        />
                      }
                    />
                  </Box>

                  {/* Debts */}
                  <Box display="flex" alignItems="center" mb={1}>
                    <CreditCardIcon color="secondary" sx={{ fontSize: 24, mr: 2 }} />
                    <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                      {t('debts')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={localPreferences.debts || false}
                          onChange={(e) => handleInputChange('debts', e.target.checked)}
                        />
                      }
                    />
                  </Box>
                </>
              )}

              {/* Documents */}
              <Box display="flex" alignItems="center" mb={1}>
                <DocumentIcon color="info" sx={{ fontSize: 24, mr: 2 }} />
                <Typography variant="body1" color="textSecondary" sx={{ flexGrow: 1 }}>
                  {t('documents')}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPreferences.documents || false}
                      onChange={(e) => handleInputChange('documents', e.target.checked)}
                    />
                  }
                />
              </Box>

            </Box>
          )}

          {activeTab === 3 && isPro && concierge && (
            <Box display="flex" flexDirection="column" p={2}>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('name')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.name || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('code')} Yefina</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.code || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('country')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.country || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('city')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.city || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('fees')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.fees ? `${concierge.fees}%` : t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('category')}</Typography>
                <Typography variant="body1" fontWeight="medium">{t(concierge.category) || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('contact')}</Typography>
                <Typography variant="body1" fontWeight="medium">
                  {concierge.contact ? (
                    <>
                      {concierge.contact.phone && <div>{`${t('phone')}: ${concierge.contact.phone}`}</div>}
                      {concierge.contact.email && <div>{`${t('email')}: ${concierge.contact.email}`}</div>}
                    </>
                  ) : t('not_available')}
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('address')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.address || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('description')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.description || t('not_available')}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" mb={2}>
                <Typography variant="body2" color="textSecondary">{t('currency')}</Typography>
                <Typography variant="body1" fontWeight="medium">{concierge.currency || t('not_available')}</Typography>
              </Box>
            </Box>
          )}
          {activeTab === 2 && (
            <Box p={2} sx={{ maxWidth: '600px', margin: 'auto' }}>

              {/* Unlock Premium Features Section */}
              {user.role === 'INDIVIDUAL' && (
                <Box
                  sx={{
                    mb: 4,
                    p: 2,
                    background: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
                    {t('unlock_premium_features')}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                    {t('premium_features_description')}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      ':hover': { color: 'secondary.main' },
                    }}
                    onClick={handleContactClick}
                  >
                    {t('contact_us')}
                  </Typography>
                </Box>
              )}
              <Divider sx={{ my: 3 }} />

              {/* Delete Account Section */}
              <Box
                sx={{
                  p: 2,
                  background: '#fff5f5',
                  borderRadius: '8px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                }}
              >
                <WarningIcon color="error" sx={{ fontSize: 40, mb: 1 }} />
                <Typography variant="body1" color="error" sx={{ mb: 1, fontWeight: 'bold' }}>
                  {t('delete_account')}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  {t('delete_account_warning')}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<WarningIcon />}
                  onClick={() => setDeleteDialogOpen(true)}
                  sx={{
                    textTransform: 'none',
                    px: 3,
                    py: 1,
                    fontWeight: 'bold',
                  }}
                >
                  {t('delete')}
                </Button>
              </Box>
            </Box>
          )}


        </Box>

      </Box>

      {/* Save Button */}
      {activeTab !== 2 && <Button
        variant="contained"
        color="primary"
        disabled={!isDirty}
        fullWidth
        onClick={handleApplyChanges}
        sx={{ mt: 3 }}
      >
        {t('save_changes')}
      </Button>
      }
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>{t('confirm_account_deletion')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('delete_account_warning')}
          </DialogContentText>
          <TextField
            label={t('type_confirm_to_delete')}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
            onChange={(e) => setConfirmKeyword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <Button
            onClick={handleDeleteAccount}
            color="error"
            disabled={confirmKeyword !== 'CONFIRM'} // Enabled only when the user types "CONFIRM"
          >
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>

  );
}
