import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ConfirmDialog from './ConfirmDialog';
import Chip from '@mui/material/Chip';
import { formatAmount } from '../utils';

const TransactionList = ({ title, transactions, currency, onEdit, onDelete, loading, readOnly=false}) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState(-1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [filters, setFilters] = useState({
    type: '',
    category: '',
    keyword: '',
    tags: []
  });

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? -1 : id);
  };

  const handleDeleteClick = (transaction) => {
    setTransactionToDelete(transaction); // Set the transaction to delete
    setIsDialogOpen(true); // Open confirmation dialog
  };

  const handleConfirmDelete = () => {
    if (transactionToDelete) {
      onDelete(transactionToDelete.id); // Call the onDelete callback with the transaction ID
      setTransactionToDelete(null); // Clear the transaction to delete
      setIsDialogOpen(false); // Close the dialog
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tags') {
      const newTags = typeof value === 'string' ? value.split(',') : value;
      setFilters({ ...filters, tags: newTags });
    } else {
      setFilters({ ...filters, [name]: value });
    }
  };

  const handleTagFilterChange = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.includes("")) {
      setFilters({ ...filters, tags: [] });
    } else {
      setFilters({ ...filters, tags: value });
    }
  };

  const handleClearFilters = () => {
    setFilters({
      type: '',
      category: '',
      keyword: '',
      tags: []
    });
  };

  // Extract unique types, categories, and tags
  const uniqueTypes = useMemo(() => [...new Set(transactions.map(transaction => transaction.type))], [transactions]);
  const uniqueCategories = useMemo(() => [...new Set(transactions.map(transaction => transaction.category).filter(Boolean))], [transactions]);
  const uniqueTags = useMemo(() => [...new Set(transactions.flatMap(transaction => transaction.tags || []))], [transactions]);

  // Helper function to format date as "day-month"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()]; // JavaScript months are 0-indexed
    return `${day}-${month}`;
  };

  // Apply filters to transactions
  const filteredTransactions = transactions.filter(transaction => {
    const keyword = filters.keyword.toLowerCase();
    const tagsMatch = filters.tags.length === 0 || (transaction.tags || []).some(tag => filters.tags.includes(tag));
    return (
      (!filters.type || transaction.type === filters.type) &&
      (!filters.category || transaction.category === filters.category) &&
      (!keyword || transaction.description.toLowerCase().includes(keyword)) &&
      tagsMatch
    );
  });
  // Group transactions by month
  const transactionsByMonth = filteredTransactions.reduce((acc, transaction) => {
    const monthYear = new Date(transaction.date).toLocaleString('default', { month: 'short', year: 'numeric' });
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(transaction);
    return acc;
  }, {});

  // Calculate total amount
  const totalAmount = filteredTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);

  // Get the entries
  const monthBlocks = Object.entries(transactionsByMonth);

  return (
    <Box sx={{ mb: 4, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: 1, border: '1px solid #e0e0e0', p: 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" gutterBottom component="div">
          {title}
        </Typography>
        <Typography variant="h7" gutterBottom component="div">
          {t('Total')}: {formatAmount(totalAmount, currency, true)}
        </Typography>
      </Box>

      {/* Filter Controls */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: 1,  backgroundColor: 'rgba(0, 0, 0, 0.03)', pb: 1 }}>
        <Button
          onClick={() => setFiltersOpen(!filtersOpen)}
          endIcon={filtersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          sx={{ mb: 2 }}
        >
          {t('Filters')}
        </Button>
        <Collapse in={filtersOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, m: 1 }}>
            <TextField
              label={t('Type')}
              select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              fullWidth
            >
              <MenuItem value=""><em>{t('All')}</em></MenuItem>
              {uniqueTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {t(type)}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={t('Category')}
              select
              name="category"
              value={filters.category}
              onChange={handleFilterChange}
              fullWidth
            >
              <MenuItem value=""><em>{t('All')}</em></MenuItem>
              {uniqueCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {t(category)}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label={t('Tags')}
              select
              name="tags"
              value={filters.tags}
              onChange={handleTagFilterChange}
              SelectProps={{
                multiple: true
              }}
              fullWidth
            >
              <MenuItem value=""><em>{t('All')}</em></MenuItem>
              {uniqueTags.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  {tag}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" onClick={handleClearFilters}>
              {t('Clear Filters')}
            </Button>
          </Box>
        </Collapse>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : filteredTransactions.length === 0 ? (
        <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
          {t('no_transaction')}
        </Typography>
      ) : (
        monthBlocks.map(([monthYear, monthlyTransactions]) => {
          // Calculate the subtotal for the month
          const subtotal = monthlyTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);

          return (
            <Accordion key={monthYear} sx={{ mt: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Typography variant="h7">{monthYear}</Typography>
                  <Typography variant="subtitle2">
                    {t('subtotal')}: {formatAmount(subtotal, currency, true)}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {monthlyTransactions.map((transaction, index) => (
                  <Card key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, p: 1, boxShadow: 1, backgroundColor: '#f5f5f5' }}>
                    <CardContent
                      sx={{ flex: '1 1 auto', p: 0.5, "&:last-child": { paddingBottom: 0.5 } }}
                      onClick={() => handleExpandClick(transaction.id)}
                    >
                      <Typography variant="body2" noWrap>{t(transaction.type)}</Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '200px',
                        }}
                      >
                        {t(transaction.category)}
                      </Typography>
                      <Typography
                        variant="body2"
                        noWrap
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '200px',
                        }}
                      >
                        {transaction.title}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {transaction.tags?.map((tag, index) => (
                          <Chip key={index} label={tag} size="small" />
                        ))}
                      </Box>
                      <Typography variant="caption" display="block" gutterBottom noWrap>{formatDate(transaction.date)}</Typography>
                    </CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, backgroundColor: 'rgba(255, 255, 255, 0.7)', border: '1px solid #e0e0e0', borderRadius: '4px' }}>
                      <Typography
                        variant="body2"
                        sx={{
                          mx: 2,
                          color: transaction.amount < 0 ? 'error.main' : 'success.main'
                        }}
                      >
                        {formatAmount(transaction.amount, currency)}
                      </Typography>
                      <Collapse in={expandedId === transaction.id} timeout="auto" unmountOnExit>
                      {(transaction.type === 'INCOME' || transaction.type === 'EXPENSE' || transaction.type === 'DEPOSIT' || transaction.type === 'WITHDRAWAL')
                      && !readOnly && !transaction.initiator_id && (
                        <>
                        {onEdit && <Tooltip title={t('Edit')}>
                          <IconButton aria-label={t('edit')} color="info" onClick={() => onEdit(transaction)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>}
                          {onDelete  && <Tooltip title={t('Delete')}>
                            <IconButton aria-label={t('delete')} color="error" onClick={() => handleDeleteClick(transaction)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>}
                          </>
                        )}
                      </Collapse>
                    </Box>
                  </Card>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    {/* Confirmation Dialog */}
      <ConfirmDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        confirmButtonColor="error"
      />
    </Box>

  );
};

export default TransactionList;
