import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Paper,
  InputAdornment,
  Modal
} from '@mui/material';
import { useForm, Controller, set } from 'react-hook-form';
import CloseButton from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { configExpenseTypes, configIncomeTypes, configTransactionTypes, getDebts } from '../../api';
import { createAmountChangeHandler, currencySymbol } from '../../utils';
import { useTheme } from '@mui/material/styles';
import ConfirmDialog from '../../utils/ConfirmDialog';

const FormAssetTransaction = ({ user, asset, apply, toggleShow, initialData }) => {
  const { t } = useTranslation();
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [debts, setDebts] = useState([]);
  const [showSaleWarning, setShowSaleWarning] = useState(false); // State for SALE warning
  const [previousType, setPreviousType] = useState(""); // Track previous type
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      date: initialData?.date || new Date().toISOString().split('T')[0],
      asset_id: asset.id,
      type: initialData?.type || '',
      category: initialData?.category || '',
      title: initialData?.title || '',
      amount: initialData?.amount ? Math.abs(initialData.amount).toString() : '',
      interest_amount: initialData?.interest_amount ? Math.abs(initialData.interest_amount).toString() : '',
      quantity: initialData?.quantity || 1,
      repeat_until: initialData?.repeat_until || null,
      notes: initialData?.notes || '',
      tags: initialData?.tags?.join(', ') || '',
      debt_id: initialData?.debt_id || null,
      occupancy: initialData?.occupancy || '', // Add occupancy in default values
    },
    mode: "onChange",
  });

  useEffect(() => {
    configTransactionTypes()
      .then(response => {
        let filteredTypes = response.data;
        if (asset.type === 'AUTOMOBILE' || asset.type === 'REAL_ESTATE') {
          filteredTypes = filteredTypes.filter(type => type !== 'DEPOSIT' && type !== 'WITHDRAWAL');
        }
        setTypes(filteredTypes);
      })
      .catch(console.error);

    if (initialData) {
      handleTypeChange(initialData.type);
    }
  }, [asset.type, initialData]);

  const onSubmitHandler = (data) => {
    if (!isNaN(data.amount) && asset.user_id) {
      const dataToSend = {
        ...data,
        user_id: asset.user_id,
        asset_id: asset.id,
        amount: Math.abs(parseFloat(data.amount)),
        amount_opt: Math.abs(parseFloat(data.interest_amount)),
        occupancy: data.occupancy === '' ? null : parseInt(data.occupancy),
        tags: data.tags.split(',').map(tag => tag.trim())
      };
      apply(dataToSend);
      toggleShow();
    }
  };

  const handleSaleConfirmation = (confirm) => {
    setShowSaleWarning(false);
    if (confirm) {
      setValue('type', 'SALE');
      setCategories([]);
    } else {
      setValue('type', previousType);
    }
  };

  const handleTypeChange = (value) => {
    if (value === "SALE") {
      setShowSaleWarning(true);
      setPreviousType(watch("type"));
    } else {
      setValue('type', value);
      if (value === 'INCOME') {
        configIncomeTypes()
          .then(response => setCategories(response.data))
          .catch(console.error);
      } else if (value === 'EXPENSE') {
        configExpenseTypes()
          .then(response => setCategories(response.data))
          .catch(console.error);
      } else {
        setCategories([]);
        setValue('category', '');
      }
    }
  };

  const handleCategoryChange = (category) => {
    setValue('category', category);
    if (category === 'DEBT_REPAYMENT') {
      getDebts(user.id).then(response => {
        setDebts(response.data);
        setValue('debt_id', response.data.length > 0 ? response.data[0].id : null);
      }).catch(console.error);
    } else {
      setDebts([]);
      setValue('debt_id', null);
    }
  };

  const category = watch('category');
  const type = watch('type');

  return (
    <>
      <Modal open={true} onClose={toggleShow}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
          <Paper sx={{ padding: 2, minWidth: 300, maxWidth: 600, maxHeight: '90vh', overflow: 'auto' }}>
            <Typography variant="h6" sx={{ m: 1 }}>{t(initialData ? 'edit_transaction' : 'add_transaction')}</Typography>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="date"
                    control={control}
                    rules={{
                      required: t('date_required'),
                      validate: (value) => {
                        const selectedDate = new Date(value);
                        const purchaseDate = new Date(asset.purchase_date);
                        const today = new Date();
                        if (selectedDate < purchaseDate) {
                          return t('date_cannot_be_before_purchase_date');
                        }
                        if (selectedDate > today) {
                          return t('date_cannot_be_in_future');
                        }
                        return true;
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label={t('date')}
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: {
                            min: asset.purchase_date, // Set the minimum date to purchase_date
                            max: new Date().toISOString().split("T")[0], // Set the maximum date to today
                          },
                        }}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />


                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>{t('type')}</InputLabel>
                        <Select
                          {...field}
                          label={t('type')}
                          onChange={(e) => {
                            field.onChange(e);
                            handleTypeChange(e.target.value);
                          }}
                        >
                          <MenuItem value="" disabled>{t('select_type')}</MenuItem>
                          {types.map((type) => (
                            <MenuItem key={type} value={type}>{t(type)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.type && <Typography color="error">{errors.type.message}</Typography>}
                </Grid>

                {(type === 'INCOME' || type === 'EXPENSE') && (
                  <Grid item xs={12}>
                    <Controller
                      name="category"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel>{t('category')}</InputLabel>
                          <Select
                            {...field}
                            label={t('category')}
                            onChange={(e) => {
                              field.onChange(e);
                              handleCategoryChange(e.target.value);
                            }}
                          >
                            <MenuItem value="" disabled>{t('select_cat')}</MenuItem>
                            {categories.map((category) => (
                              <MenuItem key={category} value={category}>{t(category)}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors.category && <Typography color="error">{errors.category.message}</Typography>}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: `${t('title_required')}` }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('title')}
                        fullWidth
                      />
                    )}
                  />
                  {errors.title && <Typography color="error">{errors.title.message}</Typography>}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: `${t('amount_required')}`, validate: value => value > 0 || `${t('amount_must_be_positive')}` }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('amount')}
                        type="number"
                        fullWidth
                        value={field.value}
                        onChange={(e) => {
                          const value = Math.abs(e.target.value);
                          field.onChange(value);
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                        }}
                      />
                    )}
                  />
                  {errors.amount && <Typography color="error">{errors.amount.message}</Typography>}
                </Grid>

                {category === 'DEBT_REPAYMENT' && (
                  <Grid item xs={12}>
                    <Controller
                      name="interest_amount"
                      control={control}
                      rules={{ required: `${t('interest_amount_required')}`, validate: value => value > 0 || `${t('amount_must_be_positive')}` }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('interest_amount')}
                          type="number"
                          fullWidth
                          value={field.value}
                          onChange={(e) => {
                            const value = Math.abs(e.target.value);
                            field.onChange(value);
                          }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                          }}
                        />
                      )}
                    />
                    {errors.interest_amount && <Typography color="error">{errors.interest_amount.message}</Typography>}
                  </Grid>
                )}

                {category === 'DEBT_REPAYMENT' && debts.length > 0 && (
                  <Grid item xs={12}>
                    <Controller
                      name="debt_id"
                      control={control}
                      rules={{ required: `${t('debt_id_required')}` }}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel shrink>{t('select_debt')}</InputLabel>
                          <Select
                            {...field}
                            label={t('select_debt')}
                          >
                            {debts.map(debt => (
                              <MenuItem key={debt.id} value={debt.id}>
                                {debt.lender} - {debt.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors.debt_id && <Typography color="error">{errors.debt_id.message}</Typography>}
                  </Grid>
                )}

                {type === 'INCOME' && (
                  <Grid item xs={12}>
                    <Controller
                      name="occupancy"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('occupancy')}
                          type="number"
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('days')}</InputAdornment>,
                          }}
                        />
                      )}
                    />
                    {errors.occupancy && <Typography color="error">{errors.occupancy.message}</Typography>}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('tags')}
                        fullWidth
                        placeholder={t('enter_tags_comma_separated')}
                      />
                    )}
                  />
                  {errors.tags && <Typography color="error">{errors.tags.message}</Typography>}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="notes"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t('notes')}
                        fullWidth
                        multiline
                        rows={2}
                      />
                    )}
                  />
                  {errors.notes && <Typography color="error">{errors.notes.message}</Typography>}
                </Grid>

                {(type === 'INCOME' || type === 'EXPENSE') && (
                  <Grid item xs={12}>
                    <Controller
                      name="repeat_until"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('recurring_transaction')}
                          type="date"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputProps: {
                              min: asset.purchase_date, // Set the minimum date to purchase_date
                              max: new Date().toISOString().split("T")[0], // Set the maximum date to today
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>

              <Box mt={2} sx={{ position: 'sticky', bottom: 0, justifyContent: 'flex-end', display: 'flex', backgroundColor: theme.palette.background.paper, paddingTop: 1 }}>
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<CloseButton />}
                  onClick={toggleShow}
                  sx={{ mr: 1 }}>{t('cancel')}</Button>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  startIcon={<CheckIcon />}
                  disabled={!isValid}
                >
                  {t('submit')}
                </Button>
              </Box>
            </form>
          </Paper>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <ConfirmDialog
        open={showSaleWarning}
        onClose={() => handleSaleConfirmation(false)}
        onConfirm={() => handleSaleConfirmation(true)}
        message={t('sale_warning')}
      />
    </>
  );
};

export default FormAssetTransaction;
