import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../context/NotificationContext';

const { REACT_APP_API_URL } = process.env;
const axiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
});


// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
    config => {
        // Get the token from localStorage or any other storage mechanism
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle 403 errors
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 403) {
            const errorMessage = error.response?.data?.detail || "You don't have permission to perform this action.";
            if (errorMessage.toLowerCase().includes('authenticated')) {
                console.warn('Session expired. Redirecting to login.');

                // Clear user data
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('data');

                // Redirect to login
                window.location.href = '/login';
            } else {
                // Display a notification for forbidden actions
                // Replace this with your notification system
                console.warn('Forbidden action detected:', errorMessage);
                //alert(errorMessage); // Replace with a proper notification system
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
