import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Rating,
  TextField,
  Divider,
  Avatar,
} from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { submitReview, deleteReview } from '../../api';
import { useNotification } from '../../context/NotificationContext';
import { Business } from '@mui/icons-material';

const PendingReviewList = ({ reviews, refresh }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  const [selectedReview, setSelectedReview] = useState(null);
  const [reviewData, setReviewData] = useState({ headline: '', rating: 0, comment: '' });

  const submitReviewMutation = useMutation(submitReview, {
    onSuccess: () => {
      showNotification(t('review_submitted'), 'success');
      queryClient.invalidateQueries('pendingReviews');
      resetForm();
      if (refresh) refresh();
    },
    onError: (error) => {
      showNotification(
        `${t('review_submission_failed')}: ${
          error.response?.data?.detail || error.message
        }`,
        'error'
      );
    },
  });

  const deleteReviewMutation = useMutation(deleteReview, {
    onSuccess: () => {
      if (refresh) refresh();
      showNotification(t('review_deleted'), 'success');
      queryClient.invalidateQueries('pendingReviews');
    },
    onError: (error) => {
      showNotification(
        `${t('review_deletion_failed')}: ${
          error.response?.data?.detail || error.message
        }`,
        'error'
      );
    },
  });

  const resetForm = () => {
    setSelectedReview(null);
    setReviewData({ headline: '', rating: 0, comment: '' });
  };

  const handleSubmitReview = () => {
    if (!selectedReview) return;
    if (validateForm()) {
      submitReviewMutation.mutate({
        review_id: selectedReview.id,
        review: reviewData,
      });
    }
  };

  const validateForm = () => {
    if (reviewData.rating === 0) {
      showNotification(t('rating_required'), 'error');
      return false;
    }
    if (!reviewData.headline.trim()) {
      showNotification(t('headline_required'), 'error');
      return false;
    }
    if (!reviewData.comment.trim()) {
      showNotification(t('comment_required'), 'error');
      return false;
    }
    return true;
  };

  const handleRefuseReview = (reviewId) => {
    deleteReviewMutation.mutate(reviewId);
  };

  const handleInputChange = (field, value) => {
    setReviewData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box my={3}>
      {reviews.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          {t('no_pending_reviews')}
        </Typography>
      ) : (
        reviews.map((review) => (
          <Card
            key={review.id}
            sx={{
              my: 2,
              p: 2,
              border: '1px solid #e0e0e0',
              borderRadius: '8px',
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              transition: 'transform 0.2s, box-shadow 0.2s',
              '&:hover': {
                transform: 'scale(1.02)',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            {/* Concierge Section */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f5f5f5',
                padding: 2,
                borderRadius: '8px',
                mb: 2,
              }}
            >
              <Avatar sx={{ bgcolor: 'primary.main', mr: 2 }}>
                <Business />
              </Avatar>
              <Typography variant="h6" color="primary">
                {review.concierge_name}
              </Typography>
            </Box>

            <CardContent>
              {/* Asset Information */}
              <Box
    sx={{
      mb: 2,
      border: '1px solid #e0e0e0',
      padding: 2,
      borderRadius: '8px',
      backgroundColor: '#fafafa',
    }}
  >
    <Typography
      variant="subtitle2"
      color="textSecondary"
      sx={{ textTransform: 'uppercase', mb: 1 }}
    >
      {t('asset')}
    </Typography>
    <Typography variant="h6" fontWeight="bold">
      {review.asset_name}
    </Typography>
    <Typography variant="body2" color="textSecondary">
      {t('describe_management_of_asset', 'Describe the management of your asset by this concierge.')}
    </Typography>
  </Box>
              {selectedReview?.id === review.id && (
                <Box>
                  <Divider sx={{ mb: 2 }} />
                  <Typography variant="body1">{t('provide_rating')}</Typography>
                  <Rating
                    value={reviewData.rating}
                    onChange={(e, newValue) =>
                      handleInputChange('rating', newValue)
                    }
                    size="large"
                    sx={{ mt: 1 }}
                  />
                  {/* Headline Field */}
      <TextField
        label={t('headline')}
        value={reviewData.headline}
        onChange={(e) => handleInputChange('headline', e.target.value)}
        fullWidth
        sx={{ mt: 2 }}
      />
                  <TextField
                    label={t('comment')}
                    placeholder={t('describe_management_of_asset')}
                    multiline
                    rows={3}
                    value={reviewData.comment}
                    onChange={(e) =>
                      handleInputChange('comment', e.target.value)
                    }
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                </Box>
              )}
            </CardContent>

            {/* Action Buttons */}
            <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              {selectedReview?.id === review.id ? (
                <>
                  <Button
                    variant="outlined"
                    onClick={resetForm}
                    sx={{ minWidth: '100px' }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmitReview}
                    sx={{ minWidth: '100px' }}
                  >
                    {t('submit')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRefuseReview(review.id)}
                    sx={{ minWidth: '100px' }}
                  >
                    {t('refuse')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setSelectedReview(review)}
                    sx={{ minWidth: '100px' }}
                  >
                    {t('accept')}
                  </Button>
                </>
              )}
            </CardActions>
          </Card>
        ))
      )}
    </Box>
  );
};

export default PendingReviewList;
