import React from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Chip,
    Button,
    Box,
    Divider,
} from '@mui/material';
import EditIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInbox from '@mui/icons-material/CheckCircle';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';


const TransactionDetails = ({ detail, user }) => {
    const { t } = useTranslation();
    const isExpense = detail.type === 'EXPENSE';

    return (
        <Box
            sx={{
                p: 1,
                borderRadius: 2,
                boxShadow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {/* Title and Category */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" fontWeight="bold" noWrap>
                    {detail.title || t('no_title')}
                </Typography>
                <Chip
                    label={t(detail.category)}
                    color={isExpense ? 'error' : 'success'}
                    size="small"
                />
            </Box>
            {/* Amount */}
            <Typography
                variant="h7"
                fontWeight="normal"
                color={isExpense ? 'error.main' : 'success.main'}
            >
                {formatAmount(detail.amount_concierge, user.currency)}
            </Typography>

            {/* Additional Info */}
            {detail.tags && detail.tags != '' && (
                <Typography variant="body2" color="textSecondary" noWrap>
                    <strong>{t('tags')}:</strong> {detail.tags}
                </Typography>
            )}
            {detail.occupancy && (
                <Typography variant="body2" color="textSecondary" noWrap>
                    <strong>{t('occupancy')}:</strong> {detail.occupancy}
                </Typography>
            )}
            {detail.notes && (
                <Typography variant="body2" color="textSecondary" noWrap>
                    <strong>{t('notes')}:</strong> {detail.notes}
                </Typography>
            )}
        </Box>
    );
};


const PendingTransactionCard = ({
    transaction,
    assets,
    user,
    onEdit,
    onDelete,
    onApprove,
    onReject,
    onSubmit,
    onReview
}) => {
    const { t } = useTranslation();
    const asset = assets.find((a) => a.id === transaction.asset_id);
    const isDraft = transaction.status === 'DRAFT';
    const isRejected = transaction.status === 'REJECTED';
    const isSubmitted = transaction.status === 'SUBMITTED';
    const isInPayment = transaction.status === 'WAITING_PAYMENT';
    const isPRO = user.role === 'PRO';
    const isAdmin = user.role === 'ADMIN';
    const isIndividual = user.role === 'INDIVIDUAL';

    // Status-related properties
    const statusColors = {
        DRAFT: 'info',
        SUBMITTED: 'primary',
        VALIDATED: 'success',
        REJECTED: 'error',
        REFUSED: 'warning',
        PENDING: 'default',
        WAITING_PAYMENT: 'info',
    };
    const statusLabels = {
        DRAFT: t('draft'),
        SUBMITTED: t('submitted'),
        VALIDATED: t('validated'),
        REJECTED: t('rejected'),
        REFUSED: t('refused'),
        PENDING: t('pending'),
        WAITING_PAYMENT: t('waiting_payment'),
    };
    return (
        <Card key={transaction.id} sx={{ my: 3, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        {/* Asset Name */}
                        <Typography variant="h6" fontWeight="bold">
                            {asset?.name || ''}
                        </Typography>

                        <Typography variant="subtitle1" color="textSecondary">
                            <strong>{t('date')}:</strong> {new Date(transaction.date).toLocaleDateString()}
                        </Typography>
                        {transaction.repeat_until && (
                            <Typography variant="subtitle1" color="textSecondary">
                                <strong>{t('repeat_until')}:</strong> {new Date(transaction.repeat_until).toLocaleDateString()}
                            </Typography>
                        )}
                        {transaction.notes && (
                            <Typography variant="subtitle1" color="textSecondary">
                                <strong>{t('notes')}:</strong> {transaction.notes}
                            </Typography>
                        )}
                        {transaction.review_required ? (
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    backgroundColor: 'success.light',
                                    color: 'success.contrastText',
                                    px: 2,
                                    py: 0.5,
                                    borderRadius: 2,
                                    fontSize: '0.875rem',
                                    fontWeight: 'bold',
                                    boxShadow: 1,
                                }}
                            >
                                <VerifiedIcon sx={{ fontSize: 20 }} /> {/* Verified Icon */}
                                {t('verified_by_Yefina')} {/* Translated text for verified */}
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    backgroundColor: 'error.light',
                                    color: 'error.contrastText',
                                    px: 2,
                                    py: 0.5,
                                    borderRadius: 2,
                                    fontSize: '0.875rem',
                                    fontWeight: 'bold',
                                    boxShadow: 1,
                                }}
                            >
                                <ErrorOutlineIcon sx={{ fontSize: 20 }} /> {/* Not Verified Icon */}
                                {t('not_verified')} {/* Translated text for not verified */}
                            </Box>
                        )}
                    </Box>
                    <Chip
                        label={statusLabels[transaction.status]}
                        color={statusColors[transaction.status]}
                        variant="outlined"
                    />
                </Box>

                <Divider sx={{ my: 2 }} />

                {/* Payment Details */}
                {(isPRO || isAdmin) && isInPayment && (
                    <Box
                        sx={{
                            p: 2,
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            backgroundColor: 'background.paper',
                            boxShadow: 1,
                            mb: 2,
                        }}
                    >
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
                            {t('payment_instructions')}
                        </Typography>
                        <Box sx={{ pl: 1, mt: 1 }}>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <strong>{t('reference')}:</strong> {transaction.reference || t('no_reference')}
                                <Button
                                    size="small"
                                    onClick={() => navigator.clipboard.writeText(transaction.reference || '')}
                                    startIcon={<ContentCopyIcon />}
                                    sx={{ textTransform: 'none' }}
                                >{t('copy')}</Button>
                            </Typography>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <strong>{t('iban')}:</strong> {transaction.payment_details?.iban || t('no_iban')}
                                <Button
                                    size="small"
                                    onClick={() => navigator.clipboard.writeText(transaction.payment_details?.iban || '')}
                                    startIcon={<ContentCopyIcon />}
                                    sx={{ textTransform: 'none' }}
                                >{t('copy')}</Button>
                            </Typography>
                            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <strong>{t('swift')}:</strong> {transaction.payment_details?.swift || t('no_swift')}
                                <Button
                                    size="small"
                                    onClick={() => navigator.clipboard.writeText(transaction.payment_details?.swift || '')}
                                    startIcon={<ContentCopyIcon />}
                                    sx={{ textTransform: 'none' }}
                                >{t('copy')}</Button>
                            </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {t('payment_summary')}
                        </Typography>
                    </Box>
                )}


                {/* Financial Summary */}
                <Box
                    sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        backgroundColor: 'background.paper',
                        p: 2,
                        borderRadius: 2,
                        boxShadow: 1,
                        mb: 2,
                        textAlign: 'center'
                    }}
                >

                    <Typography variant="h6" fontWeight="normal" sx={{ mb: 1 }}>
                        {t('total_amount')}: {formatAmount(transaction.amount_concierge, user.currency)}
                    </Typography>
                    {transaction.review_required && transaction.amount_concierge > 0 && (
                        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                            <strong>{t('yefina_transaction_fee')}:</strong> {formatAmount(transaction.yefina_fee, user.currency)}
                        </Typography>
                    )}
                </Box>
                <Divider sx={{ my: 2 }} />
                {transaction.details?.map((detail, index) => (
                    <TransactionDetails key={index} detail={detail} user={user} />
                ))}

            </CardContent>
            <CardActions>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    {isPRO &&
                        <>
                            {/* Secondary Actions (Aligned to the Left) */}
                            {(isDraft || isRejected || isInPayment) &&
                                <Box>
                                    <Button
                                        size="small"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => onDelete(transaction)}
                                    >
                                        {t('delete')}
                                    </Button>
                                    {!isInPayment &&
                                        <Button
                                            size="small"
                                            color="secondary"
                                            startIcon={<EditIcon />}
                                            onClick={() => onEdit(transaction)}
                                        >
                                            {t('edit')}
                                        </Button>
                                    }
                                </Box>
                            }

                            {/* Main Actions (Aligned to the Right) */}
                            {isDraft &&
                                <Button
                                    size="small"
                                    color="primary"
                                    startIcon={<ForwardToInbox />}
                                    onClick={() => onSubmit(transaction.id)}
                                >
                                    {t('submit')}
                                </Button>
                            }
                        </>
                    }
                    {isIndividual && isSubmitted &&
                        <>
                            {/* Secondary Actions (Aligned to the Left) */}
                            <Button
                                size="small"
                                color="warning"
                                startIcon={<ThumbDownIcon />}
                                onClick={() => onReject(transaction)}
                            >
                                {t('reject')}
                            </Button>
                            <Button
                                size="small"
                                color="success"
                                startIcon={<DoneIcon />}
                                onClick={() => onApprove(transaction.id)}
                            >
                                {t('approve')}
                            </Button>
                        </>
                    }

                    {isAdmin && isInPayment &&
                        <>
                            <Button
                                size="small"
                                color="secondary"
                                startIcon={<EditIcon />}
                                onClick={() => onEdit(transaction)}
                            >
                                {t('edit')}
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                startIcon={<ForwardToInbox />}
                                onClick={() => onReview(transaction.id)}
                            >
                                {t('validate')}
                            </Button>
                        </>
                    }
                </Box>
            </CardActions>
        </Card>
    );
};

export default PendingTransactionCard;
