import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider } from '@mui/material';
import { formatAmount } from '../../utils';

const Details = ({ asset, details, location, currency, t }) => {
  const {
    type,
    category,
    exploitation,
    name,
    description,
    purchase_date,
    purchase_price,
    acquisition_cost,
  } = asset;
  const name_purchase_date = type === 'FINANCIAL_PRODUCT' ? 'open_date' : 'purchase_date';
  const name_purchase_price = type === 'FINANCIAL_PRODUCT' ? 'total_investment' : 'purchase_price';
  const excludedKeys = ['id', 'category'];

  // Helper function to format the asset details
  const formatDetail = (label, value, currency = '') => {
    let displayValue = value;
    if (currency) {
      displayValue = formatAmount(displayValue, currency);
    } else if (label === 'surface') {
      displayValue = `${value} m²`; // Add 'm²' for surface
    }

    return (
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>{t(label)}:</strong> {displayValue}
      </Typography>
    );
  };

  return (
    <Box sx={{ m: 0, backgroundColor: 'rgba(0, 0, 0, 0.03)', p: 2, borderRadius: 2 }}>
      <Card elevation={0} sx={{ p: 2, backgroundColor: 'transparent' }}>
        <CardContent>
          {/* Title */}
          <Typography
            variant="h6"
            component="h6"
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: 'rgba(0, 0, 0, 0.03)',
              border: '1px solid #e0e0e0',
              p: 1,
              borderRadius: 1,
              mb: 2,
            }}
          >
            {name}
          </Typography>

          {/* Description */}
          {description && (
            <Typography color="text.secondary" gutterBottom sx={{ textAlign: 'center', mb: 2 }}>
              {description}
            </Typography>
          )}

          {/* General Information Section */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {t('general')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {formatDetail('type', t(type))}
                {formatDetail('category', t(category))}
                {exploitation && formatDetail('exploitation', t(exploitation))}
                {formatDetail('ownership', `${(asset.ownership * 100).toFixed(0)}%`)}
                {formatDetail(name_purchase_date, purchase_date)}
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Financial Details Section */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
              {t('finances')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {formatDetail(name_purchase_price, purchase_price, currency)}
                {formatDetail('acquisition_cost', acquisition_cost, currency)}
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 1 }} />

          {/* Property Details Section */}
          {details && (
            <Box >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {t('details')}
              </Typography>
              <Grid container >
                {Object.entries(details).map(
                  ([key, value]) =>
                    value &&
                    !excludedKeys.includes(key) &&
                    !key.endsWith('id') && (
                      <Grid item xs={12} sm={6} key={key}>
                        {formatDetail(key, value)}
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          )}

          <Divider sx={{ my: 1 }} />

          {/* Location Details Section */}
          {location && (
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                {t('location_details')}
              </Typography>
              <Grid container spacing={2}>
                {Object.entries(location).map(
                  ([key, value]) =>
                    value &&
                    !excludedKeys.includes(key) &&
                    !key.endsWith('id') && (
                      <Grid item xs={12} sm={6} key={key}>
                        {formatDetail(key, value)}
                      </Grid>
                    )
                )}
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Details;
