import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import { UserProvider } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-supabase'
import { DataProvider } from './context/DataContext';
import { ConfigProvider } from './context/ConfigContext';
import { ImageProvider } from './context/ImageContext';
import AppRoutes from './AppRoutes';
import supabase from './supabase'

import './App.css';
import './form.css';

// Create a client
const queryClient = new QueryClient();

function App() {
    return (
      <QueryClientProvider client={queryClient} >
        <UserProvider>
        <ConfigProvider>
          <DataProvider>
            <LanguageProvider>
            <NotificationProvider>
            <ImageProvider>
            <Provider value={supabase}>
                <Router>
                    <AppRoutes />
                </Router>
                </Provider>
                </ImageProvider>
              </NotificationProvider>
            </LanguageProvider>
            </DataProvider>
            </ConfigProvider>
        </UserProvider>
      </QueryClientProvider>
    );
}
export default App;
