import React, { useEffect, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Box } from '@mui/material';
import { getCategoryIcon } from '../../../../utils/Avatar';
import { ConfigContext } from '../../../../context/ConfigContext';
import { useImages } from '../../../../context/ImageContext';

const AssetTypeSelector = memo(({ onSelect }) => {
  const { t } = useTranslation();
  const { configs } = useContext(ConfigContext);
  const { images, loading: imagesLoading } = useImages();

  const assetTypes = configs.assetTypes || [];

  const handleTypeSelect = (type) => {
    onSelect(type);
  };

  if (imagesLoading) {
    return <CircularProgress />;
  }

  if (!assetTypes.length) {
    return (
      <Typography color="textSecondary" align="center">
        {t('no_asset_types_available')}
      </Typography>
    );
  }

  return (
    <Grid container spacing={2}>
      {assetTypes.map((type) => {
        const imageSrc = images[type] || images['OTHER_ASSET'];
        return (
          <Grid item xs={6} sm={6} md={6} lg={6} key={type}>
            <Card
              sx={{
                height: 160,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                '&:hover': { boxShadow: '0 6px 12px rgba(0,0,0,0.2)', transform: 'scale(1.02)' },
              }}
              onClick={() => handleTypeSelect(type)}
            >
              <CardActionArea sx={{ position: 'relative', height: '100%' }}>
                {imageSrc ? (
                  <CardMedia
                    component="img"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    image={imageSrc}
                    alt={t(type)}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: 'gray',
                      backgroundColor: 'lightgray',
                    }}
                  >
                    {getCategoryIcon(type)}
                  </Box>
                )}
                <CardContent
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)',
                    width: '100%',
                    padding: 1,
                  }}
                >
                  <Typography gutterBottom variant="h5" color="white">
                    {t(type)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default AssetTypeSelector;
