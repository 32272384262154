import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import {
  Box, Container, Fab, Grid, Button, Fade, Tab, Tabs, Typography, ToggleButton, ToggleButtonGroup, Modal
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import MainCard from '../../utils/MainCard';
import { deleteAsset, createAsset } from '../../api';
import AssetCreationWizard from './create/AssetCreationWizard';
import { useTranslation } from 'react-i18next';
import AssetCard from '../../utils/AssetCard';
import { formatAmount } from '../../utils';
import ConfirmDialog from '../../utils/ConfirmDialog';
import LoadingModal from '../../utils/LoadingModal';
import { useNotification } from '../../context/NotificationContext';
import { UserContext } from '../../context/UserContext';

const AssetsUser = () => {
  const { user, refreshUser } = useContext(UserContext);
  const { t } = useTranslation();
  const { data, refreshAll } = useContext(DataContext);

  const [activeTab, setActiveTab] = useState(0);
  const [assetView, setAssetView] = useState('active');
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [assetIdToDelete, setAssetIdToDelete] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  // Dynamically filtered assets
  const filteredAssets = data.assets
    ? data.assets.filter((asset) => (assetView === 'active' ? asset.active : !asset.active))
    : [];

  const typedAssets = filteredAssets.reduce((acc, asset) => {
    const { type } = asset;
    acc[type] = acc[type] || { items: [], totalValue: 0 };
    acc[type].items.push(asset);
    acc[type].totalValue += asset.value || 0;
    return acc;
  }, {});

  const totalValue = filteredAssets.reduce((sum, asset) => sum + (asset.value || 0), 0);

  // Handle view toggle between active and sold assets
  const handleViewChange = (event, newView) => {
    if (newView) setAssetView(newView);
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  const handleDeleteAsset = (assetId) => {
    setAssetIdToDelete(assetId);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!assetIdToDelete) return;
    setIsCreating(true);
    try {
      await deleteAsset(assetIdToDelete);
      await refreshAll(user.role);
      refreshUser();
      showNotification(t('asset_deleted_successfully'), 'success');
    } catch (error) {
      showNotification(`${t('asset_deletion_failed')}: ${error.response?.data?.detail}`, 'error');
    } finally {
      setIsCreating(false);
      setIsDialogOpen(false);
    }
  };

  const handleOpenWizard = () => {
    console.log("Opening wizard");
    setIsWizardOpen(true);
  };

  const handleCloseWizard = () => {
    console.log("Closing wizard");
    setIsWizardOpen(false);
  };

  const handleWizardSubmitAsset = async (formData, smoothDeposit, flagNewDeposit, details, forecast, location = null) => {
    try {
      setIsCreating(true);
      const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
      details = isEmptyObject(details) ? null : details;
      location = isEmptyObject(location) ? null : location;
      forecast = isEmptyObject(forecast) ? { cashflow: 0, appreciation: 10, period: 10 } : forecast;

      await createAsset(formData, details, forecast, location, flagNewDeposit, smoothDeposit);
      await refreshAll(user.role);
      refreshUser();
      showNotification(
        t(smoothDeposit ? 'asset_created_successfully_with_smooth_process' : 'asset_created_successfully'),
        'success'
      );
    } catch (error) {
      showNotification(`${t('asset_creation_failed')}: ${error.response?.data?.detail}`, 'error');
    } finally {
      setIsCreating(false);
      setIsWizardOpen(false);
    }
  };

  return (
    <Container maxWidth="md">
      {data.assets && data.assets.some((asset) => !asset.active) && (
        <Box display="flex" justifyContent="center" my={2}>
          <ToggleButtonGroup
            value={assetView}
            exclusive
            onChange={handleViewChange}
            aria-label="Asset View Toggle"
          >
            <ToggleButton value="active" aria-label="Active Assets">
              {t('running_assets')}
            </ToggleButton>
            <ToggleButton value="sold" aria-label="Sold Assets">
              {t('sold_assets')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      )}

      {Object.keys(typedAssets).length === 0 ? (
        <Box textAlign="center" mt={5}>
          <Typography variant="h6">{t('welcome_to_assets')}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {t('no_assets_prompt')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenWizard}
            sx={{ mt: 3 }}
          >
            {t('add_your_first_asset')}
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="center" my={2}>
            <MainCard
              title={t('assets_total_value')}
              value={totalValue}
              currency={user.currency}
              m_change={assetView === 'active' ? data.userStatsHistory?.value_mtd : null}
              y_change={assetView === 'active' ? data.userStatsHistory?.value_ytd : null}
              size="h4"
            />
          </Box>

          <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
            {Object.keys(typedAssets).map((type) => (
              <Tab
                key={type}
                label={
                  <>
                    {t(type)}
                    <Typography variant="subtitle1" component="span" ml={1}>
                      {formatAmount(typedAssets[type].totalValue, user.currency)}
                    </Typography>
                  </>
                }
              />
            ))}
          </Tabs>

          {Object.entries(typedAssets).map(([type, assets], index) => (
            <Box key={type} hidden={activeTab !== index} p={3}>
              <Grid container spacing={2}>
                {assets.items.map((asset) => (
                  <AssetCard
                    key={asset.id}
                    asset={asset}
                    onDelete={handleDeleteAsset}
                    onOpen={() => navigate(`/assets/${asset.id}`)}
                    currency={user.currency}
                  />
                ))}
              </Grid>
            </Box>
          ))}

          <Fab
            color="primary"
            aria-label={t('add_asset')}
            onClick={handleOpenWizard}
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              width: 64,
              height: 64,
              fontSize: 32,
            }}
          >
            <AddIcon />
          </Fab>

            {/* Asset Creation Wizard */}
            {isWizardOpen && <AssetCreationWizard
            applyAsset={handleWizardSubmitAsset}
            onCancel={handleCloseWizard}
            t={t}
            user={user}
              />}


          <ConfirmDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            onConfirm={handleConfirmDelete}
            title={t('delete_transaction_title')}
            cancelLabel={t('cancel_button')}
            confirmLabel={t('delete_button')}
            confirmButtonColor="error"
            message={t('confirm_asset_deletion')}
          />
        </>
      )}

      <LoadingModal open={isCreating} />
      <Modal
        open={isWizardOpen}
        onClose={handleCloseWizard}
        closeAfterTransition
        backdropprops={{ timeout: 1500 }}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Fade in={isWizardOpen}>
          <Box sx={{ background: 'white', boxShadow: 24, p: 4, borderRadius: 2, maxWidth: '500px', maxHeight: '90vh', overflow: 'auto' }}>
            <AssetCreationWizard applyAsset={handleWizardSubmitAsset} onCancel={handleCloseWizard} t={t} user={user} />
          </Box>
        </Fade>
      </Modal>    </Container>
  );
};

export default AssetsUser;
