import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'; // adjust path as needed
import { DataContext } from '../../context/DataContext';
import { useNotification } from '../../context/NotificationContext';
import { ConfigContext } from '../../context/ConfigContext';
import { useTranslation } from 'react-i18next';

function Logout() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useContext(UserContext);
  const { resetData } = useContext(DataContext);
  const { showNotification } = useNotification();
  const { resetConfigs } = useContext(ConfigContext);


  useEffect(() => {
    // assuming setUser sets the user state to null or another initial state
    resetData();
    logout();
    resetConfigs();
    showNotification(t('logout_successful'), 'success');
    // Redirecting user to home or login page after logout
    navigate('/');
  }, [navigate, Logout]);

  return null;
}

export default Logout;
