import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, InputAdornment, Collapse, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { formatAmount, currencySymbol } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const Finances = forwardRef(({ memo, assetIn, user, t, onValidSubmit }, ref) => {
  const [createNewDeposit, setCreateNewDeposit] = useState(assetIn.flagNewDeposit || false);
  const [smoothDeposit, setSmoothDeposit] = useState(assetIn.flagSmoothDeposit || assetIn.type==="FINANCIAL_PRODUCT");
  const [totalCost, setTotalCost] = useState(assetIn?.purchase_price || 0);
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(false); // For collapse toggle
  const navigate = useNavigate();

  const type = assetIn.type;
  const { control, handleSubmit, formState: { errors }, setValue, setError, getValues, clearErrors, watch } = useForm({
    defaultValues: {
      quantity: assetIn?.quantity || 1,
      purchase_price: assetIn?.purchase_price || '',
      acquisition_cost: assetIn?.acquisition_cost || '',
      value: assetIn?.value || '',
      ownership: assetIn?.ownership || 1,
      debt_id: assetIn?.debt_id || null,
    },
    mode: "onChange"
  });

  const isFinance = type === "FINANCIAL_PRODUCT";

  useEffect(() => {
    for (const key in memo) {
      setValue(key, memo[key] ?? '');
    }
  }, [memo, setValue]);

  useImperativeHandle(ref, () => ({
    submitForm: () => handleSubmit(handleFormSubmit)(),
  }));

  useEffect(() => {
    if (assetIn) {
      for (const key in assetIn) {
        setValue(key, assetIn[key]);
      }
    }
  }, [assetIn, setValue]);

  useEffect(() => {
    const subscription = watch((value) => {
      const purchasePrice = parseFloat(value.purchase_price || 0);
      const acquisitionCost = parseFloat(value.acquisition_cost || 0);
      const ownershipPercentage = parseFloat(value.ownership || 1);
      const newTotalCost = (purchasePrice + acquisitionCost) * ownershipPercentage;
      setTotalCost(newTotalCost);

      // Automatically check the createNewDeposit checkbox when totalCost is greater than 0
      if (newTotalCost > 0) {
        setCreateNewDeposit(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleFormSubmit = (data) => {
      // Check if purchase_price is in the data object
    console.log("Form Finances data submitted", data);
    data["flagNewDeposit"] = createNewDeposit;
    data["flagSmoothDeposit"] = smoothDeposit;
    onValidSubmit(data);
  };

  const handleCreateDepositChange = (e) => {
    setCreateNewDeposit(e.target.checked);
    clearErrors("purchase_price");
  };

  const handleSmoothDepositChange = (e) => {
    setSmoothDeposit(e.target.checked);
  };

  const validateTotalCost = () => {
    if (getValues("debt_id")) return true;
    if (createNewDeposit) return true;
    if (isFinance) { setCreateNewDeposit(true); return true; }
    if (getValues("purchase_price") < 0) return `${t('purchase_price')} must be positive`;
    if (getValues("acquisition_cost") < 0) return `${t('acquisition_cost')} must be positive`;
    return totalCost <= user.cash_balance ? true :
      `${t('ownership')} * (${t('purchase_price')} + ${t('acquisition_cost')}) ${t('exceeds_user_cash')} ${formatAmount(user.cash_balance, user.currency)}`;
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)} sx={{ '& .MuiTextField-root': { mb: 2, width: '100%' } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', gap: '5px' }}>
        <Controller
          name="purchase_price"
          control={control}
          rules={{ required: t('required'), validate: validateTotalCost }}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              label={isFinance ? t('total_deposits') : t('purchase_price')}
              InputProps={{
                endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
              }}
              error={!!errors.purchase_price}
              helperText={errors.purchase_price ? errors.purchase_price.message : !isFinance && t('hint_price_without_fee')}
              sx={{ flex: 2, mt: 1 }}
            />
          )}
        />
      </Box>

      {/* Expand Button for More Details */}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        startIcon={<AddIcon />}
        onClick={() => setIsAdditionalInfoOpen(prev => !prev)}
        sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}
      >
        {isAdditionalInfoOpen ? t('Less Details') : t('More Details')}
      </Button>
      </Box>

      {/* Collapsible section for additional fields */}
      <Collapse in={isAdditionalInfoOpen} timeout="auto" unmountOnExit>
        {isFinance && (
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t('current_value')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                }}
                error={!!errors.value}
                helperText={errors.value ? errors.value.message : ''}
                sx={{ flex: 1, mt: -1 }}
              />
            )}
          />
        )}
        {!isFinance && (
          <Controller
            name="acquisition_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t('acquisition_cost')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currencySymbol(user.currency)}</InputAdornment>,
                }}
                error={!!errors.acquisition_cost}
                helperText={errors.acquisition_cost ? errors.acquisition_cost.message : ''}
                sx={{ flex: 1, mt: -1 }}
              />
            )}
          />
        )}
      </Collapse>

      {!isFinance && totalCost > 0 && (
        <Form.Check
          type="checkbox"
          id="createNewDeposit"
          label={`${t("create_implicit_deposit")} ${formatAmount(totalCost, user.currency)}`}
          onChange={handleCreateDepositChange}
          checked={createNewDeposit}
        />
      )}
      {isFinance && totalCost > 0 && (
        <Form.Check
          type="checkbox"
          id="smoothDeposit"
          label={`${t("smooth_deposit")}`}
          onChange={handleSmoothDepositChange}
          checked={smoothDeposit}
        />
      )}
      {t('no_debts_message')}{' '}
      <a href="#!" onClick={() => navigate('/debts')}>{t('add_new_credit_link')}</a>
    </Box>
  );
});

export default Finances;
