import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Button
} from '@mui/material';
import { UploadFile as UploadFileIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { uploadPhoto, deletePhoto, listPhotos } from '../../api';
import { useParams } from 'react-router-dom';
import PhotoCard from './PhotoCard'; // A reusable card for displaying photos


const PhotoUploader = ({ ownerType, ownerId }) => {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const { asset_id } = useParams();
  ownerId = asset_id || ownerId;
  const fetchPhotos = async () => {
    try {
      const response = await listPhotos(ownerType, ownerId);
      setPhotos(response.data || []);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file); // File
    formData.append('owner_type', ownerType); // Add owner_type
    formData.append('owner_id', ownerId); // Add owner_id
    formData.append('is_primary', false); // Default to false or dynamic based on logic


    try {
      setUploading(true);
      const response = await uploadPhoto(formData);
      setPhotos((prevPhotos) => [...prevPhotos, response.data.photo]);
    } catch (error) {
      console.error('Error uploading photo:', error);
    } finally {
      setUploading(false);
    }
  };

  const handlePhotoDelete = async (photoId) => {
    try {
      await deletePhoto(photoId);
      setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, [ownerType, ownerId]);

  return (
    <Box>
      <Typography variant="h4">Manage Photos</Typography>
      <Box my={2}>
  <label htmlFor="upload-photo">
    <input
      id="upload-photo"
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={(e) => handleFileUpload(e)}
    />
    <Button
      variant="contained"
      color="primary"
      component="span"
      disabled={isUploading}
      startIcon={<UploadFileIcon />}
    >
      {isUploading ? t('uploading') : t('upload_photo')}
    </Button>
  </label>
</Box>
      <Grid container spacing={2}>
        {photos.map((photo) => (
          <Grid item xs={12} sm={6} md={4} key={photo.id}>
            <PhotoCard photo={photo} onDelete={handlePhotoDelete} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PhotoUploader;
