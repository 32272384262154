import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { MDBContainer } from 'mdb-react-ui-kit';
import { Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar/Topbar';
import Inbox from './components/inbox';
import Reviews from './components/reviews';
import Login from './components/login/Login';
import DashboardPro from './components/dashboard/DashboardPro.js';
import Partners from './components/partners';
import Logout from './components/login/Logout';
import Signup from './components/login/Signup';
import Profile from './components/profile';
import Debts from './components/debts';
import Assets from './components/assets';
import Asset from './components/asset';
import Dashboard from './components/dashboard';
import Savings from './components/savings';
import Estimations from './components/estimations';
import Concierges from './components/marketplace/index.js';
import Contracts from './components/contracts';
import TransactionsPro from './components/transactionsPro';
import Management from './components/management';
import UpdatePassword from './components/login/UpdatePassword';
import PhotoUploader from './components/photos';

export const AppRoutes = () => {
    const location = useLocation();
    const [isLoginSignupPage, setLoginSignupPage] = useState(['/login', '/signup','/profile/update'].includes(location.pathname));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isSidebarVisible, setIsSidebarVisible] = useState(!isMobile && !isLoginSignupPage);
    const { user, loading } = useContext(UserContext);
    const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        setIsSidebarVisible(!isMobile && !isLoginSignupPage);
      };

      handleResize();

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [isMobile, isLoginSignupPage]);

    useEffect(() => {
      setLoginSignupPage(['/login', '/signup'].includes(location.pathname));
    }, [location.pathname]);


    if (loading)
        return <Spinner animation="border" variant="primary"  className="spinner-container"/>;

    // retrieve right style
    let style = '';
    if (isMobile) {
      style = 'content-mobile';
    } else if (isSidebarVisible) {
      style = 'content-shifted';
    } else { // login/signup page
      style = 'content';
    }

    return (
        <MDBContainer fluid className="App">
            <Topbar toggleSidebar={toggleSidebar} isMobile={isMobile} isLoginSignupPage={isLoginSignupPage} />
            {!isLoginSignupPage && isSidebarVisible &&<Sidebar className='sidebar' user={user} isMobile={isMobile} isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />}
            <MDBContainer className={style}>
                <Routes>
                    <Route path="/debts" element={user ? <Debts /> : <Navigate to="/login" replace />} />
                    <Route path="/profile" element={user ? <Profile user={user}/> : <Navigate to="/login" replace />} />
                    <Route path="/assets" element={user ? <Assets /> : <Navigate to="/login" replace />} />
                    <Route path="/dashboard" element={user ? user.role!=="PRO" ? <Dashboard user={user}/> : <DashboardPro user={user} />  : <Navigate to="/login" replace />} />
                    <Route path="/assets/:asset_id" element={user ? <Asset/> : <Navigate to="/login" replace />} />
                    <Route path="/assets/:asset_id/photos" element={<PhotoUploader ownerType={"ASSET"} ownerId={null}/>} />
                    <Route path="/savings" element={user ? <Savings /> : <Navigate to="/login" replace />} />
                    <Route path="/estimations" element={user ? <Estimations user={user} /> : <Navigate to="/login" replace />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/transactions" element={user && user.role==="PRO" ? <TransactionsPro user={user} /> : <Navigate to="/login" replace /> } />
                    <Route path="/marketplace" element={user ? <Concierges /> : <Navigate to="/login" replace />} />
                    <Route path="/documents" element={user ? <Contracts /> : <Navigate to="/login" replace />} />
                    <Route path="/partners" element={user ? <Partners /> : <Navigate to="/login" replace />} />
                    <Route path="/reviews" element={user ? <Reviews /> : <Navigate to="/login" replace />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/inbox" element={user ? <Inbox /> : <Navigate to="/login" replace />} />
                    <Route path="/management" element={user ? <Management /> : <Navigate to="/login" replace />} />
                    <Route path='/profile/update' element={<UpdatePassword />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                </Routes>
        </MDBContainer>
      </MDBContainer>

    );
}
export default AppRoutes;
