import React, {useState} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,  TextField, Typography,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  title = "confirm_title", // Translation key for title
  message = "confirm_message", // Translation key for message
  cancelLabel = "cancel_button", // Translation key for cancel button
  confirmLabel = "confirm_button", // Translation key for confirm button
  confirmButtonColor = "primary", // Default confirm button color
  cancelButtonColor = "secondary", // Default cancel button color
  requireReason = false, // Add a prop to toggle reason input
}) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const handleConfirm = () => {
    if(requireReason) onConfirm(reason); // Pass the reason back to the parent
    else onConfirm();
    setReason(''); // Clear the reason after confirming
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 3, // Rounded corners for better visual appeal
          padding: 2,
          minWidth: 400, // Ensure the dialog has a consistent size
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold' }}>{t(title)}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'text.secondary', fontSize: '1rem' }}>
        <Typography variant="body2" gutterBottom>
          {t(message)}
        </Typography>
        {requireReason && (
          <Box mt={2}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label={t("reason")}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder={t("reason_refusal")}
            />
          </Box>
        )}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          onClick={onClose}
          color={cancelButtonColor}
          variant="outlined"
          sx={{
            textTransform: 'none', // Keep text capitalization normal
          }}
        >
          {t(cancelLabel)}
        </Button>
        <Button
          onClick={handleConfirm}
          color={confirmButtonColor}
          variant="contained"
          disabled={requireReason && !reason.trim()} // Disable if reason is required but empty
          sx={{
            textTransform: 'none',
          }}
        >
          {t(confirmLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
