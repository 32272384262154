import React, { useState, useContext } from 'react';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../utils';
import {
  Container, Grid, Box, Tabs, Tab, Typography, Tooltip, Avatar, CardContent,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button
} from '@mui/material';
import MainCard from '../../utils/MainCard';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EventSeatIcon from '@mui/icons-material/EventSeat';
import { StyledCard, CardHeader, CardValue, IconWrapper } from './StyledCard';
import { formatChangeText } from '../../utils';
import UserCashflowChart from '../charts/UserCashflowChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const DashboardPro = () => {
  const { user } = useContext(UserContext);
  const { data } = useContext(DataContext); // Access preloaded data
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [reviewsOpen, setReviewsOpen] = useState(false);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [reviews, setReviews] = useState([]);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState('tab1');
  const user_concierge = data.user_concierge || {};
  const concierge = data.concierge || {};
  const showCharts = user_concierge?.net_liq !== 0;
  const statsHistory = data.conciergeStatsHistory;


  const handleCloseReviews = () => setReviewsOpen(false);
  const fetchReviews = async () => {
    setLoadingReviews(true);
    try {
      // Simulating API call for reviews (replace with your API logic)
      const response = await new Promise((resolve) =>
        setTimeout(() => resolve({ data: concierge.reviews || [] }), 1000)
      );
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setLoadingReviews(false);
    }
  };

  const handleOpenReviews = () => {
    setReviewsOpen(true);
    fetchReviews();
  };


  const handleClick = (tooltip) => {
    setTooltipContent(tooltip);
    setTooltipOpen(true);
  };

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleTabChange = (value) => {
    if (value !== tabValue) setTabValue(value);
  };
  const cardsData = [
    {
      icon: <EventSeatIcon sx={{ color: 'grey' }} />, // Icon changes color based on occupancy rate
      value: concierge.occupancy_rate
        ? `${(concierge.occupancy_rate * 100).toFixed(2)}%`
        : 'N/A', // Display occupancy rate or fallback if unavailable
      label: t('occupancy_rate'),
      color: concierge.occupancy_rate == 0 ? 'black' : concierge.occupancy_rate >= 0.5 ? 'green' : 'red',
      tooltip: t('tooltip_occupancy_rate', 'The average occupancy rate of your managed assets.'),
      isCritical: concierge.occupancy_rate && concierge.occupancy_rate < 0.25,
    },
    {
      icon: <TrendingUpIcon sx={{ color: concierge.roi >= 0.1 ? 'green' : concierge.roi < 0 ? 'red': 'black' }} />, // Improved ROI icon with conditional color
      value: concierge.roi
        ? `${(concierge.roi * 100).toFixed(2)}%`
        : 'N/A',
      label: t('average_roi'),
      color: concierge.roi >= 0.1 ? 'green' : concierge.roi < 0 ? 'red': 'black',
      tooltip: t('tooltip_average_roi', 'The average return on investment across assets.'),
      isCritical: concierge.roi && concierge.roi < 0.05,
    },
    {
      icon: (
        <StarIcon sx={{ color: '#FFC107', cursor: concierge.reviews_count ? 'pointer' : 'default' }} />
      ), // Highlight reviews icon in gold
      value: concierge.reviews_count
        ? (concierge.rating_count / concierge.reviews_count).toFixed(1).replace(/\.0$/, '')
        : 'N/A',
      label: concierge.reviews_count + ' ' + (concierge.reviews_count==0 ? t('review') : t('reviews')),
      tooltip: t('tooltip_reviews', 'Click to view all reviews.'),
      onClick: concierge.reviews_count ? () =>  handleOpenReviews: null, // Make clickable
    },
    {
      icon: <BusinessCenterIcon sx={{ color: '#3f51b5' }} />, // Enhanced assets icon with distinct primary color
      value: concierge.assets || 0, // Replace 0 with the actual data or fallback value
      label: t('active_assets'),
      tooltip: t('active_assets_tooltip', 'Number of assets currently under management.'),
    },
  ];

  return (
    <Container mb={2}>
      {/* Main Overview */}
      <Box display="flex" justifyContent="center" my={2}>
        <MainCard
          title={t('revenue')}
          value={user_concierge.net_liq}
          m_change={statsHistory?.net_worth_mtd}
          y_change={statsHistory?.net_worth_ytd}
          currency={user_concierge.currency}
          tooltip={t('tooltip_revenue') || ''}
          size="h4"
        />
      </Box>

     {/* Tabs Section */}
{showCharts && (
  <>
    <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} centered>
      <Tab label={t('revenue')} value="tab1" />
      <Tab label={t('occupancy_rate')} value="tab2" />
    </Tabs>

    {/* Tab Content */}
    <Box my={3}>
      {tabValue === 'tab1' && (
        <UserCashflowChart
          currency={user_concierge.currency}
          userCashflowChart={data.conciergeRevenueChart}
          strIncome={t('income')}
          strExpense={t('expense')}
        />
      )}
      {tabValue === 'tab2' && (
        <Typography variant="subtitle1">{t('chart_placeholder_occupancy')}</Typography>
      )}
    </Box>
  </>
)}

     {/* Cards Section */}
      <Grid container spacing={3} my={3}>
        {cardsData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Tooltip
              title={card.tooltip || ''}
              open={tooltipOpen && tooltipContent === card.tooltip}
              onClose={handleClose}
              disableHoverListener
              disableFocusListener
              arrow
            >
              <StyledCard raised onClick={() => handleClick(card.tooltip)}>
                <CardContent>
                  <CardHeader>
                    <IconWrapper>
                      <Avatar sx={{ bgcolor: 'transparent' }}>
                        {card.icon}
                      </Avatar>
                    </IconWrapper>
                    <div>
                <Typography variant="subtitle2" gutterBottom>
                  {card.label}
                </Typography>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <CardValue
                    sx={{
                      color: card.isCritical ? 'red' : 'inherit',
                      fontWeight: card.isCritical ? 'bold' : 'normal',
                    }}
                  >
                    {card.value}
                  </CardValue>
                  {card.isCritical && (
                    <Tooltip title={card.warningMessage}>
                      <WarningAmberIcon
                        sx={{
                          fontSize: 16,
                          color: 'red',
                          ml: 1,
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                  )}
                    </Box>

                      {card.mtd ? formatChangeText(card.mtd, (card.mtd / Math.abs(card.value - card.mtd)) * 100, t('MTD'), user.currency) : null}

                      </div>
                  </CardHeader>
                </CardContent>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
        {/* Reviews Modal */}
        <Dialog open={reviewsOpen} onClose={handleCloseReviews} fullWidth maxWidth="sm">
  <DialogTitle>{t('all_reviews')}</DialogTitle>
  <DialogContent>
    {loadingReviews ? (
      <CircularProgress />
    ) : reviews.length > 0 ? (
      reviews.map((review) => (
        <Box
          key={review.id}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            p: 2,
            mb: 2,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* User Name and Date */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  mr: 1,
                  bgcolor: 'lightblue', // Background color
                  color: 'white',
                }}
              >
                <PersonIcon />
              </Avatar>
            <Typography variant="body1" fontWeight="bold">
              {review.user_name || t('anonymous')}
            </Typography>
            </Box>
            <Typography variant="caption" color="textSecondary">
              {new Date(review.created_at).toLocaleDateString()}
            </Typography>
          </Box>

          {/* Headline */}
          <Typography variant="h6" sx={{ mt: 1 }}>
            {review.headline}
          </Typography>

          {/* Rating */}
          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Box display="flex" alignItems="center">
              {[...Array(review.rating)].map((_, index) => (
                <StarIcon key={index} sx={{ color: '#FFC107', fontSize: 16 }} />
              ))}
              {[...Array(5 - review.rating)].map((_, index) => (
                <StarIcon key={index} sx={{ color: '#E0E0E0', fontSize: 16 }} />
              ))}
            </Box>
          </Box>

          {/* Comment */}
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {review.comment || t('no_comment')}
          </Typography>

          {/* Asset Category */}
          <Typography variant="body2" color="textPrimary" sx={{ mt: 2 }}>
            {t('asset_category')}: {t(review.asset_category || 'unknown')}
          </Typography>
        </Box>

      ))
    ) : (
      <Typography variant="body2" color="textSecondary">
        {t('no_reviews_available')}
      </Typography>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseReviews} color="primary">
      {t('close')}
    </Button>
  </DialogActions>
</Dialog>

    </Container>
  );
};

export default DashboardPro;
