import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import { currencySymbol } from '../../../../utils';
import i18next from 'i18next';

const Summary = forwardRef(({ memo, assetIn, user, t, onForecastUpdate }, ref) => {
    const formatDate = (dateString) => new Date(dateString).toLocaleDateString(i18next.language);
    const details = null; // Set to null for now
    const name_price = assetIn.type === "FINANCIAL_PRODUCT" ? "deposit":"purchase_price";
    const name_date = assetIn.type === "FINANCIAL_PRODUCT" ? "opening_date":"purchase_date";
    return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="subtitle1"><strong>{t('type')}:</strong> {t(assetIn.type)}</Typography>
          <Typography variant="subtitle1"><strong>{t('name')}:</strong> {assetIn.name}</Typography>
          <Typography variant="subtitle1"><strong>{t(name_date)}:</strong> {formatDate(assetIn.purchase_date)}</Typography>
          <Typography variant="subtitle1"><strong>{t(name_price)}:</strong> {assetIn.purchase_price} {currencySymbol(user.currency)}</Typography>
          {assetIn.category && <Typography variant="subtitle1"><strong>{t('category')}:</strong> {t(assetIn.category)}</Typography>}

          {/* Location Details */}
          {/*location && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">{t('location')}</Typography>
              <Typography variant="subtitle1"><strong>{t('country')}:</strong> {location.country}</Typography>
              <Typography variant="subtitle1"><strong>{t('region')}:</strong> {location.region}</Typography>
                          </CardContent></Box>
          )}

              {/* Add other location fields as needed */}

          {/* Asset-Specific Details */}
          {details && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">{t('details')}</Typography>
              {details.brand && <Typography variant="subtitle1"><strong>{t('brand')}:</strong> {details.brand}</Typography>}
              {details.model && <Typography variant="subtitle1"><strong>{t('model')}:</strong> {details.model}</Typography>}
              {/* Add other details fields as needed */}
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
    );
});

export default Summary;
