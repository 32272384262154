import { useMutation } from 'react-query';
import { useNotification } from '../context/NotificationContext';

const useCustomMutation = (mutationFn, { successMessage, errorMessage, refreshData }) => {
  const { showNotification } = useNotification();

  return useMutation((data) => {
    return mutationFn(data);
  }, {

    onSuccess: () => {
      console.log('Mutation succeeded. Calling refresh...');
      if (successMessage) {
        showNotification(successMessage, 'success');
      }

      if (refreshData){
        console.log('Calling refreshData...');
        refreshData(); // Call the refresh function provided as a prop
      }
      else {
        console.log('No refreshData provided. Not calling refreshData...');
      }



    },
    onError: (error) => {
      const errorDetail = error.response?.data?.detail || error.message;
      if (errorMessage) {
        showNotification(`${errorMessage}: ${errorDetail}`, 'error');
      }
    },
  });
};

export default useCustomMutation;
